import React from 'react';
import { Link } from 'react-router-dom';
import { AlertTitle, Button, Typography } from '@mui/material';
import { JucyAlert } from '../../../../components/Alert/JucyAlert';
import { useEditReservationHref } from '../../../../hooks';
import RouteRegistry from '../../../../store/RouteRegistry';
import { BookingCart } from '../../../../types/BookingCart';

export const EditConfirmedBookingAlert: React.FC<{
    summary: BookingCart;
}> = ({ summary }) => {
    const editLink = useEditReservationHref(summary);
    const createLink = RouteRegistry.getPath('create-trip', {
        action: 'create',
        fleetTypeOrResRef: summary.fleetType?.slug,
    });
    return (
        <JucyAlert color="primary" variant="outlined" severity="info">
            <AlertTitle>Changes to confirmed bookings</AlertTitle>
            We're sorry, but you're no longer able to make online changes to your
            <br />
            booking <strong>{summary.reference}</strong>
            <br />
            <br />
            Confirmed bookings cannot be changed online at this time.
            <br />
            <br />
            If you’d like to make any changes to this booking, please contact our support team. They will come back to you with the details regarding the changes and they will update the
            booking for you once you’ve agreed on the modifications.
            <br />
            <Button color="primary" variant="outlined" size="small" component={Link} to={editLink}>
                Contact us
            </Button>
            <br />
            <br />
            <Typography variant="caption">
                Alternatively, you may,{' '}
                <Button color="primary" variant="outlined" size="small" component={Link} to={createLink}>
                    create a new quote
                </Button>
            </Typography>
        </JucyAlert>
    );
};
