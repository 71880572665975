import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AppBar, Container, Stack, Toolbar } from '@mui/material';
import AppStateStore from '../../store/AppStateStore';
import RouteRegistry from '../../store/RouteRegistry';
import TestEnvRibbon from '../TestEnvRibbon';
import { CurrencySelector } from '../formFields/CurrencySelector';
import { AccountSummary, ExitLink, Logo, MainMenu, MobileNavigation, MobileUserMenu } from './components';

export const MainAppBar: React.FC = observer(() => {
    const routes = RouteRegistry.routes.filter((r) => r.showInMenu && (r.public || AppStateStore.user.isAuthenticated));
    return (
        <AppBar sx={{ color: 'text.primary' }}>
            <TestEnvRibbon />
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <MobileNavigation routes={routes} />
                    <Logo />
                    <MainMenu />
                    <Stack direction="row" spacing={1} alignItems="center" ml="auto" pl={1}>
                        <AccountSummary sx={{ display: { xs: 'none', sm: 'block', whiteSpace: 'nowrap' } }} />
                        <ExitLink sx={{ display: { xs: 'none', sm: 'inline' } }} />
                        <CurrencySelector sx={{ marginLeft: 'auto' }} />
                        <MobileUserMenu />
                    </Stack>
                </Toolbar>
            </Container>
        </AppBar>
    );
});
MainAppBar.displayName = 'MainAppBar';
