import { stripEmptyProps } from './stripEmptyProps';

const createUrl = (url: string | URL, params?: Record<string, string>): URL => {
    const result = new URL(url);
    if (params) {
        Object.keys(stripEmptyProps(params)).forEach((key) => result.searchParams.append(key, params[key]));
    }
    return result;
};

export default createUrl;
