import { datadogRum } from '@datadog/browser-rum';
import React, { ErrorInfo } from 'react';
import { Box, Card, CardContent, Container, Theme } from '@mui/material';
import { withTheme } from '@mui/styles';
import get from 'lodash/get';
import { JucyAuthError } from '../../contexts/JucyAuthContext';
import { OBEErrorAlert } from './OBEErrorAlert';

const isDev: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

type ErrorBoundaryProps = {
    theme: Theme;
    children?: React.ReactNode;
};
type ErrorBoundaryState = {
    hasError?: boolean;
    error?: Error;
    errorInfo?: ErrorInfo;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    state: ErrorBoundaryState = {};

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ hasError: true, errorInfo, error });
        const renderingError = new Error(error.message);
        renderingError.name = 'ReactRenderingError';
        renderingError.stack = errorInfo.componentStack || undefined;
        renderingError.cause = error;
        datadogRum.addError(renderingError);
    }

    render() {
        const { error, errorInfo, hasError } = this.state;

        const isAuthError = error instanceof JucyAuthError;

        return hasError ? (
            <Container maxWidth="md" sx={{ paddingTop: 2 }}>
                <OBEErrorAlert
                    subTitle={
                        <>
                            Looks like we have run in to some technical issues.
                            {isDev || isAuthError ? <Box sx={{ py: 2 }}>{error?.message}</Box> : null}
                        </>
                    }
                >
                    {isDev ? (
                        <Box py={2}>
                            {errorInfo?.componentStack && (
                                <details className="smaller">
                                    <summary>Component stack</summary>
                                    <Card>
                                        <CardContent>
                                            <pre>{errorInfo.componentStack}</pre>
                                        </CardContent>
                                    </Card>
                                </details>
                            )}
                            {error && (
                                <details className="smaller">
                                    <Card>
                                        <CardContent>
                                            <pre>
                                                {get(error, 'response.body.Message') && (
                                                    <>
                                                        {`${get(error, 'response.body.Message')}`}
                                                        <br />
                                                        <br />
                                                    </>
                                                )}
                                                {get(error, 'response.error') && (
                                                    <>
                                                        {`${get(error, 'response.error')}`}
                                                        <br />
                                                        <br />
                                                    </>
                                                )}
                                                {get(error, 'status') && (
                                                    <>
                                                        {`Status: ${get(error, 'status')}`}
                                                        <br />
                                                        <br />
                                                    </>
                                                )}
                                                {error.stack && (
                                                    <>
                                                        {`Stack: ${error.stack}`}
                                                        <br />
                                                        <br />
                                                    </>
                                                )}
                                            </pre>
                                        </CardContent>
                                    </Card>
                                </details>
                            )}
                        </Box>
                    ) : null}
                </OBEErrorAlert>
            </Container>
        ) : (
            this.props.children
        );
    }
}

export default withTheme<Theme, React.JSXElementConstructor<ErrorBoundaryProps>>(ErrorBoundary);
