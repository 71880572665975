import { useLocation } from 'react-router';

export const useGetUrlCountryCode = () => {
    const location = useLocation();
    const parts = location.pathname.split('/').filter(Boolean);

    const countryCodes = ['au', 'nz'];
    for (const part of parts) {
        if (countryCodes.includes(part)) {
            return part;
        }
    }
    return undefined;
};
