import { PageTitle } from '#/components/Typograpgy/Title';
import appState from '#/store/AppStateStore';
import paymentStore from '#/store/PaymentStore';
import TranslationStore from '#/store/TranslationStore';
import { BookingCart } from '#/types/BookingCart';
import { Box, BoxProps, Container } from '@mui/material';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentProviderForm } from './PaymentProviderForm';

export type PaymentFormProps = BoxProps & {
    summary: BookingCart;
    backLink?: React.ReactNode;
};

const PaymentForm: React.FC<PaymentFormProps> = observer(({ summary, ...props }) => {
    const paymentOption = paymentStore.getActivePaymentOption(summary);

    useEffect(() => {
        appState.orderSummaryFooter.showNextBtn = false;
        appState.ui.showFooter = false;
        return () => {
            appState.ui.showFooter = true;
        };
    }, []);

    if (!paymentOption) {
        return <PaymentFormContainer {...props} />;
    }

    const valid = Boolean(paymentOption?.isRedirect ? summary.convertUrl : summary.paymentToken);
    if (!valid) {
        return (
            <PaymentFormContainer {...props}>
                <PageTitle textAlign="center" pb={{ sm: 2, md: 3 }}>
                    Payment is not available for this booking
                </PageTitle>
            </PaymentFormContainer>
        );
    }

    return (
        <PaymentFormContainer gateway={paymentOption?.gateway} {...props}>
            <PaymentFormHeader />
            <PaymentProviderForm paymentOption={paymentOption} summary={summary} />
        </PaymentFormContainer>
    );
});
PaymentForm.displayName = 'PaymentForm';

const PaymentFormHeader: React.FC = () => {
    const { t } = useTranslation(TranslationStore.currentNamespace);
    return (
        <>
            <PageTitle textAlign="center" pb={{ sm: 2, md: 3 }}>
                Let&apos;s lock in your trip!
            </PageTitle>
            <div className="mb-3 text-center">
                <i className="fas fa-lock" /> {t('hirer_details.group.secured_payment')}
            </div>
        </>
    );
};
const PaymentFormContainer: React.FC<BoxProps & { gateway?: string; backLink?: React.ReactNode }> = ({ className, backLink, children, ...props }) => (
    <Box className={clsx('h-auto payment-form ', className)} {...props}>
        {backLink ? (
            <Container maxWidth="xl" sx={{ pt: 1 }}>
                {backLink}
            </Container>
        ) : null}
        <Container maxWidth="sm" sx={{ pt: 2, pb: 8 }} className="h-auto">
            {children}
        </Container>
    </Box>
);
export default PaymentForm;
