import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import config from '../../config';
import { useRouteNavigate } from '../../hooks/useNavigateRoute';
import AppStateStore from '../../store/AppStateStore';
import AvailabilityStore from '../../store/AvailabilityStore';
import FleetTypesStore from '../../store/FleetTypesStore';
import RentalTripSearchStore from '../../store/RentalTripSearchStore';
import ReservationStore from '../../store/ReservationStore';
import TranslationStore from '../../store/TranslationStore';
import { JucyButton, JucyMuiTextLink } from '../Button/Button';
import ModalDialog from '../ModalDialog';
import { PageTitle } from '../Typograpgy/Title';

const ShowDNRModal = observer(() => {
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const callUsLink = new URL(`/${ReservationStore.activeQuote?.PickUpSite?.CountryCode}/en/about-us/help-and-contact/`, config.websiteBaseUrl);
    const navigate = useRouteNavigate();
    const closeHandler = useCallback(() => {
        AppStateStore.toggleDNRModal();
        // Redirect to trip search page
        ReservationStore.reset();
        AvailabilityStore.reset();
        RentalTripSearchStore.init();
        navigate('direct-create-quote', { params: { fleetType: FleetTypesStore.getDefault().slug }, replace: true });
    }, [navigate]);

    return (
        <ModalDialog open={AppStateStore.showDNRModal} onClose={AppStateStore.toggleDNRModal} data-cy="dnr-modal" maxWidth="sm">
            <DialogTitle component="div">
                <PageTitle>Whoops!</PageTitle>
            </DialogTitle>
            <DialogContent sx={{ paddingTop: 0 }}>
                <p>{t('email_quote.dnr_message_1')}</p>
                <p>
                    {t('email_quote.dnr_message_2')}{' '}
                    <JucyMuiTextLink size="small" rel="noopener noreferrer" target="_blank" external={true} href={callUsLink.toString()}>
                        friendly crew
                    </JucyMuiTextLink>
                </p>
            </DialogContent>
            <DialogActions>
                <JucyButton data-cy="email-quote-submit" type="submit" variant="contained" onClick={closeHandler}>
                    Close
                </JucyButton>
            </DialogActions>
        </ModalDialog>
    );
});

export default ShowDNRModal;
