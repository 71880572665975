import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import DirectRentalsReservationTabSet from '../../components/Tab/DirectRentalsReservationTabSet';
import SummaryColumnLayout from '../../containers/SummaryColumnLayout';
import reservationStore from '../../store/ReservationStore';
import { EditQuotePage } from '../common/EditQuote';

const routeId = 'direct-edit-quote';

const DirectEditQuotePage = observer((props) => {
    const [showRight, setShowRight] = useState(true);
    const { activeTab } = useParams();

    return (
        <SummaryColumnLayout summary={reservationStore.summary} routeId={routeId} showRight={showRight}>
            <EditQuotePage>
                <DirectRentalsReservationTabSet setShowRight={setShowRight} routeId={routeId} activeTab={activeTab} {...props} />
            </EditQuotePage>
        </SummaryColumnLayout>
    );
});
DirectEditQuotePage.displayName = 'DirectEditQuotePage';

export default DirectEditQuotePage;
