import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '#/hooks/useAuth';
import { RegistryRoute } from '#/store/RouteRegistry';
import { Tab, Tabs } from '@mui/material';
import { SxProps } from '@mui/system';
import { HirePeriodsRatesTab } from './HirePeriods&RatesTab';
import { useMainNav } from './useMainNav';

const tabStyles: SxProps = {
    color: '#111',
    fontSize: 14,
    px: 1.5,
};

const NavMenu: React.FC<{ routes: RegistryRoute[]; currentPage?: string; children?: React.ReactNode }> = ({ routes, currentPage, children }) => (
    <Tabs
        sx={{
            display: { xs: 'none', md: 'flex' },
            marginTop: -2,
            marginBottom: -2,
            height: 68,
            '& .MuiTab-root': { height: 68 },
            '& .MuiTabs-indicator': { height: 4 },
        }}
        value={currentPage}
    >
        {routes.map((route) => (
            <Tab sx={tabStyles} value={route.path} label={route.label} component={Link} to={route.path} key={route.id} />
        ))}
        {children}
    </Tabs>
);

export const MainMenu: React.FC = observer(() => {
    const { user } = useAuth();
    const { enabled, menuItems, currentPage } = useMainNav();
    if (!enabled) {
        return null;
    }
    return (
        <NavMenu routes={menuItems} currentPage={currentPage}>
            {user?.type === 'agent' ? <Tab sx={tabStyles} value="/hire-periods" label="Hire periods & Rates" component={HirePeriodsRatesTab} /> : null}
        </NavMenu>
    );
});
