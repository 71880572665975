import * as React from 'react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ContentMenuItem } from '@jucy/content-api-client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, MenuItem, MenuItemProps, MenuList } from '@mui/material';

export const CMSMenuLink: React.FC<MenuItemProps & { item: ContentMenuItem }> = ({ item, ...props }) => {
    const url = useMemo(() => {
        if (item.link) {
            return `/agent/content/${item.link}`;
        }
        return '';
    }, [item.link]);
    if (item.children.length) {
        return (
            <Accordion square={true} disableGutters={true} elevation={0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`cms-content-panel-${item.id}`} id={`cms-content-header-${item.id}`}>
                    {item.label}
                </AccordionSummary>
                <AccordionDetails sx={{ py: 0 }} id={`cms-content-panel-${item.id}`}>
                    <MenuList component="div" sx={{ py: 0 }} disablePadding={true}>
                        {item.children.map((child) => (
                            <CMSMenuLink key={child.id} item={child} />
                        ))}
                    </MenuList>
                </AccordionDetails>
            </Accordion>
        );
    }
    return (
        <MenuItem {...(props as MenuItemProps<typeof Link>)} component={Link} to={url}>
            {item.label}
        </MenuItem>
    );
};

export const CMSPagesAccordion: React.FC<{ items?: ContentMenuItem[] }> = ({ items }) => (
    <Accordion square={true} disableGutters={true} elevation={0} sx={{ '&:before': { display: 'none' } }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="cms-content-panel" id="cms-content-headerr">
            Updates & Information
        </AccordionSummary>
        <AccordionDetails sx={{ py: 0 }} id="cms-content-panel">
            <MenuList component="div" sx={{ py: 0 }} disablePadding={true}>
                {items?.map((item) => <CMSMenuLink key={item.id} item={item} />)}{' '}
            </MenuList>
        </AccordionDetails>
    </Accordion>
);
