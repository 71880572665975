import { lazy } from 'react';
import registry, { RegistryRoute } from '../store/RouteRegistry';

const LogoutPage = lazy(() => import('./LogoutPage'));
const ConfigPage = lazy(() => import('./ConfigPage'));
const CreateQuotePage = lazy(() => import('./CreateQuotePage'));
const DownloadRatesPage = lazy(() => import('./DownloadRatesPage'));
const ExamplePage = lazy(() => import('./ExamplePage'));
const PaymentCallbackPage = lazy(() => import('./PaymentCallbackPage'));
const AgentContentPage = lazy(() => import('./agent/AgentContentPage'));
const AgentLoginPage = lazy(() => import('./agent/AgentLoginPage'));
const CancelQuotePage = lazy(() => import('./agent/CancelQuotePage'));
const EditQuotePage = lazy(() => import('./agent/EditQuotePage'));
const HirePeriodsPage = lazy(() => import('./agent/HirePeriodsPage'));
const QuoteConfirmedPage = lazy(() => import('./agent/QuoteConfirmedPage'));
const QuoteEmailedPage = lazy(() => import('./agent/QuoteEmailedPage'));
const ReservationsPage = lazy(() => import('./agent/ReservationsPage'));
const CommissionsPage = lazy(() => import('./affiliates/CommissionsPage'));
const ReviewQuotePage = lazy(() => import('./agent/ReviewQuotePage'));
const LandingPage = lazy(() => import('./common/LandingPage'));
const DirectBookingConfirmedPage = lazy(() => import('./direct/DirectBookingConfirmationPage'));
const DirectEditQuotePage = lazy(() => import('./direct/DirectEditQuotePage'));
const DirectPaymentFailurePage = lazy(() => import('./direct/DirectPaymentFailurePage'));
const DirectQuoteEmailedPage = lazy(() => import('./direct/DirectQuoteEmailedPage'));
const StaffLoginPage = lazy(() => import('./staff/StaffLoginPage'));
const TripPage = lazy(() => import('./tripPage'));

const routes: RegistryRoute[] = [
    {
        id: 'staff-login',
        path: '/staff/login',
        element: <StaffLoginPage />,
        public: true,
    },
    {
        id: 'agent-login',
        path: '/login',
        element: <AgentLoginPage />,
        public: true,
    },
    {
        id: 'logout',
        path: '/logout',
        element: <LogoutPage />,
        public: true,
    },
    {
        id: 'quotes',
        path: '/:mode/quotes',
        element: <ReservationsPage />,
        label: 'Manage Bookings',
        showInMenu: true,
        userTypes: ['agent', 'staff'],
    },
    {
        id: 'commissions',
        path: '/:mode/commissions',
        element: <CommissionsPage />,
        label: 'Commissions',
        showInMenu: true,
        userTypes: ['affiliate'],
    },
    {
        id: 'agent-create-quote',
        path: '/:mode/create-quote/:fleetType/:activeTab',
        element: <CreateQuotePage />,
        menuRouteId: 'fleet-type',
    },

    {
        id: 'agent-create-quote',
        path: '/:mode/create-quote/:fleetType',
        element: <CreateQuotePage />,
        menuRouteId: 'fleet-type',
    },
    {
        id: 'create-quote',
        path: '/direct/create-quote',
        element: <CreateQuotePage />,
        public: true,
    },
    {
        id: 'create-quote-landing',
        path: '/:mode/create-quote',
        element: <LandingPage />,
        label: 'Price & Availability',
        showInMenu: true,
        userTypes: ['agent', 'staff'],
    },
    {
        id: 'edit-quote',
        path: '/agent/edit-quote/:reservationReference',
        element: <EditQuotePage />,
        menuRouteId: 'quotes',
    },
    {
        id: 'edit-quote',
        path: '/agent/edit-quote/:reservationReference/:activeTab',
        element: <EditQuotePage />,
        menuRouteId: 'quotes',
    },
    {
        id: 'quote',
        path: '/:mode/quote/:reservationReference',
        element: <ReviewQuotePage />,
        menuRouteId: 'quotes',
    },
    {
        id: 'quote-emailed',
        path: '/:mode/quote/:reservationReference/emailed',
        element: <QuoteEmailedPage />,
        menuRouteId: 'quotes',
    },
    {
        id: 'quote-confirmed',
        path: '/:mode/quote/:reservationReference/confirmed',
        element: <QuoteConfirmedPage />,
        menuRouteId: 'quotes',
    },
    {
        id: 'quote-cancel',
        path: '/:mode/quote/:reservationReference/cancel',
        element: <CancelQuotePage />,
        menuRouteId: 'quotes',
    },
    {
        id: 'examples',
        path: '/examples',
        element: <ExamplePage />,
        public: true,
    },
    {
        id: 'config',
        path: '/config',
        element: <ConfigPage />,
        public: true,
    },
    {
        id: 'direct-home',
        path: '/:mode',
        element: <LandingPage />,
        public: true,
    },

    {
        id: 'create-quote',
        path: '/:mode/create-quote/:fleetType',
        element: <CreateQuotePage />,
        public: true,
    },
    {
        id: 'direct-edit-quote',
        path: '/:mode/edit-quote/:reservationReference/:activeTab',
        element: <DirectEditQuotePage />,
        public: true,
    },

    {
        id: 'direct-edit-quote',
        path: '/:mode/edit-quote/:reservationReference',
        element: <DirectEditQuotePage />,
        public: true,
    },
    {
        id: 'direct-edit-quote',
        path: '/:mode/edit-quote',
        element: <DirectEditQuotePage />,
        public: true,
    },
    {
        id: 'checkin',
        path: '/:mode/check-in',
        element: <DirectBookingConfirmedPage />,
        public: true,
    },
    {
        id: 'direct-booking-confirmed',
        path: '/:mode/booking-confirmed',
        element: <DirectBookingConfirmedPage />,
        public: true,
    },
    {
        id: 'direct-booking-confirmed',
        path: '/:mode/booking-confirmed/:reservationReference',
        element: <DirectBookingConfirmedPage />,
        public: true,
    },
    {
        id: 'direct-payment-failure',
        path: '/:mode/payment-failure',
        element: <DirectPaymentFailurePage />,
        public: true,
    },
    {
        id: 'direct-payment-callback',
        path: '/:mode/payment-callback',
        element: <PaymentCallbackPage />,
        public: true,
    },

    {
        id: 'direct-quote-emailed',
        path: '/:mode/quote/:reservationReference/emailed',
        element: <DirectQuoteEmailedPage />,
        public: true,
    },

    {
        id: 'hire-periods',
        path: '/hire-periods',
        element: <HirePeriodsPage />,
        label: 'Hire Periods',
        public: false,
    },
    {
        id: 'root',
        path: '/',
        element: <LandingPage />,
    },
    {
        id: 'trip-landing',
        path: '/direct/trip',
        element: <LandingPage />,
    },
    {
        id: 'agent-content-page',
        path: '/agent/content/*',
        element: <AgentContentPage />,
    },

    {
        id: 'rates-download',
        path: '/agent/rates/:countryCode/download',
        element: <DownloadRatesPage />,
        public: false,
    },
    {
        id: 'create-trip',
        path: '/:mode/trip/:action/:fleetTypeOrResRef/:activeTab',
        element: <TripPage />,
        public: true,
    },
    {
        id: 'create-trip',
        path: '/:mode/trip/:action/:fleetTypeOrResRef',
        element: <TripPage />,
        public: true,
    },
    {
        id: 'create-trip',
        path: '/:mode/trip/:action/*',
        element: <TripPage />,
        public: true,
    },
];
const routesWithCountryCodes: RegistryRoute[] = [];

for (const route of routes) {
    routesWithCountryCodes.push(route);
    routesWithCountryCodes.push({
        ...route,
        showInMenu: false,
        menuRouteId: undefined,
        id: `${route.id}-country`,
        path: `/:countryCode${route.path}`,
    });
}
registry.init({ routes: routesWithCountryCodes });
export default routesWithCountryCodes;
