import { forwardRef } from 'react';
import { Button, ButtonProps, Menu } from '@mui/material';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { HirePeriodsRatesMenuItems } from './HirePeriods&RatesMenuItems';

export const HirePeriodsRatesTab = forwardRef<HTMLButtonElement, ButtonProps>(({ children, ...props }, ref) => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'hire-periods-rates-menu' });
    return (
        <>
            <Button ref={ref} {...props} {...bindTrigger(popupState)}>
                {children}
            </Button>
            <Menu {...bindMenu(popupState)}>
                <HirePeriodsRatesMenuItems popupState={popupState} />
            </Menu>
        </>
    );
});
HirePeriodsRatesTab.displayName = 'HirePeriodsRatesTab';
