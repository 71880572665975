import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageLoader from '#/components/LoadingAnimation/PageLoader';
import { useAuth } from '#/hooks';
import ErrorReporter from '#/lib/ErrorReporter';

const Auth0CallbackPage = () => {
    const { isAuthenticated, handleRedirectCallback, logout } = useAuth0();
    const { user } = useAuth();
    const [returnTo, setReturnTo] = useState<string | undefined>();
    const handled = useRef(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuthenticated && !handled.current) {
            const handleCallback = async () => {
                handled.current = true;
                try {
                    const result = await handleRedirectCallback();
                    setReturnTo(result.appState.returnTo || '/agent');
                } catch (error) {
                    ErrorReporter.captureError(error);
                    logout();
                }
            };

            handleCallback().catch(ErrorReporter.captureError);
        }
    }, [handleRedirectCallback, isAuthenticated, logout, setReturnTo]);
    useEffect(() => {
        if (user.type === 'agent' && user.isAuthenticated) {
            navigate(returnTo || '/agent');
        }
        if (user.type === 'affiliate' && user.isAuthenticated) {
            navigate(returnTo || '/affiliate');
        }
    }, [navigate, returnTo, user]);

    return <PageLoader id="login-callback" />;
};
Auth0CallbackPage.displayName = 'Auth0CallbackPage';
export default Auth0CallbackPage;
