import React from 'react';
import { BookingCart } from '../../../../types/BookingCart';
import { PaymentOption } from '../../../PaymentOptions/lib';
import { IframePaymentForm } from './IframePaymentForm';
import { MockPaymentForm } from './MockPaymentForm';
import { StripePaymentForm } from './StripePaymentForm';

export const PaymentProviderForm: React.FC<{ paymentOption: PaymentOption; summary: BookingCart }> = ({ paymentOption, summary }) => {
    if (summary.paymentToken === 'mock') {
        return <MockPaymentForm summary={summary} />;
    }

    if (paymentOption?.gateway === 'stripe') {
        return <StripePaymentForm summary={summary} />;
    }
    return <IframePaymentForm summary={summary} />;
};
