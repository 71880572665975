import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import BreakFrame from '../../components/BreakFrame/BreakFrame';
import { JucyButton } from '../../components/Button/Button';
import config from '../../config';
import { useRouteNavigate, useSearchParams } from '../../hooks';
import reservationStore from '../../store/ReservationStore';
import TranslationStore from '../../store/TranslationStore';
import { Link } from 'react-router-dom';
import RouteRegistry from '#/store/RouteRegistry';
import ErrorReporter from '#/lib/ErrorReporter';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import { JucyErrorAlert } from '#/components/Alert/JucyErrorAlert';
import AppStateStore from '#/store/AppStateStore';

const DirectPaymentFailurePageLoader = observer((props) => {
    const routeNavigate = useRouteNavigate();
    const [searchParams] = useSearchParams();
    const activeQuote = reservationStore.activeQuote;
    const bookingNo = reservationStore.bookingNo;
    const status = searchParams.status;
    const reservationReference = searchParams.reservationReference;
    const country = searchParams.country;
    const [redirect, setRedirect] = React.useState<string | undefined>();
    const loading = !activeQuote && reservationStore.state !== 'error' && reservationStore.state !== 'done';

    useEffect(() => {
        if (loading) {
            return;
        }

        if (!redirect && status === 'CANCELLED' && activeQuote) {
            setRedirect(
                RouteRegistry.getPath('direct-edit-quote', {
                    fleetType: activeQuote.FleetType?.slug,
                    reservationReference: activeQuote.ReservationReference,
                    activeTab: 'driver',
                })
            );
        }
        if (!redirect && (!activeQuote || !bookingNo)) {
            setRedirect(RouteRegistry.getPath('direct-create-quote'));
        }
    }, [activeQuote, bookingNo, redirect, routeNavigate, status, loading]);

    useEffect(() => {
        if (reservationReference) {
            reservationStore
                .setActiveReservationReference(reservationReference, {
                    resume: true,
                    country,
                })
                .catch(ErrorReporter.captureError);
        }
    }, [reservationReference, country]);

    if (reservationStore.state === 'error') {
        return (
            <div>
                <JucyErrorAlert title="404 Quote not found" className="animate__animated animate__fadeIn me-auto w-100 mb-0 mt-4">
                    <p className="mb-0">We&apos;re sorry, no valid quote for reference {reservationReference} was found</p>
                    <br />
                    <Link to={`/${AppStateStore.mode}`}>Start a new quote </Link>
                </JucyErrorAlert>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="d-flex flex-column align-items-center w-100 pt-6 animate__animated animate__fadeIn">
                <LoadingAnimation variant="primary" />
                <div className="fw-bold small text-muted">Loading your reservation</div>
            </div>
        );
    }
    return <BreakFrame>{redirect ? <>{redirect} </> : <DirectPaymentFailurePage {...props} />}</BreakFrame>;
});
DirectPaymentFailurePageLoader.displayName = 'DirectPaymentFailurePageLoader';

const DirectPaymentFailurePage = observer(() => {
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const helpPage = `${config.websiteBaseUrl}/nz/en/about-us/help-and-contact/`;
    const [searchParams] = useSearchParams();
    const continueLink = RouteRegistry.getPath('direct-edit-quote', {
        fleetType: reservationStore.activeQuote?.FleetType?.slug,
        reservationReference: reservationStore.activeQuote?.ReservationReference,
        activeTab: 'payment',
    });
    return (
        <div className="main-content full-page  animate__animated animate__fadeIn">
            <div className="h-auto text-center">
                <h1 className="h2 pt-5 pb-5 mb-0">{t('payment_failure.group.title')}</h1>
                <div className="mb-0 pb-5">
                    <div className="fw-bold h6 pb-4">
                        {t('payment_failure.group.booking_number')} {reservationStore.bookingNo}
                    </div>

                    {searchParams?.message ? <p>{searchParams.message}</p> : null}
                    <Trans i18nKey="payment_failure.group.message" href={helpPage}>
                        &quot;
                        <a href={helpPage} target="_blank" rel="noopener noreferrer">
                            place holder
                        </a>
                        &quot;
                    </Trans>
                </div>
                <JucyButton component={Link} color="light" variant="contained" data-cy="payment-try-again-btn" to={continueLink}>
                    {t('payment_failure.group.try_again')}
                </JucyButton>
            </div>
        </div>
    );
});
DirectPaymentFailurePage.displayName = 'DirectPaymentFailurePage';

export default DirectPaymentFailurePageLoader;
