import React from 'react';
import { JucyCountryCode } from '@jucy/rentals-common';
import { Box, Tab, TabProps, Tabs, styled } from '@mui/material';
import FleetCategoryMinHireTableLoader from '../../components/Tables/FleetCategoryMinHireTableLoader';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`min-hire-period-tabpanel-${index}`} aria-labelledby={`min-hire-period-${index}`} {...other}>
            {value === index ? children : null}
        </div>
    );
};
const JucyTab = styled((props: TabProps) => <Tab {...props} />)(({ theme }) => ({
    '&.MuiTab-root.Mui-selected': {
        color: theme.palette.text.primary,
    },
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
    fontWeight: 'bold',
}));

const HirePeriodsPage: React.FC = () => {
    // const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (_event: React.ChangeEvent<unknown>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ mt: 2, width: '100%' }}>
            <Tabs value={value} onChange={handleChange}>
                <JucyTab label="New Zealand" />
                <JucyTab label="Australia" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <FleetCategoryMinHireTableLoader region={JucyCountryCode.nz} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <FleetCategoryMinHireTableLoader region={JucyCountryCode.au} />
            </TabPanel>
        </Box>
    );
};
export default HirePeriodsPage;
