import { observer } from 'mobx-react-lite';
import React from 'react';
import { useAuth } from '#/hooks';
import { Box, Container, Link, Menu, Paper, useMediaQuery, useTheme } from '@mui/material';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import useGetMenuItems from '../../hooks/useGetMenuItems';
import appStateStore from '../../store/AppStateStore';
import { CMSMenuLink } from '../MainAppBar/components/nav/CMSPagesTab';

const AgentFooterNav: React.FC = observer(() => {
    const theme = useTheme();
    const { user } = useAuth();
    const rateType = user.type === 'agent' ? user?.settings?.au?.rateType : undefined;
    const isSm = useMediaQuery(theme.breakpoints.down('md'));
    const popupState = usePopupState({ variant: 'popover', popupId: 'footer-cms-pages-menu' });
    const { items: menuItems } = useGetMenuItems({ path: `/partner/${rateType}`, enabled: Boolean(rateType) });
    if (isSm || appStateStore.mode !== 'agent') {
        return null;
    }
    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
            }}
            elevation={3}
        >
            <Container
                className="foot-container"
                maxWidth="xl"
                sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    flex: '1 1',
                    py: 1,
                }}
            >
                <Link href="https://brandfolder.com/jucy" target="_blank">
                    Trade Assets
                </Link>
                {menuItems?.length ? (
                    <>
                        <Box sx={{ px: 1 }}> &bull;</Box>
                        <Link sx={{ cursor: 'pointer' }} {...bindTrigger(popupState)}>
                            Updates & Information
                        </Link>
                        <Menu {...bindMenu(popupState)}>
                            {menuItems.map((item) => (
                                <CMSMenuLink key={item.id} item={item} popupState={popupState} />
                            ))}
                        </Menu>
                    </>
                ) : null}
            </Container>
        </Paper>
    );
});
AgentFooterNav.displayName = 'AgentFooterNav';

export default AgentFooterNav;
