import React, { useCallback, useMemo } from 'react';
import { BookingCart } from '../../../../types/BookingCart';
import { useNavigate } from 'react-router';
import paymentStore from '../../../../store/PaymentStore';
import useGetGaTrackingId from '../../../../hooks/useGetGaTrackingId';
import createUrl from '../../../../lib/createUrl';
import RouteRegistry from '../../../../store/RouteRegistry';
import config from '../../../../config';
import getPaymentResultPath from '../../../../lib/getPaymentResultPath';
import PaymentField from '../../../form/fields/PaymentField';

export const IframePaymentForm: React.FC<{ summary: BookingCart }> = ({ summary }) => {
    const navigate = useNavigate();
    const paymentOption = paymentStore.getActivePaymentOption(summary);
    const trackingId = useGetGaTrackingId();

    const { convertUrl, reservationReference } = summary;
    const convertLink = useMemo(() => {
        if (!convertUrl) {
            return null;
        }
        if (!paymentOption) {
            return null;
        }
        const returnUrl = createUrl(new URL(`/obe${RouteRegistry.getPath('direct-payment-callback')}`, window.location.origin), {
            ref: reservationReference || '',
            callback: 'true',
            country: summary.pickUpLocation?.CountryCode || '',
            _ga: trackingId,
        });
        const baseUrl: URL = convertUrl.startsWith('http') ? new URL(convertUrl) : new URL(convertUrl, config.apiBaseUrl);
        baseUrl.searchParams.delete('returnUrl');
        baseUrl.searchParams.delete('deposit');
        return createUrl(baseUrl, {
            gateway: paymentOption.gateway,
            returnUrl: createUrl(returnUrl, { success: 'true' }).href,
            failedUrl: createUrl(returnUrl, { success: 'false' }).href,
            type: paymentOption.id === 'deposit' ? 'deposit' : 'full',
            deposit: paymentOption.id === 'deposit' ? 'true' : 'false',
        });
    }, [convertUrl, paymentOption, reservationReference, summary.pickUpLocation?.CountryCode, trackingId]);

    const handleOnComplete = useCallback(
        ({ success, status, country }: { success: boolean; status: string; reservationReference?: string; country?: string }) => {
            const resultUrl = getPaymentResultPath({
                success,
                status,
                reservationReference,
                query: {
                    country: country as string,
                },
            });
            navigate(resultUrl, { replace: true });
        },
        [navigate, reservationReference]
    );

    return convertLink ? <PaymentField data-cy="active-payment-frame" src={convertLink.href} onComplete={handleOnComplete} /> : null;
};
