import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { JucyButton } from '../../components/Button/Button';
import ReservationSummaryCard from '../../components/Reservation/ReservationSummaryCard';
import { PageTitle } from '../../components/Typograpgy/Title';
import { useRouteNavigate } from '../../hooks/useNavigateRoute';
import { useSearchParams } from '../../hooks/useSearchParams';
import Events from '../../lib/Events';
import AvailabilityStore from '../../store/AvailabilityStore';
import ReservationStore from '../../store/ReservationStore';

const DirectConfirmedEmailedPage = observer(() => {
    const [{ lastName }] = useSearchParams();
    const params = useParams();
    const navigate = useRouteNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [activeQuote, setActiveQuote] = useState(ReservationStore.activeQuote);
    const storeActiveQuote = ReservationStore.activeQuote;
    if (!isLoading && ReservationStore.state !== 'error' && (!activeQuote || activeQuote.ReservationReference !== params.reservationReference)) {
        setIsLoading(true);
        ReservationStore.fetchReservation(params.reservationReference, lastName).then((res) => {
            ReservationStore.setActiveQuote(res);
            setIsLoading(false);
        });
    }
    useEffect(() => {
        if (storeActiveQuote) {
            setActiveQuote(storeActiveQuote);
            setIsLoading(false);
        }
        if (storeActiveQuote) {
            Events.trackPurchase(storeActiveQuote);
        }
    }, [storeActiveQuote]);

    return (
        <div className="main-content col-12 col-lg-8">
            <div className="h-auto">
                <PageTitle sx={{ py: { xs: 1, md: 3 } }} data-cy="page-title">
                    Quote Sent
                </PageTitle>
                {activeQuote && (
                    <>
                        <div className="fw-bold h6 pb-4">Booking reference: {ReservationStore.bookingNo}</div>
                        <p>Your quote has been sent to {ReservationStore.activeQuote.HirerDetails.Email}. It will expire in 3 days.</p>

                        <ReservationSummaryCard reservation={activeQuote} editable={false} />
                        <JucyButton
                            className="mt-5"
                            variant="contained"
                            onClick={() => {
                                AvailabilityStore.reset();
                                ReservationStore.reset();
                                navigate('direct-create-quote', {
                                    params: {
                                        fleetType: activeQuote.FleetType.slug,
                                    },
                                });
                            }}
                        >
                            Back to Homepage
                        </JucyButton>
                    </>
                )}
            </div>
        </div>
    );
});
DirectConfirmedEmailedPage.displayName = 'DirectConfirmedEmailedPage';

export default DirectConfirmedEmailedPage;
