import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import ErrorReporter from '#/lib/ErrorReporter';
import { JucyErrorAlert } from '../../components/Alert/JucyErrorAlert';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import SummaryColumnLayout from '../../containers/SummaryColumnLayout';
import AppStateStore from '../../store/AppStateStore';
import ReservationStore from '../../store/ReservationStore';

const QuotePage = observer(({ child: Child }) => {
    const { reservationReference } = useParams();
    useEffect(() => {
        if (reservationReference) {
            ReservationStore.setActiveReservationReference(reservationReference).catch(ErrorReporter.captureError);
        }
    }, [reservationReference]);
    return (
        <SummaryColumnLayout routeId="edit-quote" summary={ReservationStore.summary}>
            {(ReservationStore.state === 'done' || ReservationStore.state === 'pending') && ReservationStore.activeQuote?.ReservationReference === reservationReference ? (
                <Child activeQuote={ReservationStore.activeQuote} />
            ) : ReservationStore.state === 'error' && ReservationStore.error?.status === 404 ? (
                <div>
                    <JucyErrorAlert title="Reservation not found" className="animate__animated animate__fadeIn me-auto w-100 mb-0 mt-4">
                        <p className="mb-0">We&apos;re sorry, the reservation with reference {reservationReference} was not found in your account</p>
                        <br />
                        <Link to={`/${AppStateStore.mode}`}>Start a new quote </Link>
                    </JucyErrorAlert>
                </div>
            ) : ReservationStore.state === 'error' ? (
                <JucyErrorAlert title="Error loading reservation" message={ReservationStore.message} />
            ) : (
                <LoadingAnimation variant="primary" className="animate__animated animate__fadeIn" />
            )}
        </SummaryColumnLayout>
    );
});
QuotePage.displayName = 'QuotePage';

export default QuotePage;
