import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { runInAction } from 'mobx';
import { useRouteNavigate } from '../../../../hooks/useNavigateRoute';
import Quote from '../../../../lib/api/model/Quote';
import { isDefaultHirerEmail } from '../../../../lib/isDefaultHirerEmail';
import { isDefaultHirerName } from '../../../../lib/isDefaultHirerName';
import ReservationStore from '../../../../store/ReservationStore';
import { AgentHirerDetailsForm } from '../../../Forms';
import { AgentHirerDetailsFormProps } from '../../../Forms/HirerDetailsForm/AgentHirerDetailsForm';

const AgentHirerDetailsTab: React.FC = observer(() => {
    const navigate = useRouteNavigate();
    const quote = ReservationStore.activeQuote || ({} as Quote);
    const { HirerDetails, AgentVoucherReference, AgentName } = quote;
    const { FirstName, LastName, Email, MobileNumber } = HirerDetails || {};
    const onHirerDetailsFormSubmit: AgentHirerDetailsFormProps['onSubmit'] = useCallback(
        (values) => {
            if (!ReservationStore.activeQuote?.InsuranceLine) {
                toast.error('Excess Reduction product must be selected to save');
            } else {
                runInAction(() => {
                    if (ReservationStore.activeQuote) {
                        ReservationStore.activeQuote.HirerDetails.FirstName = values.firstName.trim();
                        ReservationStore.activeQuote.HirerDetails.LastName = values.lastName.trim();
                        ReservationStore.activeQuote.HirerDetails.Email = values.email;
                        ReservationStore.activeQuote.HirerDetails.MobileNumber = values.mobileNumber || '';
                        ReservationStore.activeQuote.AgentName = values.agentName || undefined;
                    }
                    ReservationStore.setAgentVoucherReference(values.agentVoucherReference || '');
                });
                ReservationStore.updateReservation().then(() => {
                    toast.success('Quote saved');
                    navigate('quote', {
                        params: {
                            reservationReference: ReservationStore.activeQuote?.ReservationReference,
                        },
                    });
                });
            }
        },
        [navigate]
    );
    const [initialValues] = useState(() => {
        const initialValues = {
            firstName: FirstName || '',
            lastName: LastName || '',
            email: Email || '',
            agentVoucherReference: AgentVoucherReference || '',
            agentName: AgentName || '',
            mobileNumber: MobileNumber || '',
        };

        if (isDefaultHirerEmail(initialValues.email)) {
            initialValues.email = '';
        }

        if (isDefaultHirerName(initialValues.firstName, initialValues.lastName)) {
            initialValues.firstName = '';
            initialValues.lastName = '';
            initialValues.mobileNumber = '';
        }
        return initialValues;
    });

    return <AgentHirerDetailsForm summary={ReservationStore.summary} initialValues={initialValues} onSubmit={onHirerDetailsFormSubmit} />;
});
AgentHirerDetailsTab.displayName = 'AgentHirerDetailsTab';
export default AgentHirerDetailsTab;
