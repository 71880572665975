import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Box, BoxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import Image from 'next/image';
import appStateStore from '../../../store/AppStateStore';
import { useBrand } from '../../../store/BrandStore';

interface LogoLinkProps extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
    sx?: SxProps<Theme>;
}

const LogoLink: React.FC<LogoLinkProps> = observer(({ children, ...props }) => {
    const link = appStateStore.mode === 'agent' ? `/${appStateStore.mode}` : appStateStore.websiteUrl;

    if (link.startsWith('http')) {
        return (
            <Box component={'a'} href={link} rel="noreferrer" {...props}>
                {children}
            </Box>
        );
    }
    return (
        <Box component={Link} to={link} {...props}>
            {children}
        </Box>
    );
});

export const Logo: React.FC<BoxProps> = observer((props) => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const version = process.env.NEXT_PUBLIC_APP_VERSION || '';
    const brand = useBrand();
    return (
        <Box display="flex" alignItems="center" {...props}>
            <LogoLink
                data-cy="home-nav"
                sx={{
                    display: 'flex',
                    textDecoration: 'none',
                    alignItems: 'center',
                    marginRight: 2,
                }}
                title={`${brand.brand === 'jucy' ? 'JUCY' : 'Star'} Online Booking Engine${version ? ` - v${version}` : ''}`}
            >
                <Image priority={true} style={{ marginRight: 5, width: isXs ? '87px' : '133px', height: 'auto' }} src={brand.logo} alt={brand.name} width={133} height={48} />
            </LogoLink>
        </Box>
    );
});
