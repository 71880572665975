import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Box } from '@mui/material';
import { runInAction } from 'mobx';
import LoadingAnimation from '../components/LoadingAnimation/LoadingAnimation';
import { useSearchParams } from '../hooks/useSearchParams';
import getPaymentResultPath from '../lib/getPaymentResultPath';
import { postPaymentDataMessage } from '../lib/payments';
import AppStateStore from '../store/AppStateStore';
import { StripeProvider } from '#/components/StripeProvider';
import { useStripe } from '@stripe/react-stripe-js';
import PageLoader from '#/components/LoadingAnimation/PageLoader';
import { JucyCountryCode } from '@jucy/rentals-common';

const Loader: React.FC = () => (
    <Box sx={{ flex: '1 1', pt: 3 }}>
        <LoadingAnimation variant="primary" className="animate__animated animate__fadeIn" />
    </Box>
);

const PaymentCallbackPage: React.FC<{
    reservationReference: string;
    success: boolean;
    status?: string;
    message?: string;
    lastName?: string;
    country?: string;
}> = ({ reservationReference, success, status, message, lastName, country }) => {
    const navigate = useNavigate();
    useEffect(() => {
        if (window.self !== window.top) {
            postPaymentDataMessage({ event: 'complete', reservationReference, success, status, message, lastName, country });
        } else {
            runInAction(() => {
                AppStateStore.isPaymentCallback = false;
                AppStateStore.ui.showHeader = true;
                AppStateStore.ui.showFooter = true;
            });
            const query = {
                reservationReference,
                ...(message ? { message } : {}),
                ...(lastName ? { lastName } : {}),
                ...(country ? { country } : {}),
            };
            const navigateTO = getPaymentResultPath({ success, status, query });
            navigate(navigateTO, { replace: true });
        }
    }, [reservationReference, success, status, message, navigate, lastName, country]);
    return null;
};
PaymentCallbackPage.displayName = 'PaymentCallbackPage';

const PaymentCallbackPageLoader: React.FC = observer(() => {
    const [{ ref, success, status, message, lastName, country, payment_intent_client_secret, gateway }] = useSearchParams<{
        ref: string;
        success: string;
        status: string;
        lastName: string;
        country: string;
        message: string;
        payment_intent_client_secret?: string;
        gateway?: string;
    }>();
    if (payment_intent_client_secret && gateway === 'stripe' && ref && lastName && country) {
        return <StripePaymentCallbackPage clientSecret={payment_intent_client_secret} lastName={lastName} countryCode={country as JucyCountryCode} reservationReference={ref} />;
    }
    return (
        <>
            <Loader />
            {ref ? <PaymentCallbackPage reservationReference={ref} success={success === 'true'} status={status} message={message} lastName={lastName} country={country} /> : null}
        </>
    );
});
PaymentCallbackPageLoader.displayName = 'PaymentCallbackPageLoader';
export default PaymentCallbackPageLoader;

type StripeConfirmationProps = {
    lastName: string;
    countryCode: JucyCountryCode;
    reservationReference: string;
    clientSecret: string;
};

const StripePaymentCallbackPage: React.FC<StripeConfirmationProps> = (props) => (
    <StripeProvider options={{ clientSecret: props.clientSecret }} countryCode={props.countryCode}>
        <StripePaymentCallbackPageComponent {...props} />
    </StripeProvider>
);

const StripePaymentCallbackPageComponent: React.FC<StripeConfirmationProps> = ({ clientSecret, lastName, countryCode, reservationReference }) => {
    const navigate = useNavigate();
    const stripe = useStripe();

    //@TODO - use react query - check with lanier that payment is saved
    useEffect(() => {
        if (!stripe) {
            return;
        }

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            const resultUrl = getPaymentResultPath({
                success: paymentIntent?.status === 'succeeded',
                reservationReference,
                query: {
                    country: countryCode,
                },
            });
            navigate(resultUrl, { replace: true });
        });
    }, [stripe, clientSecret, reservationReference, lastName, countryCode, navigate]);
    return (
        <Box sx={{ flex: '1 1' }}>
            <PageLoader hasContainer={true} />
        </Box>
    );
};
