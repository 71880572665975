import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo, useRef } from 'react';
import { useParams } from 'react-router';
import { Box, Paper, Typography } from '@mui/material';
import { AvailabilityGridProps } from '../../../../components/FleetCategory/components/AvailabilityGrid/AvailabilityGrid';
import { SearchFormContainerProps } from '../../../../components/Forms/TripSearchForm/SearchFormContainerProps';
import { getBodyTopOffset } from '../../../../lib/getBodyTopOffset';
import AvailabilityStore from '../../../../store/AvailabilityStore';
import FleetTypesStore from '../../../../store/FleetTypesStore';
import { BookingCart } from '../../../../types/BookingCart';
import TripResultsTab from './TripResultsTab';
import { TripSearchFrom, TripSearchFromProps } from './TripSearch';

export type TripSearchTabVariants = 'standalone' | 'plain';

export interface TripSearchTabProps {
    variant?: TripSearchTabVariants;
    summary?: BookingCart;
    showResults?: boolean;
    onSubmit: SearchFormContainerProps['onSubmit'];
}

export interface TripSearchTabPropsWithOutResultsProps extends TripSearchTabProps {
    showResults?: false;
}

export interface TripSearchTabPropsWithResultsProps extends TripSearchTabProps {
    showResults: true;
    onFleetCategorySelected: AvailabilityGridProps['onSelect'];
}

const TripSearchTab: React.FC<TripSearchTabPropsWithOutResultsProps | TripSearchTabPropsWithResultsProps> = observer(({ variant, summary, onSubmit, ...props }) => {
    const availabilityGridRef = useRef<HTMLDivElement>(null);
    const { fleetTypeOrResRef } = useParams<{ fleetTypeOrResRef: string }>();
    const fleetTypeSlug = useMemo(() => {
        if ((!summary || summary?.action === 'create') && fleetTypeOrResRef) {
            return fleetTypeOrResRef;
        }
        if (summary?.fleetCategory?.fleetTypeCode) {
            return summary.fleetCategory.fleetTypeCode;
        }
        return FleetTypesStore.getDefault().slug;
    }, [fleetTypeOrResRef, summary]);

    const handleSummit: TripSearchFromProps['onSubmit'] = useCallback(
        async (values, formikHelpers) => {
            await onSubmit(values, formikHelpers);
            const timer = setTimeout(() => {
                if (availabilityGridRef.current) {
                    window.scrollTo({
                        top: availabilityGridRef.current.getBoundingClientRect().top - getBodyTopOffset(),
                        behavior: 'smooth',
                    });
                }
            }, 40);
            return () => {
                if (timer) {
                    clearTimeout(timer);
                }
            };
        },
        [onSubmit]
    );
    return (
        <Box mt={2}>
            {variant === 'standalone' ? (
                <Paper
                    variant="outlined"
                    sx={(t) => ({
                        padding: 2,
                        background: t.palette.grey[100],
                        borderColor: t.palette.grey[300],
                    })}
                >
                    <Typography
                        variant="h1"
                        sx={() => ({
                            fontSize: '27px',
                            fontWeight: 600,
                        })}
                        gutterBottom={true}
                    >
                        Let&apos;s find your ideal vehicle
                    </Typography>
                    <TripSearchFrom defaultFleetTypeSlug={fleetTypeSlug} summary={summary} onSubmit={handleSummit} />
                </Paper>
            ) : (
                <TripSearchFrom defaultFleetTypeSlug={fleetTypeSlug} summary={summary} onSubmit={handleSummit} />
            )}
            {props.showResults && AvailabilityStore.state !== 'pending' && props.onFleetCategorySelected ? (
                <Box ref={availabilityGridRef} mt={2}>
                    <TripResultsTab summary={summary} onSelect={props.onFleetCategorySelected} title={variant === 'standalone' ? 'Results' : undefined} />
                </Box>
            ) : null}
        </Box>
    );
});
TripSearchTab.displayName = 'TripSearchTab';
export default TripSearchTab;
