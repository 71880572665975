import { useCallback } from 'react';
import { toast } from 'react-toastify';
import ErrorReporter from '#/lib/ErrorReporter';
import { asError } from '#/lib/asError';
import { isAbortError } from '#/lib/isAbortError';
import Events from '../../../lib/Events';
import Product from '../../../lib/api/model/Product';
import { BookingCart } from '../../../types/BookingCart';
import { TripTabPanelProps } from '../components';

export const useHandleOnExtrasSelected = ({ summary }: { summary?: BookingCart }): TripTabPanelProps['onExtrasSelected'] =>
    useCallback(
        async (item: Product, delta: number) => {
            try {
                if (summary?.amendments?.updateSelectedExtras && summary.action === 'edit') {
                    summary.amendments.updateSelectedExtras(item.asV3ProductLine());
                } else if (summary) {
                    summary.updateSelectedExtras(item.asV3ProductLine());
                }
                Events.trackExtrasCartEvent(delta > 0 ? 'add' : 'remove', item, delta, summary);
            } catch (e) {
                const error = asError(e);
                if (isAbortError(error)) {
                    return;
                }
                ErrorReporter.captureError(error);
                toast.error(error.message);
            }
        },
        [summary]
    );
