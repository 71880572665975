import * as React from 'react';
import { Fade } from '@mui/material';
import Spinner, { SpinnerProps } from './Spinner';

const DelayedSpinner: React.FC<SpinnerProps> = (props) => {
    const [loading, setLoading] = React.useState(false);
    const timerRef = React.useRef<number>();

    React.useEffect(
        () => () => {
            timerRef.current = window.setTimeout(() => {
                setLoading(true);
            }, 1000);
            clearTimeout(timerRef.current);
        },
        []
    );

    return (
        <Fade
            in={loading}
            style={{
                transitionDelay: '800ms',
            }}
            unmountOnExit
        >
            <Spinner {...props} />
        </Fade>
    );
};
export default DelayedSpinner;
