import React from 'react';
import { LoadingContainer } from '#/components/LoadingContainer';
import { StripeProvider } from './StripeProvider';
import { Alert, AlertTitle, Box, Card, CardContent, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { StripePaymentForm } from './StripePaymentForm';
import ErrorReporter from '#/lib/ErrorReporter';
import { jucyPay, ReservationPaymentTokenData } from '#/services/jucyPay';
import { StripeError } from '@stripe/stripe-js';

interface StripePaymentFieldProps {
    tokenData?: ReservationPaymentTokenData;
    returnUrl: string;
    reservationReference: string;
    onComplete?: (value: { success: boolean }) => void;
    balanceDue?: number;
    mode?: 'storeCard' | 'purchase';
    email?: string;
    onEmailChange?: (email: string) => void;
    onError?: (error: StripeError) => void;
}

export const StripePaymentField: React.FC<StripePaymentFieldProps> = ({
    tokenData: passedTokenData,
    onComplete,
    email,
    onError,
    onEmailChange,
    balanceDue,
    returnUrl,
    reservationReference,
    mode,
}) => {
    const {
        data: tokenData,
        isLoading,
        error,
    } = useQuery({
        queryKey: ['pay-balance-due-token', reservationReference, balanceDue, returnUrl],
        queryFn: async () =>
            jucyPay.getReservationPaymentToken({
                storeCard: mode === 'storeCard',
                reservationReference: reservationReference as string,
                amount: Number(balanceDue),
                returnUrl: returnUrl,
            }),
        refetchInterval: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        enabled: !passedTokenData,
        initialData: passedTokenData,
    });

    if (error) {
        ErrorReporter.reportError({
            error: error as Error,
            tags: {
                location: 'StripePayBalanceDueField',
            },
        });
        return (
            <div>
                <Alert severity="error">
                    <AlertTitle>Whoops!</AlertTitle>
                    <p>We have run in to an unexpected error</p>
                    {(error as Error).message}
                </Alert>
            </div>
        );
    }

    const ready = !isLoading && tokenData?.token && tokenData?.countryCode && tokenData?.confirmUrl;

    return (
        <Box mb={2}>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="subtitle1" component="h2" sx={{ fontWeight: 'normal', mb: 1 }}>
                        {mode === 'storeCard' ? 'Add card on file' : 'Payment details'}
                    </Typography>
                    <Box sx={{ px: 1 }}>
                        <LoadingContainer isLoading={isLoading}>
                            {ready ? (
                                <StripeProvider options={{ clientSecret: tokenData?.token }} countryCode={tokenData.countryCode}>
                                    <StripePaymentForm
                                        email={email}
                                        onEmailChange={onEmailChange}
                                        confirmParams={{ return_url: tokenData.confirmUrl }}
                                        onError={(error) => {
                                            onError?.(error);
                                        }}
                                        mode={mode}
                                        onComplete={(paymentIntent) => {
                                            onComplete?.({ success: paymentIntent.status === 'succeeded' });
                                        }}
                                    />
                                </StripeProvider>
                            ) : null}
                        </LoadingContainer>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};
