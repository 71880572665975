import { observer } from 'mobx-react-lite';
import React, { Suspense, lazy, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useMediaQuery, useTheme } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';
import { useActiveRoute, useRouteNavigate } from '../../hooks';
import AppStateStore from '../../store/AppStateStore';
import AvailabilityStore from '../../store/AvailabilityStore';
import FleetTypesStore from '../../store/FleetTypesStore';
import RentalTripSearchStore from '../../store/RentalTripSearchStore';
import ReservationStore from '../../store/ReservationStore';
import TranslationStore from '../../store/TranslationStore';
import DelayedSpinner from '../LoadingAnimation/DelayedSpinner';
import { JucyMuiTab, JucyMuiTabPanel, JucyMuiTabs } from '../Tabs';
import PaymentTab from './DirectPaymentTab';
import { RentalsReservationTabNames } from './RentalsReservationTabNames';

const DirectHirerDetailsTab = lazy(() => import('./Tabs/Direct/DirectHirerDetailsTab'));
const DirectSearchResultsTab = lazy(() => import('./Tabs/Direct/DirectSearchResultsTab'));
const SelectExcessTab = lazy(() => import('./Tabs/Common/SelectExcessTab'));
const SelectExtrasTab = lazy(() => import('./Tabs/Common/SelectExtrasTab'));
const getTabs = (t) => [
    {
        id: RentalsReservationTabNames.searchResults,
        label: () => t('top_nav.group.search_results'),
        showRight: true,
        component: DirectSearchResultsTab,
        enabled: ({ RentalTripSearchStore }) => Boolean(!RentalTripSearchStore.isRelocation),
        visible: () => true,
    },
    {
        id: RentalsReservationTabNames.excessReduction,
        label: () => t('top_nav.group.excess_reduction'),
        showRight: true,
        component: SelectExcessTab,
        onSelectRouteDetails: {
            routeId: 'direct-edit-quote',
            params: {
                activeTab: RentalsReservationTabNames.extras,
            },
        },
        enabled: ({ ReservationStore }) => Boolean(ReservationStore.activeQuote && ReservationStore.activeQuote.InsuranceProducts[0].Items.length > 0),
        visible: () => true,
    },
    {
        id: RentalsReservationTabNames.extras,
        label: () => t('top_nav.group.extras'),
        showRight: true,
        component: SelectExtrasTab,
        onSelectRouteDetails: {
            routeId: 'direct-edit-quote',
            params: {
                activeTab: RentalsReservationTabNames.driver,
            },
        },
        enabled: ({ ReservationStore }) => Boolean(ReservationStore.activeQuote && ReservationStore.activeQuote.InsuranceLine),
        visible: () => true,
    },
    {
        id: RentalsReservationTabNames.driver,
        label: () => t('top_nav.group.driver'),
        showRight: true,
        component: DirectHirerDetailsTab,
        onSelectRouteDetails: {
            routeId: 'direct-edit-quote',
            params: {
                activeTab: RentalsReservationTabNames.payment,
            },
        },
        enabled: ({ ReservationStore }) => Boolean(ReservationStore.activeQuote && ReservationStore.activeQuote.InsuranceLine),
        visible: ({ ReservationStore }) => Boolean(ReservationStore.activeQuote && ReservationStore.activeQuote.FleetCategory),
    },
    {
        id: RentalsReservationTabNames.payment,
        label: () => t('top_nav.group.payment'),
        showRight: false,
        component: PaymentTab,
        enabled: ({ ReservationStore }) => Boolean(ReservationStore.activeQuote && (ReservationStore.activeQuote.PaymentURL || ReservationStore.activeQuote.PaymentToken)),
        visible: ({ ReservationStore }) => Boolean(ReservationStore.activeQuote && ReservationStore.activeQuote.FleetCategory.paymentEnabled),
    },
];

const findTabName = (query) => {
    if (query) {
        if (RentalsReservationTabNames[query]) {
            return RentalsReservationTabNames[query];
        } else if (Object.values(RentalsReservationTabNames).includes(query)) {
            return query;
        }
    }
    return RentalsReservationTabNames.searchResults;
};

const DirectRentalsReservationTabSet = observer(({ setShowRight, activeTab: initialActiveTab }) => {
    const [activeTab, setActiveTab] = useState(() => findTabName(initialActiveTab));
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const activeRoute = useActiveRoute();
    const navigate = useRouteNavigate();
    const params = useParams();
    const activeRouteTab = params?.activeTab;
    const fleetType = FleetTypesStore.activeFleetTypeSlug;
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const tabs = useMemo(() => getTabs(t), [t]);
    const visibleTabs = tabs.filter((t) => t.visible({ ReservationStore, RentalTripSearchStore, AppStateStore }));
    const enabledTabs = visibleTabs.filter((t) =>
        t.enabled({
            ReservationStore,
            RentalTripSearchStore,
            AppStateStore,
        })
    );
    const currentTab = activeTab && visibleTabs.find((t) => t.id === activeTab);
    useEffect(() => {
        if (!enabledTabs.find((t) => t.id === activeTab)) {
            const prevTab = enabledTabs[enabledTabs.length - 1];

            navigate(activeRoute.id, {
                params: {
                    ...params,
                    activeTab: prevTab.id,
                },
                search: window.location.search,
            });
        }
    }, [visibleTabs, activeTab, params, enabledTabs, navigate, activeRoute.id]);
    useEffect(() => {
        const tabName = findTabName(activeRouteTab || initialActiveTab || RentalsReservationTabNames.searchResults);
        setActiveTab(tabName);
    }, [activeRouteTab, initialActiveTab]);
    useEffect(() => {
        if (currentTab) {
            setShowRight(currentTab.showRight);
        }
    }, [currentTab, setShowRight]);
    useEffect(() => {
        if (activeTab === RentalsReservationTabNames.driver && ReservationStore.activeQuote && !ReservationStore.activeQuote.InsuranceLine) {
            navigate(activeRoute.id, {
                params: {
                    ...params,
                    activeTab: RentalsReservationTabNames.excessReduction,
                },
                search: window.location.search,
            });
        }
    }, [activeRoute.id, activeTab, navigate, params]);

    const toggleTab = useCallback(
        (e, tab) => {
            if (activeRoute?.path.includes(':activeTab') && activeTab !== tab) {
                navigate(activeRoute.id, { params: { ...params, activeTab: tab }, search: window.location.search });
            } else if (activeTab !== tab) {
                setActiveTab(tab);
            }
            window.scrollTo(0, 0);
        },
        [activeRoute?.path, activeRoute.id, activeTab, navigate, params]
    );
    return (AvailabilityStore.state !== 'pending' && AvailabilityStore.availableCount > 0) || ReservationStore.activeQuote ? (
        <>
            <JucyMuiTabs
                value={activeTab}
                onChange={toggleTab}
                variant="scrollable"
                scrollButtons={isXs ? true : 'auto'}
                allowScrollButtonsMobile={true}
                sx={{
                    [`& .${tabsClasses.flexContainer}`]: {
                        width: 0,
                    },
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                }}
            >
                {visibleTabs.map((t) => (
                    <JucyMuiTab
                        label={t.label()}
                        a11yName="rentals"
                        data-cy={`tab-${t.id}`}
                        key={`tab-item-${t.id}`}
                        value={t.id}
                        disabled={!t.enabled({ ReservationStore, RentalTripSearchStore, AppStateStore })}
                    />
                ))}
            </JucyMuiTabs>
            <Suspense fallback={<DelayedSpinner center={true} />}>
                {visibleTabs.map((t) => (
                    <JucyMuiTabPanel key={`tab-pane-${t.id}`} value={t.id} activeValue={activeTab} a11yName="rentals" className="animate__animated animate__fadeIn">
                        {t.onSelectRouteDetails ? <t.component onSelectRouteDetails={{ ...t.onSelectRouteDetails, fleetType: fleetType }} /> : <t.component />}
                    </JucyMuiTabPanel>
                ))}
            </Suspense>
        </>
    ) : (
        <></>
    );
});
DirectRentalsReservationTabSet.displaName = 'DirectRentalsReservationTabSet';
export default DirectRentalsReservationTabSet;
