import { CountryUrlSwitcher } from '#/components/CountryUrlSwitcher';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import PageLoader from '#/components/LoadingAnimation/PageLoader';
import RouteRegistrySync from '#/components/Route/RouteRegistrySync';
import config from '#/config';
import { loginFlowPaths } from '#/contexts/JucyAuthContext';
import { ObeProvider } from '#/layouts/ClientOnlyObeLayout/components/ObeProviders';
import { BrandPageProps, getBrandPageStaticProps, getBrandStaticPaths } from '#/lib/brand';
import { Auth0CallbackPage, PageNotFound } from '#/obePages';
import { RegistryRoute } from '#/store/RouteRegistry';
import { NextPage } from 'next';
import ObeLayout from '../../layouts/ClientOnlyObeLayout';
import routes from '../../obePages/routes';

export const AppContainerElement: React.FC<{ route: RegistryRoute }> = ({ route }) => (
    <RouteRegistrySync route={route}>
        <ObeLayout>{route.element}</ObeLayout>
    </RouteRegistrySync>
);

const ObePage: NextPage<BrandPageProps> = observer(({ brand }) => {
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setLoaded(true);
    }, []);

    if (!loaded) {
        return <PageLoader id="page" brand={brand}></PageLoader>;
    }
    return (
        <BrowserRouter basename="/obe">
            <ObeProvider>
                <CountryUrlSwitcher />
                <Routes>
                    <Route path={config.auth0RedirectPath} element={<Auth0CallbackPage />} />
                    {routes.map((route) => {
                        if (route.redirect) {
                            return <Route key={route.path} path={route.path} element={<Navigate to={route.redirect} replace />} />;
                        } else if (loginFlowPaths.some((p) => route.path.startsWith(p))) {
                            return <Route key={route.path} path={route.path} element={route.element} />;
                        }
                        return <Route key={route.path} path={route.path} element={<AppContainerElement route={route} />} />;
                    })}
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </ObeProvider>
        </BrowserRouter>
    );
});
ObePage.displayName = 'ObePage';
export default ObePage;

export const getStaticProps = getBrandPageStaticProps;

export const getStaticPaths = getBrandStaticPaths;
