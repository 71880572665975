import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DialogActions, DialogContent, DialogTitle, SxProps, Theme } from '@mui/material';
import { useAuth } from '../../../hooks';
import AppStateStore from '../../../store/AppStateStore';
import appState from '../../../store/AppStateStore';
import TranslationStore from '../../../store/TranslationStore';
import { JucyButton } from '../../Button/Button';
import ModalDialog from '../../ModalDialog';
import { PageTitle } from '../../Typograpgy/Title';

export const ExitLink: React.FC<{ sx?: SxProps<Theme> }> = observer(({ sx }) => {
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const [showExitConfirmationModal, setShowExitConfirmationModal] = useState(false);
    const { user } = useAuth();
    return (
        <>
            {user?.isAuthenticated ? (
                <JucyButton component={Link} to="/logout" size="small" sx={sx}>
                    Logout
                </JucyButton>
            ) : (
                <JucyButton
                    size="small"
                    component="a"
                    sx={sx}
                    href={appState.websiteUrl}
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.preventDefault();
                        setShowExitConfirmationModal(true);
                    }}
                >
                    {t('app.back_to_website', 'Back to JUCY website')}
                </JucyButton>
            )}
            <ExitConfirmationModal showModal={showExitConfirmationModal} setShowModal={setShowExitConfirmationModal} exitLink={AppStateStore.websiteUrl} />
        </>
    );
});

const ExitConfirmationModal: React.FC<{ showModal?: boolean; setShowModal: (show: boolean) => void; exitLink: string }> = observer(({ showModal, setShowModal, exitLink }) => (
    <ModalDialog open={Boolean(showModal)} onClose={() => setShowModal(!showModal)} maxWidth="sm">
        <DialogTitle component="div">
            <PageTitle>Exit reservation</PageTitle>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: 0 }}>Are you sure you want to exit the reservation path and discard your trip details?</DialogContent>
        <DialogActions>
            <JucyButton color="light" data-cy="email-quote-submit" type="submit" variant="contained" onClick={() => (window.location.href = exitLink)}>
                Yes, exit reservation
            </JucyButton>
            <JucyButton data-cy="email-quote-submit" type="submit" variant="contained" onClick={() => setShowModal(!showModal)}>
                No, back to reservation
            </JucyButton>
        </DialogActions>
    </ModalDialog>
));
ExitConfirmationModal.displayName = 'ConfirmationModal';
