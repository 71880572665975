import { FormikContextType } from 'formik';
import { makeAutoObservable, reaction } from 'mobx';
import { DirectHirerDetailsFormValues } from '../components/Forms/HirerDetailsForm/DirectHirerDetailsSchema';
import { HirerDetailsSummary } from '../lib/api/model/HirerDetailsSummary';
import Quote from '../lib/api/model/Quote';
import { isDefaultHirerEmail } from '../lib/isDefaultHirerEmail';
import { isDefaultHirerName } from '../lib/isDefaultHirerName';
import reservationStore from './ReservationStore';
import tripStore from './TripStore';
import { datadogRum } from '@datadog/browser-rum';

export class HireDetailsStore implements HirerDetailsSummary {
    firstName = '';
    lastName = '';
    mobileNumber = '';
    email = '';
    driversLicenceCountry = '';
    acceptedTerms = false;
    mailingList = false;
    numberOfPeople = 0;
    voucherReference: string | undefined = '';
    agentName: string | undefined = '';

    constructor(props?: Partial<HireDetailsStore>) {
        Object.assign(this, props);
        makeAutoObservable(this);
        reaction(
            () => this.firstName,
            () => {
                tripStore.hirerDetails.firstName = this.firstName;
                if (reservationStore?.activeQuote) {
                    reservationStore.activeQuote.HirerDetails.FirstName = this.firstName;
                }
            }
        );
        reaction(
            () => this.lastName,
            () => {
                tripStore.hirerDetails.lastName = this.lastName;
                if (reservationStore?.activeQuote) {
                    reservationStore.activeQuote.HirerDetails.LastName = this.lastName;
                }
            }
        );
        reaction(
            () => this.mobileNumber,
            () => {
                tripStore.hirerDetails.mobileNumber = this.mobileNumber;
                if (reservationStore?.activeQuote) {
                    reservationStore.activeQuote.HirerDetails.MobileNumber = this.mobileNumber;
                }
            }
        );
        reaction(
            () => this.email,
            () => {
                datadogRum.setUserProperty('hirerEmail', this.email);
                tripStore.hirerDetails.email = this.email;
                if (reservationStore?.activeQuote) {
                    reservationStore.activeQuote.HirerDetails.Email = this.email;
                }
            }
        );
        reaction(
            () => this.driversLicenceCountry,
            () => {
                tripStore.hirerDetails.driversLicenceCountry = this.driversLicenceCountry;
                if (reservationStore?.activeQuote) {
                    reservationStore.activeQuote.HirerDetails.DriversLicenceCountry = this.driversLicenceCountry;
                }
            }
        );
        reaction(
            () => this.acceptedTerms,
            () => {
                tripStore.hirerDetails.acceptedTerms = this.acceptedTerms;
                if (reservationStore?.activeQuote) {
                    reservationStore.activeQuote.acceptedTerms = this.acceptedTerms;
                }
            }
        );
        reaction(
            () => this.mailingList,
            () => {
                tripStore.hirerDetails.mailingList = this.mailingList;
                if (reservationStore?.activeQuote) {
                    reservationStore.activeQuote.HirerDetails.OptIn = this.mailingList;
                }
            }
        );
        reaction(
            () => this.numberOfPeople,
            () => {
                tripStore.hirerDetails.numberOfPeople = this.numberOfPeople;
                if (reservationStore?.activeQuote) {
                    reservationStore.activeQuote.HirerDetails.NumberOfPeople = this.numberOfPeople;
                }
            }
        );

        reaction(
            () => tripStore.isHydrated,
            (current, prev) => {
                if (current && current !== prev) {
                    this.firstName = tripStore.hirerDetails.firstName;
                    this.lastName = tripStore.hirerDetails.lastName;
                    this.mobileNumber = tripStore.hirerDetails.mobileNumber;
                    this.email = tripStore.hirerDetails.email;
                    this.driversLicenceCountry = tripStore.hirerDetails.driversLicenceCountry;
                    this.acceptedTerms = tripStore.hirerDetails.acceptedTerms || false;
                    this.mailingList = tripStore.hirerDetails.mailingList || false;
                    this.numberOfPeople = tripStore.hirerDetails.numberOfPeople || 1;
                }
            }
        );

        reaction(
            () => reservationStore?.activeQuote?.HirerDetails,
            (current, prev) => {
                if (current && current.Email !== prev?.Email) {
                    this.firstName = current.FirstName;
                    this.lastName = current.LastName;
                    this.mobileNumber = current.MobileNumber;
                    this.email = current.Email;
                    this.driversLicenceCountry = current.DriversLicenceCountry;
                    this.mailingList = current.OptIn;
                }
            }
        );
    }

    isDefaultHirerName() {
        return isDefaultHirerName(this.firstName, this.lastName);
    }

    isDefaultHirerEmail() {
        return isDefaultHirerEmail(this.email);
    }

    updateFromForm(values: Partial<DirectHirerDetailsFormValues>) {
        if (values.firstName && values.firstName?.trim() !== this.firstName) {
            this.firstName = values.firstName.trim();
        }
        if (values.lastName && values.lastName?.trim() !== this.lastName) {
            this.lastName = values.lastName.trim();
        }
        if (values.mobileNumber && values.mobileNumber?.trim() !== this.mobileNumber) {
            this.mobileNumber = values.mobileNumber.trim();
        }
        if (values.email && values.email?.trim() !== this.email) {
            this.email = values.email.trim();
        }
        if (values.driversLicenceCountry && values.driversLicenceCountry?.trim() !== this.driversLicenceCountry) {
            this.driversLicenceCountry = values.driversLicenceCountry.trim();
        }
        if (values.termsAndConditions !== undefined && values.termsAndConditions !== this.acceptedTerms) {
            this.acceptedTerms = values.termsAndConditions || false;
        }
        if (values.mailingList !== undefined && values.mailingList !== this.mailingList) {
            this.mailingList = values.mailingList || false;
        }
        if (values.numberOfPeople !== undefined && values.numberOfPeople !== this.numberOfPeople) {
            this.numberOfPeople = values.numberOfPeople || 1;
        }
    }

    updateFromQuote(values: Partial<Quote>) {
        if (values.HirerDetails?.FirstName && values.HirerDetails.FirstName?.trim() !== this.firstName) {
            this.firstName = values.HirerDetails.FirstName.trim();
        }
        if (values.HirerDetails?.LastName && values.HirerDetails.LastName?.trim() !== this.lastName) {
            this.lastName = values.HirerDetails.LastName.trim();
        }
        if (values.HirerDetails?.MobileNumber && values.HirerDetails.MobileNumber?.trim() !== this.mobileNumber) {
            this.mobileNumber = values.HirerDetails.MobileNumber.trim();
        }
        if (values.HirerDetails?.Email && values.HirerDetails.Email?.trim() !== this.email) {
            this.email = values.HirerDetails.Email.trim();
        }
        if (values.HirerDetails?.DriversLicenceCountry && values.HirerDetails.DriversLicenceCountry?.trim() !== this.driversLicenceCountry) {
            this.driversLicenceCountry = values.HirerDetails.DriversLicenceCountry.trim();
        }
    }

    updateContext<T extends Partial<DirectHirerDetailsFormValues>>(context: FormikContextType<T>) {
        const disposers = [
            reaction(
                () => this.firstName,
                (firstName) => {
                    if (context.values.firstName !== firstName) {
                        context.setFieldValue('firstName', firstName);
                    }
                }
            ),
            reaction(
                () => this.lastName,
                (lastName) => {
                    if (context.values.lastName !== lastName) {
                        context.setFieldValue('lastName', lastName);
                    }
                }
            ),
            reaction(
                () => this.mobileNumber,
                (mobileNumber) => {
                    if (context.values.mobileNumber !== mobileNumber) {
                        context.setFieldValue('mobileNumber', mobileNumber);
                    }
                }
            ),
            reaction(
                () => this.email,
                (email) => {
                    if (context.values.email !== email) {
                        context.setFieldValue('email', email);
                    }
                }
            ),
            reaction(
                () => this.driversLicenceCountry,
                (driversLicenceCountry) => {
                    if (context.values.driversLicenceCountry !== driversLicenceCountry) {
                        context.setFieldValue('driversLicenceCountry', driversLicenceCountry);
                    }
                }
            ),
            reaction(
                () => this.mailingList,
                (mailingList) => {
                    if (mailingList !== undefined && context.values.mailingList !== mailingList) {
                        context.setFieldValue('mailingList', mailingList);
                    }
                }
            ),
        ];
        return () => {
            disposers.forEach((d) => d());
        };
    }

    init() {
        this.firstName = '';
        this.lastName = '';
        this.mobileNumber = '';
        this.email = '';
        this.driversLicenceCountry = '';
        this.acceptedTerms = false;
        this.mailingList = false;
        this.numberOfPeople = 1;
    }
}

const hireDetailsStore = new HireDetailsStore();
export default hireDetailsStore;
