import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PageLoader from '#/components/LoadingAnimation/PageLoader';
import FatalError from '../../components/Alert/FatalError';
import AgentRentalsReservationTabSet, { RentalsReservationTabNames } from '../../components/Tab/AgentRentalsReservationTabSet';
import SummaryColumnLayout from '../../containers/SummaryColumnLayout';
import { useRouteNavigate, useTripApi } from '../../hooks';
import { useSearchParams } from '../../hooks/useSearchParams';
import AvailabilityStore from '../../store/AvailabilityStore';
import RentalTripSearchStore from '../../store/RentalTripSearchStore';
import ReservationStore from '../../store/ReservationStore';
import reservationStore from '../../store/ReservationStore';
import RouteRegistry from '../../store/RouteRegistry';

const AgentCreateQuotePage = observer((props) => {
    const [isLoading, setIsLoading] = useState(false);
    const routeId = 'create-quote';
    const { fleetType: fleetTypeSlug, activeTab } = useParams();
    const [routeQuery] = useSearchParams();
    RouteRegistry.setActiveRoute(RouteRegistry.getRouteById(routeId));
    const navigate = useRouteNavigate();
    const { mode } = useParams();
    const tripEnabled = useTripApi();

    useEffect(() => {
        if (tripEnabled) {
            navigate('create-trip', {
                params: {
                    mode,
                    fleetTypeOrResRef: fleetTypeSlug,
                    action: 'create',
                },
                search: routeQuery,
                replace: true,
            });
        }
    }, [fleetTypeSlug, mode, navigate, routeQuery, tripEnabled]);
    useEffect(() => {
        RentalTripSearchStore.init({ fleetTypeSlug });
        ReservationStore.reset();
        AvailabilityStore.reset();
    }, [fleetTypeSlug]);

    useEffect(() => {
        const routeValues = RentalTripSearchStore.fleetType && {
            driverAge: '21',
            fleetType: RentalTripSearchStore.fleetType,
            ...RentalTripSearchStore.convertValuesFromQueryString(routeQuery),
        };
        if (activeTab === 'fleet-category') {
            const loadAvailability = RentalTripSearchStore.validate(routeValues) && AvailabilityStore.state !== 'loading' && AvailabilityStore.availableCount === 0;
            if (loadAvailability) {
                setIsLoading(true);
                RentalTripSearchStore.setValues(routeValues);
                RentalTripSearchStore.performSearch().then(() => setIsLoading(false));
            }
        }
    }, [activeTab, routeQuery]);

    if (RentalTripSearchStore.state === 'pending' || isLoading) {
        return <PageLoader hasContainer={true} />;
    }

    if (RentalTripSearchStore.state === 'error') {
        return <FatalError title={RentalTripSearchStore.message} />;
    }

    return <AgentCreateQuotePageContent {...props} />;
});
AgentCreateQuotePage.displayName = 'AgentCreateQuotePage';

const AgentCreateQuotePageContent = observer(() => (
    <SummaryColumnLayout summary={reservationStore.summary} routeId="create-quote" className="animate__animated animate__fadeIn">
        <AgentRentalsReservationTabSet activeTab={RentalsReservationTabNames.tripInfo} />
    </SummaryColumnLayout>
));
AgentCreateQuotePageContent.displayName = 'AgentCreateQuotePageContent';

export default AgentCreateQuotePage;
