import React from 'react';
import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import { BookingCart } from '../../../../types/BookingCart';
import paymentStore from '../../../../store/PaymentStore';
import useGetGaTrackingId from '../../../../hooks/useGetGaTrackingId';
import { StripePaymentField } from '../../../../stripe/StripePaymentField';
import getPaymentResultPath from '../../../../lib/getPaymentResultPath';
import { useNavigate } from 'react-router-dom';
import { JucyCountryCode } from '@jucy/rentals-common';
import config from '../../../../config';
import CurrencyFormatter from '../../../CurrencyFormatter/CurrencyFormatter';
import { getPaymentCallbackUrl } from '../../../../lib/payments';

export const StripePaymentForm: React.FC<{ summary: BookingCart }> = ({ summary }) => {
    const trackingId = useGetGaTrackingId();
    const paymentOption = paymentStore.getActivePaymentOption(summary);
    const navigate = useNavigate();
    const amount = summary.isOnRequest ? 1 : Number(`${paymentOption?.total.value || 0}`);
    const confirmUrl = summary.convertUrl?.startsWith('http') ? new URL(summary.convertUrl) : new URL(summary.convertUrl || '', config.apiBaseUrl);
    if (!confirmUrl.searchParams.has('returnUrl')) {
        confirmUrl.searchParams.set('returnUrl', getPaymentCallbackUrl());
    }
    const tokenData = {
        token: summary.paymentToken as string,
        countryCode: summary.pickUpLocation?.CountryCode as JucyCountryCode,
        confirmUrl: confirmUrl.href,
        amount: amount,
    };
    return (
        <Container maxWidth="sm">
            <Card variant="outlined" sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="subtitle1" component="h2" sx={{ fontWeight: 'normal', mb: 1 }}>
                        Payment checkout
                    </Typography>
                    <Box px={1}>
                        <Typography variant="subtitle1" gutterBottom={true}>
                            Booking #{summary.pickUpLocation?.SiteCode}-{summary.reservationId}
                            {paymentOption?.id === 'deposit' ? ' deposit' : null}
                            {summary?.isOnRequest ? ' request' : null}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom={false}>
                            {summary.isOnRequest ? <CurrencyFormatter price={{ ...summary?.totalPrice, value: 1 }} prefixCurrencyCode={true} /> : paymentOption?.amount}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
            <StripePaymentField
                mode={summary.isOnRequest ? 'storeCard' : 'purchase'}
                onComplete={({ success }) => {
                    const resultUrl = getPaymentResultPath({
                        success,
                        reservationReference: summary.reservationReference,
                        query: {
                            country: summary?.pickUpLocation?.CountryCode as string,
                        },
                    });
                    navigate(resultUrl, { replace: true });
                }}
                tokenData={tokenData}
                reservationReference={summary.reservationReference as string}
                email={summary.hirerDetails.email}
                returnUrl={summary.getPaymentRedirectUrl({ _ga: trackingId })}
            />
        </Container>
    );
};
