import { useCallback } from 'react';
import { toast } from 'react-toastify';
import ErrorReporter from '#/lib/ErrorReporter';
import { asError } from '#/lib/asError';
import { isAbortError } from '#/lib/isAbortError';
import Events from '../../../lib/Events';
import InsuranceProduct from '../../../lib/api/model/InsuranceProduct';
import { BookingCart } from '../../../types/BookingCart';
import { TripTabPanelProps } from '../components';

export const useHandleOnExcessSelected = ({ summary }: { summary?: BookingCart }): TripTabPanelProps['onExcessSelected'] =>
    useCallback(
        async (excessOption: InsuranceProduct) => {
            try {
                if (summary?.selectedExcess && summary?.amendments?.fleetCategory && summary.action === 'edit') {
                    summary.amendments.selectedExcess = excessOption.asV3ProductLine();
                } else if (summary) {
                    summary.selectedExcess = excessOption.asV3ProductLine();
                }
                Events.trackAddInsuranceOption(summary);
            } catch (e) {
                const error = asError(e);
                if (isAbortError(error)) {
                    return;
                }
                ErrorReporter.captureError(error);
                toast.error(error.message);
            }
        },
        [summary]
    );
