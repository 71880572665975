import { useAuth, useUpdateRouteParams } from '#/hooks';
import appStateStore from '#/store/AppStateStore';
import hireDetailsStore from '#/store/HireDetailsStore';
import reservationStore from '#/store/ReservationStore';
import routeRegistry, { RegistryRoute } from '#/store/RouteRegistry';
import tripStore from '#/store/TripStore';
import { UserMode, userModes } from '#/types/UserMode';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';

const RouteRegistrySync: React.FC<{ route: RegistryRoute; children: React.ReactNode }> = observer(({ route, children }) => {
    const { isLoading: isAuthLoading, user } = useAuth();
    const { mode } = useParams<{ mode?: UserMode }>();
    const updateRouteParams = useUpdateRouteParams();
    const {
        push,
        query: { initial, ...query },
    } = useRouter();

    runInAction(() => {
        routeRegistry.activeRoute = route;
        if (mode && userModes.includes(mode)) {
            appStateStore.routeMode = mode || appStateStore.routeMode;
        }
    });
    useEffect(() => {
        if (!isAuthLoading && mode && userModes.includes(mode)) {
            if (mode === 'staff' && !user.isAuthenticated) {
                updateRouteParams({ mode: 'direct' }, { keepSearch: true });
            }
            if (mode === 'direct' && user.isAuthenticated) {
                updateRouteParams({ mode: 'agent' }, { keepSearch: true });
            }
        }
    }, [user, updateRouteParams, isAuthLoading, mode]);

    useEffect(() => {
        if (reservationStore.activeQuote?.id && initial == 'true') {
            reservationStore.reset();
        }

        if (tripStore.tripInfo && initial == 'true') {
            hireDetailsStore.init();
            tripStore.init();
            runInAction(() => {
                reservationStore.activeQuote = undefined;
            });
        }

        if (initial == 'true') {
            push({ pathname: window.location.pathname, query }, undefined, { shallow: true });
        }
    }, [push, query, initial]);

    return <>{children}</>;
});

export default RouteRegistrySync;
