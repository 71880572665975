import { observer } from 'mobx-react-lite';
import React from 'react';
import { useParams } from 'react-router';
import AccountKeyStore from '#/store/AccountKeyStore';
import { Box } from '@mui/material';
import { JucyErrorAlert } from '../../components/Alert/JucyErrorAlert';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import useGetAgentCMSPage from '../../hooks/useGetAgentCMSPage';

const AgentContentPage: React.FC = observer(() => {
    const params = useParams<{ '*': string }>();
    const { error, page, isLoading } = useGetAgentCMSPage({
        path: params?.['*'] || '',
        accountKey: AccountKeyStore.accountKey,
    });
    if (error) {
        return (
            <div className="pt-4 animate__animated animate__fadeIn">
                <JucyErrorAlert title="Whoops failed to load this page" message={error?.message || 'An unexpected error has occurred'}></JucyErrorAlert>
            </div>
        );
    }
    if (isLoading || !page) {
        return (
            <div className="w-100 d-flex justify-content-center pt-4 animate__animated animate__fadeIn">
                <LoadingAnimation variant="primary" className="animate__animated animate__fadeIn" />
            </div>
        );
    }

    return (
        <Box sx={{ pt: 2 }}>
            <h1 className="getJucyRentalsApiClient({apiKey:AccountKeyStore.accountKey}) h2  pb-2 pb-lg-5 mb-0">{page.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: page.content }} />
        </Box>
    );
});
export default AgentContentPage;
