import initStores from '#/store/initStores';
import { useMsal } from '@azure/msal-react';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import PageLoader from '#/components/LoadingAnimation/PageLoader';
import ErrorReporter from '#/lib/ErrorReporter';
import { asError } from '#/lib/asError';
import { useAuth, useRouteNavigate } from '../../hooks';

const StaffLoginPage: React.FC = observer(() => {
    const { user, isLoading } = useAuth();
    const { instance } = useMsal();
    const navigate = useRouteNavigate();

    const onLoginSuccess = useCallback(() => {
        initStores();
        toast.success('Logged in successfully', { autoClose: 3000, hideProgressBar: true });
        navigate('direct-home', { params: { mode: 'staff' } });
    }, [navigate]);

    useEffect(() => {
        if (!isLoading && user.type !== 'staff') {
            const handleAzureLoginRedirect = async () => {
                try {
                    const token = await instance.handleRedirectPromise();
                    if (token) {
                        onLoginSuccess();
                        return;
                    }
                    await instance.loginRedirect({ scopes: ['User.Read'] });
                } catch (e) {
                    ErrorReporter.captureError(asError(e));
                    toast.error('Unable to login to booking portal', { autoClose: 3000, hideProgressBar: true });
                    navigate('direct-home', { params: { mode: 'staff' } });
                }
            };
            handleAzureLoginRedirect().catch(ErrorReporter.captureError);
        } else if (!isLoading && user.type === 'staff') {
            onLoginSuccess();
        }
    }, [instance, isLoading, navigate, onLoginSuccess, user.type]);

    return <PageLoader id="log-in" />;
});

export default StaffLoginPage;
