import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, MenuList } from '@mui/material';
import { HirePeriodsRatesMenuItems } from './HirePeriods&RatesMenuItems';

export const HirePeriodsRatesMenuAccordion = () => (
    <Accordion square={true} disableGutters={true} elevation={0} sx={{ '&:before': { display: 'none' } }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="hire-periods-rates-panel" id="hire-periods-rates-header">
            Hire periods & Rates
        </AccordionSummary>
        <AccordionDetails sx={{ py: 0 }} id="hire-periods-rates-panel">
            <MenuList component="div" sx={{ py: 0 }} disablePadding={true}>
                <HirePeriodsRatesMenuItems />
            </MenuList>
        </AccordionDetails>
    </Accordion>
);
