import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useAuth } from '#/hooks';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Box, IconButton, Menu, Tooltip } from '@mui/material';
import { useAccountInfo } from '../../../hooks/useAccountInfo';
import ReservationStore from '../../../store/ReservationStore';
import { AccountSummary } from './AccountSummary';
import { ExitLink } from './ExitLink';

const initialise = (input?: string): string => {
    const split = input ? input.split(' ') : [];
    return split.length ? `${split[0]?.[0]} ${split[0]?.[1]}`.trim() : input || '';
};

export const MobileUserMenu: React.FC = observer(() => {
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const { user } = useAuth();
    const { accountInfo } = useAccountInfo(user.type === 'agent' ? user.accountKey : undefined);
    return (
        <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
            <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    {accountInfo?.branch || ReservationStore.hirerFullName ? <Avatar>{initialise(accountInfo?.branch || ReservationStore.hirerFullName)}</Avatar> : <MenuIcon />}
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="user-menu"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <AccountSummary sx={{ display: { xs: 'block', sm: 'none' }, p: 1 }} />
                <ExitLink />
            </Menu>
        </Box>
    );
});
