import { observer } from 'mobx-react-lite';
import React, { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useMediaQuery, useTheme } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';
import { useActiveRoute } from '../../hooks/useActiveRoute';
import { useRouteNavigate } from '../../hooks/useNavigateRoute';
import AvailabilityStore from '../../store/AvailabilityStore';
import ReservationStore from '../../store/ReservationStore';
import DelayedSpinner from '../LoadingAnimation/DelayedSpinner';
import { JucyMuiTab, JucyMuiTabPanel, JucyMuiTabs } from '../Tabs';
import AgentHirerDetailsTab from './Tabs/Agents/AgentHirerDetailsTab';

const AgentSelectVehicleTab = lazy(() => import('./Tabs/Agents/AgentSelectVehicleTab'));
const AgentTripSearchTab = lazy(() => import('./Tabs/Agents/AgentTripSearchTab'));
const SelectExcessTab = lazy(() => import('./Tabs/Common/SelectExcessTab'));
const SelectExtrasTab = lazy(() => import('./Tabs/Common/SelectExtrasTab'));

export const RentalsReservationTabNames = {
    tripInfo: 'trip-info',
    fleetCategory: 'fleet-category',
    excessReduction: 'excess-reduction',
    extras: 'extras',
    hirerDetails: 'hirer-details',
};
const findTabName = (query) => {
    if (query) {
        if (RentalsReservationTabNames[query]) {
            return RentalsReservationTabNames[query];
        } else if (Object.values(RentalsReservationTabNames).includes(query)) {
            return query;
        }
    }
    return RentalsReservationTabNames.tripInfo;
};

const useActiveTabs = () => {
    const tabs = [];
    tabs.push({
        id: RentalsReservationTabNames.tripInfo,
        label: 'Pick up & Return',
        component: AgentTripSearchTab,
        onSelectRouteDetails: {
            routeId: 'create-quote',
            params: {
                activeTab: RentalsReservationTabNames.fleetCategory,
            },
        },
        enabled: Boolean(true),
    });
    if ((AvailabilityStore.state !== 'pending' && AvailabilityStore.availableCount > 0) || ReservationStore.activeQuote) {
        tabs.push({
            id: RentalsReservationTabNames.fleetCategory,
            label: 'Vehicle',
            component: AgentSelectVehicleTab,
            onSelectRouteDetails: {
                routeId: 'edit-quote',
                params: {
                    activeTab: RentalsReservationTabNames.excessReduction,
                },
            },
            enabled: Boolean(true),
        });
        tabs.push({
            id: RentalsReservationTabNames.excessReduction,
            label: 'Excess Reduction',
            component: SelectExcessTab,
            onSelectRouteDetails: {
                routeId: 'edit-quote',
                params: {
                    activeTab: RentalsReservationTabNames.extras,
                },
            },
            enabled: Boolean(ReservationStore.activeQuote && ReservationStore.activeQuote.InsuranceProducts[0].Items.length > 0),
        });

        tabs.push({
            id: RentalsReservationTabNames.extras,
            label: 'Extras',
            component: SelectExtrasTab,
            onSelectRouteDetails: {
                routeId: 'edit-quote',
                params: {
                    activeTab: RentalsReservationTabNames.hirerDetails,
                },
            },
            enabled: Boolean(ReservationStore.activeQuote && ReservationStore.activeQuote.InsuranceLine),
        });

        tabs.push({
            id: RentalsReservationTabNames.hirerDetails,
            label: 'Hirer details',
            component: AgentHirerDetailsTab,
            enabled: Boolean(ReservationStore.activeQuote && ReservationStore.activeQuote.InsuranceLine),
        });
    }
    return tabs;
};

const AgentRentalsReservationTabSet = observer(({ activeTab: initialActiveTab }) => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const [activeTab, setActiveTab] = useState(() => findTabName(initialActiveTab));
    const params = useParams();
    const activeRoute = useActiveRoute();
    const navigate = useRouteNavigate();
    useEffect(() => {
        setActiveTab(findTabName(params.activeTab));
    }, [params.activeTab]);

    const toggleTab = useCallback(
        (e, tab) => {
            if (activeRoute?.path.includes(':activeTab') && activeTab !== tab) {
                navigate(activeRoute.id, { params: { ...params, activeTab: tab }, search: window.location.search });
            } else if (activeTab !== tab) {
                setActiveTab(tab);
            }
        },
        [activeRoute?.path, activeRoute.id, activeTab, navigate, params]
    );
    const tabs = useActiveTabs();
    useEffect(() => {
        if (!tabs.filter((t) => t.enabled).some((s) => s.id === activeTab)) {
            toggleTab(null, tabs[0].id);
        }
    }, [activeTab, tabs, toggleTab]);

    return (
        <>
            <JucyMuiTabs
                value={tabs.filter((t) => t.enabled).some((t) => t.id === activeTab) ? activeTab : tabs[0].id}
                onChange={toggleTab}
                variant="scrollable"
                scrollButtons={isXs ? true : 'auto'}
                allowScrollButtonsMobile={true}
                sx={{
                    [`& .${tabsClasses.flexContainer}`]: {
                        width: 0,
                    },
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                }}
            >
                {tabs.map((t) => (
                    <JucyMuiTab label={t.label} a11yName="rentals" data-cy={`tab-${t.id}`} key={`tab-item-${t.id}`} value={t.id} disabled={!t.enabled} />
                ))}
            </JucyMuiTabs>
            <Suspense fallback={<DelayedSpinner center={true} />}>
                {tabs
                    .filter((t) => t.enabled)
                    .map((t) => (
                        <JucyMuiTabPanel key={`tab-pane-${t.id}`} value={t.id} activeValue={activeTab} a11yName="rentals" className="animate__animated animate__fadeIn">
                            {t.onSelectRouteDetails ? <t.component onSelectRouteDetails={{ ...t.onSelectRouteDetails }} /> : <t.component />}
                        </JucyMuiTabPanel>
                    ))}
            </Suspense>
        </>
    );
});
AgentRentalsReservationTabSet.displayName = 'AgentRentalsReservationTabSet';
export default AgentRentalsReservationTabSet;
