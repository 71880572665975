import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { BookingCart } from '../../../types/BookingCart';
import { TabDetail } from '../lib';
import {
    TripHirerDetailsTab,
    TripHirerDetailsTabProps,
    TripPaymentTab,
    TripResultsTab,
    TripResultsTabProps,
    TripSearchTab,
    TripSearchTabProps,
    TripSelectExcessTab,
    TripSelectExcessTabProps,
    TripSelectExtrasTab,
    TripSelectExtrasTabProps,
} from './Tabs';

export interface TripTabPanelProps {
    active: boolean;
    tab: TabDetail;
    summary?: BookingCart;
    onSearchSubmit: TripSearchTabProps['onSubmit'];
    onFleetCategorySelected: TripResultsTabProps['onSelect'];
    onExcessSelected: TripSelectExcessTabProps['onSelect'];
    onExtrasSelected: TripSelectExtrasTabProps['onSelect'];
    onHirerDetailsSubmit: TripHirerDetailsTabProps['onSubmit'];
}

export const TripTabPanel: React.FC<TripTabPanelProps> = ({ tab, active, summary, onSearchSubmit, onFleetCategorySelected, onExcessSelected, onExtrasSelected, onHirerDetailsSubmit }) => {
    const panelProps = useMemo(
        () => ({
            role: 'tabpanel',
            hidden: !active,
            id: `trip-tabpanel-${tab.slug}`,
            'aria-labelledby': `trip-tab-${tab.slug}`,
        }),
        [active, tab.slug]
    );
    if (panelProps.hidden) {
        return null;
    }
    if (tab.name === 'search') {
        return (
            <Box sx={{ mt: 2 }} {...panelProps}>
                <TripSearchTab summary={summary} onSubmit={onSearchSubmit} />
            </Box>
        );
    }
    if (tab.name === 'search-results') {
        return (
            <Box sx={{ mt: 2 }} {...panelProps}>
                <TripSearchTab showResults={true} summary={summary} onSubmit={onSearchSubmit} onFleetCategorySelected={onFleetCategorySelected} />
            </Box>
        );
    }
    if (tab.name === 'results') {
        return (
            <div {...panelProps}>
                <TripResultsTab summary={summary} onSelect={onFleetCategorySelected} />
            </div>
        );
    }

    if (tab.name === 'excess-reduction') {
        return (
            <div {...panelProps}>
                <TripSelectExcessTab summary={summary} onSelect={onExcessSelected} />
            </div>
        );
    }

    if (tab.name === 'extras') {
        return (
            <div {...panelProps}>
                <TripSelectExtrasTab summary={summary} onSelect={onExtrasSelected} />
            </div>
        );
    }
    if (tab.name === 'driver') {
        return (
            <div {...panelProps}>
                <TripHirerDetailsTab summary={summary} onSubmit={onHirerDetailsSubmit} />
            </div>
        );
    }

    if (tab.name === 'payment') {
        return (
            <div {...panelProps}>
                <TripPaymentTab summary={summary} />
            </div>
        );
    }

    return null;
};
