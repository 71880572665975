import { useBrand } from '#/store/BrandStore';
import { datadogRum } from '@datadog/browser-rum';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import ErrorReporter from '#/lib/ErrorReporter';
import ReservationStore from '#/store/ReservationStore';
import { BookingOptions } from '@jucy/rentals-api-client/rentals-api-v3';
import { JucyCountryCode } from '@jucy/rentals-common';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Field, Formik, Form as FormikForm, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { FormikHelpers } from 'formik/dist/types';
import { runInAction, toJS } from 'mobx';
import * as Yup from 'yup';
import { useRentalsApi, useTripApi, useUpdateRouteParams } from '../../hooks';
import { subscribeToList } from '../../lib/getJucyRentalsApiClient';
import { resolveError } from '../../lib/resolveError';
import AppStateStore from '../../store/AppStateStore';
import hireDetailsStore from '../../store/HireDetailsStore';
import reservationStore from '../../store/ReservationStore';
import { summaryFromBooking } from '../../store/Summary/summaryFromBooking';
import translationStore from '../../store/TranslationStore';
import tripStore from '../../store/TripStore';
import { BookingCart } from '../../types/BookingCart';
import { JucyErrorAlert } from '../Alert/JucyErrorAlert';
import { JucyButton } from '../Button/Button';
import ModalDialog from '../ModalDialog';
import { PageTitle } from '../Typograpgy/Title';
import Checkbox from '../form/fields/Checkbox';
import PhoneNumberInput from '../form/fields/PhoneNumberInput';

const emailQuoteValidationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required('Required'),
    lastName: Yup.string().trim().required('Required'),
    email: Yup.string().email().trim().required('Required'),
    mobileNumber: Yup.string().trim(),
    termsAndConditions: Yup.bool()
        .test('termsAndConditions', 'Please accept our terms and conditions', (value) => value)
        .required('Please accept our terms and conditions'),
    mailingList: Yup.boolean().optional(),
});
export type EmailQuoteFromValues = Yup.InferType<typeof emailQuoteValidationSchema>;

const SyncHirerWithTripStore: React.FC = () => {
    const context = useFormikContext<EmailQuoteFromValues>();
    useEffect(() => hireDetailsStore.updateContext(context), [context]);
    useEffect(() => {
        hireDetailsStore.updateFromForm(context.values);
    }, [context]);
    return null;
};

interface EmailQuoteFormProps {
    summary?: BookingCart;
    initialValues: EmailQuoteFromValues;
    onSubmit: (values: EmailQuoteFromValues, form: FormikHelpers<EmailQuoteFromValues>) => unknown | Promise<unknown>;
}

const EmailQuoteForm: React.FC<EmailQuoteFormProps> = observer(({ initialValues, summary, onSubmit }) => {
    const hasPhoneNumber = Boolean(initialValues.mobileNumber);
    const { t } = useTranslation(translationStore.currentNamespace);
    const brandStore = useBrand();
    const rentalsApiClient = useRentalsApi();
    const updateRoute = useUpdateRouteParams();
    const params = useParams();
    const tripEnabled = useTripApi();
    const submitHandler = useCallback(
        async (values: EmailQuoteFromValues, formikHelpers: FormikHelpers<EmailQuoteFromValues>) => {
            try {
                runInAction(() => {
                    if (summary) {
                        summary.hirerDetails.firstName = values.firstName?.trim();
                        summary.hirerDetails.lastName = values.lastName?.trim();
                        summary.hirerDetails.mobileNumber = values.mobileNumber?.trim() || '';
                        summary.hirerDetails.email = values.email?.trim();
                        summary.hirerDetails.mailingList = values.mailingList;
                        summary.hirerDetails.acceptedTerms = values.termsAndConditions;
                        if (summary.amendments) {
                            summary.amendments.hirerDetails = toJS(summary.hirerDetails);
                        }
                    }
                });
                datadogRum.setUser({
                    email: values.email?.trim().toLowerCase() || '',
                });
                const createRequest = summary?.amendments?.asEmailQuoteRequest?.() || summary?.asEmailQuoteRequest();
                if (summary && createRequest) {
                    const [response] = await Promise.all([
                        rentalsApiClient.v3.quotes.emailQuote(createRequest),
                        values.mailingList &&
                            subscribeToList({
                                list: summary.pickUpLocation?.CountryCode as JucyCountryCode,
                                email: values.email,
                                firstName: values.firstName,
                                lastName: values.lastName,
                                tags: ['obe', 'email-quote'],
                            }).catch((e) => {
                                ErrorReporter.captureError(e);
                            }),
                    ]);
                    toast.success(`Quote emailed. Reference #${response.booking?.pickUpLocation}-${response?.bookingNo}`);
                    onSubmit?.(values, formikHelpers);

                    if (!tripEnabled) {
                        ReservationStore.setActiveReservationReference(response.booking.reservationReference, {
                            resume: true,
                            country: ReservationStore.activeQuote?.PickUpSite?.CountryCode,
                            useCache: false,
                        }).catch(ErrorReporter.captureError);
                        return;
                    }
                    if (summary) {
                        const updatedSummary = summaryFromBooking({
                            booking: response.booking,
                            options: (tripStore.options || {
                                secondaryProducts: summary.secondaryProducts,
                                insuranceProducts: summary.insuranceProducts,
                                bundles: summary.bundles,
                            }) as BookingOptions,
                            ...summary,
                            convertUrl: response.convertUrl,
                            reservationId: response.bookingNo,
                            reservationReference: response.reference,
                            bookingStatus: response.status as BookingCart['bookingStatus'],
                        });
                        Object.assign(summary, updatedSummary);
                        if (summary.amendments) {
                            summary.amendments = toJS(summary);
                        }
                    }
                    if (summary.reservationReference) {
                        updateRoute(
                            {
                                ...params,
                                action: 'edit',
                                fleetTypeOrResRef: summary.reservationReference,
                            },
                            {
                                keepSearch: true,
                            }
                        );
                    }
                }
            } catch (e) {
                const error = await resolveError(e);
                if (error.message.toLowerCase() === 'dnr error') {
                    AppStateStore.showDNRModal = true;
                    AppStateStore.showEmailQuoteModal = false;
                } else {
                    formikHelpers.setStatus({ error: error.message });
                }
            }
        },
        [onSubmit, params, rentalsApiClient.v3.quotes, summary, tripEnabled, updateRoute]
    );

    return (
        <Formik initialValues={initialValues} validationSchema={emailQuoteValidationSchema} onSubmit={submitHandler}>
            {(form) => (
                <FormikForm className="rentals-hirer-details-form">
                    <SyncHirerWithTripStore />
                    <DialogContent sx={{ paddingTop: 0 }}>
                        <p className="smaller">{t('email_quote.message')}</p>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Field
                                    component={TextField}
                                    fullWidth
                                    size="small"
                                    label={t('hirer_details.group.first_name')}
                                    type="text"
                                    name="firstName"
                                    disabled={form.isSubmitting}
                                    data-cy="first-name"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field
                                    component={TextField}
                                    fullWidth
                                    size="small"
                                    label={t('hirer_details.group.last_name')}
                                    type="text"
                                    name="lastName"
                                    disabled={form.isSubmitting}
                                    data-cy="last-name"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field
                                    component={TextField}
                                    fullWidth
                                    size="small"
                                    label={t('hirer_details.group.email')}
                                    type="email"
                                    name="email"
                                    disabled={form.isSubmitting}
                                    data-cy="email"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PhoneNumberInput
                                    label="Mobile Number"
                                    name="mobileNumber"
                                    phoneInputProps={{
                                        country: hasPhoneNumber ? undefined : summary?.pickUpLocation?.CountryCode || 'nz',
                                    }}
                                    placeholder="+64 21123456"
                                    disabled={form.isSubmitting}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Checkbox
                                    type="checkbox"
                                    data-cy="accept-terms"
                                    disabled={form.isSubmitting}
                                    id="termsAndConditions"
                                    name="termsAndConditions"
                                    label={
                                        <>
                                            {t('email_quote.group.terms_and_conditions_1')}{' '}
                                            <a
                                                title={t('email_quote.group.terms_and_conditions_2') || ''}
                                                href={brandStore.termsPageUrl({ countryCode: summary?.pickUpLocation?.CountryCode })}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {t('email_quote.group.terms_and_conditions_2')}{' '}
                                            </a>
                                        </>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Checkbox id="mailingList" type="checkbox" name="mailingList" disabled={form.isSubmitting} data-cy="subscribe" label={t('newsletter.signup')} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ alignItems: 'flex-start' }}>
                        {form.status?.error ? (
                            <JucyErrorAlert title={t('email_quote.email_error')} message={form.status.error} className="animate__animated animate__fadeIn" sx={{ mr: 2, width: '100%' }} />
                        ) : null}

                        <JucyButton variant="contained" showLoader={form.isSubmitting} disabled={form.isSubmitting} type="submit" data-cy="email-quote-submit">
                            {form.status?.error ? t('email_quote.try_again') : t('email_quote.send_email')}
                        </JucyButton>
                    </DialogActions>
                </FormikForm>
            )}
        </Formik>
    );
});
EmailQuoteForm.displayName = 'EmailQuoteForm';

const EmailQuoteContent = observer(() => {
    const summary = reservationStore.activeQuote ? reservationStore.summary : tripStore.summary;
    const [initialValues] = useState<EmailQuoteFromValues>(() => ({
        firstName: hireDetailsStore.isDefaultHirerName() ? '' : hireDetailsStore.firstName || '',
        lastName: hireDetailsStore.isDefaultHirerName() ? '' : hireDetailsStore.lastName || '',
        email: hireDetailsStore.isDefaultHirerEmail() ? '' : hireDetailsStore.email || '',
        mobileNumber: hireDetailsStore.mobileNumber || '',
        termsAndConditions: Boolean(hireDetailsStore.acceptedTerms),
        mailingList: Boolean(hireDetailsStore.mailingList),
    }));
    return <EmailQuoteForm onSubmit={AppStateStore.toggleEmailQuoteModal} summary={summary} initialValues={initialValues} />;
});
EmailQuoteContent.displayName = 'EmailQuoteContent';

const EmailQuoteModal = observer(() => (
    <ModalDialog open={AppStateStore.showEmailQuoteModal} onClose={AppStateStore.toggleEmailQuoteModal} data-cy="email-quote-modal" maxWidth="sm">
        <DialogTitle component="div">
            <PageTitle>Email quote</PageTitle>
        </DialogTitle>
        {AppStateStore.showEmailQuoteModal ? <EmailQuoteContent /> : null}
    </ModalDialog>
));
EmailQuoteModal.displayName = 'EmailQuoteModal';

export default EmailQuoteModal;
