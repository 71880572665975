import React from 'react';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import { Alert, AlertTitle, useTheme } from '@mui/material';
import config from '../config';

const TestEnvRibbon: React.FC = () => {
    const isTest = config.env === 'test';
    const theme = useTheme();

    if (!isTest) {
        return null;
    }

    return (
        <>
            {isTest && (
                <Alert
                    severity="success"
                    sx={{
                        zIndex: 99999,
                        borderRadius: 0,
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: theme.palette.secondary.main,
                        color: 'white',
                        height: `${theme.settings?.appBar.testBannerHeight}px`,
                    }}
                    icon={<BorderColorSharpIcon sx={{ fill: 'white', marginBottom: 1 }} />}
                >
                    <AlertTitle sx={(t) => ({ fontSize: '0.75rem', fontWeight: 700, marginBottom: 0, fontFamily: t.typography.fontFamily })}>
                        You are currently viewing the test environment
                    </AlertTitle>
                </Alert>
            )}
        </>
    );
};

export default TestEnvRibbon;
