import { useCallback } from 'react';
import { SearchFormContainerProps } from '../../../components/Forms/TripSearchForm/SearchFormContainerProps';
import { useUpdateRouteParams } from '../../../hooks';
import fleetTypesStore from '../../../store/FleetTypesStore';
import { useTripRouteParams } from './useTripRouteParams';

export const useHandleFleetTypeChanged = (): Exclude<SearchFormContainerProps['onFleetTypeChanged'], undefined> => {
    const routeParams = useTripRouteParams();
    const updateRouteParams = useUpdateRouteParams();
    return useCallback<Exclude<SearchFormContainerProps['onFleetTypeChanged'], undefined>>(
        (fleetType) => {
            if (fleetType?.slug) {
                fleetTypesStore.setActiveFleetTypeSlug(fleetType.slug);
                if (routeParams.fleetTypeOrResRef !== fleetType.slug) {
                    updateRouteParams(
                        {
                            ...routeParams,
                            fleetTypeOrResRef: fleetType.slug,
                        },
                        { keepSearch: true }
                    );
                }
            }
        },
        [routeParams, updateRouteParams]
    );
};
