import CurrencyFormatter, { formatCurrency } from '../../../CurrencyFormatter/CurrencyFormatter';
import config from '../../../../config';
import paymentStore from '../../../../store/PaymentStore';
import { BookingCart } from '../../../../types/BookingCart';
import { Box, Button, Card, CardContent, Container, Typography } from '@mui/material';
import React from 'react';
import { getPaymentCallbackUrl } from '../../../../lib/payments';

export const MockPaymentForm: React.FC<{ summary: BookingCart }> = ({ summary }) => {
    const paymentOption = paymentStore.getActivePaymentOption(summary);
    const confirmUrl = summary.convertUrl?.startsWith('http') ? new URL(summary.convertUrl) : new URL(summary.convertUrl || '', config.apiBaseUrl);
    if (!confirmUrl.searchParams.has('returnUrl')) {
        confirmUrl.searchParams.set('returnUrl', getPaymentCallbackUrl());
    }

    return (
        <Container maxWidth="sm">
            <Card variant="outlined" sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="subtitle1" component="h2" sx={{ fontWeight: 'normal', mb: 1 }}>
                        Payment checkout
                    </Typography>
                    <Box px={1}>
                        <Typography variant="subtitle1" gutterBottom={true}>
                            Booking #{summary.pickUpLocation?.SiteCode}-{summary.reservationId}
                            {paymentOption?.id === 'deposit' ? ' deposit' : null}
                            {summary?.isOnRequest ? ' request' : null}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom={false}>
                            {summary.isOnRequest ? <CurrencyFormatter price={{ ...summary?.totalPrice, value: 1 }} prefixCurrencyCode={true} /> : paymentOption?.amount}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
            <Box mb={2}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="subtitle1" component="h2" sx={{ fontWeight: 'normal', mb: 1 }}>
                            Payment details
                        </Typography>
                        <Box sx={{ px: 1 }}>
                            <dl>
                                <dt>token:</dt>
                                <dd>{summary.paymentToken}</dd>

                                <dt>countryCode:</dt>
                                <dd>{summary.pickUpLocation?.CountryCode}</dd>

                                <dt>confirmUrl:</dt>
                                <dd>{confirmUrl.href}</dd>

                                <dt>amount:</dt>
                                <dd>{formatCurrency(paymentOption?.total || 0)}</dd>
                            </dl>
                            <br />
                            <Button data-cy="confirm-payment" variant="contained" href={confirmUrl.href}>
                                Confirm
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    );
};
