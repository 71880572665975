import { LoaderDots } from '@jucy-ui/components';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import FatalError from '#/components/Alert/FatalError';
import { TableHeadCell } from '#/components/Tables/ReservationsTable/TableHeadCell';
import SearchBar from '#/components/Tables/SearchBar';
import { useAuth } from '#/hooks';
import { asError } from '#/lib/asError';
import accountKeyStore from '#/store/AccountKeyStore';
import { Box, CircularProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { ReservationsTableRow } from './ReservationsTableRow';
import { headCells } from './headCells';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import getJucyRentalsApiClient from '#/lib/getJucyRentalsApiClient';

export const ReservationsTable: React.FC = observer(() => {
    const { user } = useAuth();
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('created');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
    const [accountKey, setAccountKey] = useState(() => accountKeyStore.accountKey);

    const { isLoading, isError, error, data, isFetching } = useQuery({
        queryKey: ['res-summaries', accountKey, accountKeyStore.user?.email, page, itemsPerPage, searchTerm, sortBy, sortDirection],
        queryFn: () =>
            getJucyRentalsApiClient({ user, accountKey }).searchReservations({
                query: searchTerm,
                page: page + 1,
                itemsPerPage,
                sort: sortBy && sortDirection ? `${sortBy}:${sortDirection}` : undefined,
            }),
        placeholderData: keepPreviousData,
    });

    const rows = data?.items || [];

    useEffect(() => {
        autorun(() => {
            setAccountKey(accountKeyStore.accountKey);
        });
    }, []);

    const handleChangePage = useCallback((_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage <= 0 ? 0 : newPage);
    }, []);

    const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setItemsPerPage(Number(event.target.value));
        setPage(0);
    }, []);
    const handleSearchChanged = useCallback((value: string) => {
        setSearchTerm(value);
        setPage(0);
    }, []);
    const handleSortChanged = useCallback(
        (value: string) => {
            const isAsc = sortBy === value && sortDirection === 'asc';
            setSortDirection(isAsc ? 'desc' : 'asc');
            setSortBy(value);
        },
        [sortBy, sortDirection]
    );

    if (isError) {
        return (
            <FatalError title="Reservations Unavailable" message="Sorry we were unable to load your reservations at this time">
                <pre>{asError(error).message}</pre>
            </FatalError>
        );
    }

    return (
        <Box pt={2} width={'100%'}>
            <SearchBar onChange={handleSearchChanged} />
            <TableContainer component={Paper} sx={{ '& p': { marginBottom: 0 } }}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell variant="head" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }} align="center">
                                <Stack alignItems="center" justifyContent="center">
                                    {!isLoading && isFetching ? <CircularProgress size={20} /> : null}
                                </Stack>
                            </TableCell>
                            {headCells.map((headCell) => (
                                <TableHeadCell key={headCell.id} onClick={() => handleSortChanged(headCell.id)} cell={headCell} sortDirection={sortDirection} sortBy={sortBy} />
                            ))}
                            <TableCell variant="head" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <ReservationsTableRow key={row.id} row={row} />
                        ))}
                        {(data?.items || []).length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={8}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {isLoading ? <LoaderDots color="secondary" /> : <>No reservations found</>}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : null}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100, 250]}
                                colSpan={8}
                                count={data?.itemCount || 0}
                                rowsPerPage={itemsPerPage}
                                page={data?.itemCount ? page : 0}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    );
});
