export interface HeadCell {
    id: string;
    label: string;
    sortable?: boolean;
}

export const headCells: readonly HeadCell[] = [
    {
        id: 'hirer.firstName',
        label: 'Hirer name',
        sortable: true,
    },
    {
        id: 'bookingNo',
        label: 'Booking No.',
        sortable: true,
    },
    {
        id: 'bookingRef',
        label: 'Booking Ref.',
        sortable: true,
    },
    {
        id: 'voucherRef',
        label: 'Voucher Ref.',
        sortable: true,
    },
    {
        id: 'created',
        label: 'Creation date',
        sortable: true,
    },
    {
        id: 'status',
        label: 'Status',
        sortable: false,
    },
];
