import React from 'react';
import { styled } from '@mui/material';
import Image, { ImageProps } from 'next/image';

export interface ImgProps extends ImageProps {
    alt: string;
    rounded?: boolean;
    fluid?: boolean;
}

const ImgEl: React.FC<ImgProps> = ({ alt, rounded, fluid, loading, ...props }) => <Image {...props} alt={alt} loading={loading || 'lazy'} {...props} />;

export const Img = styled(ImgEl)(({ theme, rounded = true, fluid = true }) => ({
    borderRadius: rounded ? theme.shape.borderRadius : undefined,
    ...(fluid
        ? {
              maxWidth: '100%',
              height: 'auto',
          }
        : undefined),
}));
