import React, { useMemo } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { FleetCategoryMinimumHirePeriods, HirePeriodsStore, PivotedMinimumHirePeriod } from './HirePeriodsStore';

const getColDef = (minimumHirePeriods?: FleetCategoryMinimumHirePeriods): GridColDef<PivotedMinimumHirePeriod>[] => {
    if (minimumHirePeriods?.minimumHirePeriods.length) {
        const result: GridColDef<PivotedMinimumHirePeriod>[] = [
            {
                field: 'locationCodes',
                headerName: '',
                width: 90,
                disableColumnMenu: true,
                renderCell: (params) => <strong>{params.value}</strong>,
            },
        ];
        for (const range of minimumHirePeriods.dateRanges.sort((a, b) => a.pickUpStartDate.getTime() - b.pickUpStartDate.getTime())) {
            const start = format(range.pickUpStartDate, 'dd MMM yyyy');
            const end = format(range.pickUpEndDate, 'dd MMM yyyy');
            result.push({
                field: HirePeriodsStore.formatRangeKey(range),
                renderHeader: () => (
                    <>
                        {start}-<br />
                        {end}
                    </>
                ),
                width: 110,
                align: 'center',
                disableColumnMenu: true,
            });
        }
        return result;
    }
    return [];
};

export interface FleetCategoryMinHireTableProps {
    error?: Error | null;
    minimumHirePeriods?: FleetCategoryMinimumHirePeriods;
    isLoading?: boolean;
}

const FleetCategoryMinHireTable: React.FC<FleetCategoryMinHireTableProps> = ({ minimumHirePeriods }) => {
    const columns = useMemo(() => getColDef(minimumHirePeriods), [minimumHirePeriods]);
    return (
        <Paper sx={{ mb: 3 }}>
            <Typography variant="h6" textAlign="center" mt={2} mb={0}>
                {minimumHirePeriods?.name} ({minimumHirePeriods?.code})
            </Typography>
            <Box sx={{ height: 415 }}>
                <DataGridPro
                    sx={{ '& .MuiDataGrid-columnHeaderTitleContainerContent': { lineHeight: 1.3, fontWeight: 'bold' } }}
                    density="compact"
                    columns={columns}
                    rows={minimumHirePeriods?.minimumHirePeriods || []}
                    getRowId={(row) => row.locationCodes}
                    hideFooter={true}
                    initialState={{ pinnedColumns: { left: ['locationCodes'] } }}
                />
            </Box>
        </Paper>
    );
};
export default FleetCategoryMinHireTable;
