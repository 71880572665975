import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { parsePaymentDataMessage } from '../../../../lib/payments';
import LoadingAnimation from '../../../LoadingAnimation/LoadingAnimation';

interface PaymentFieldProps extends React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> {
    onComplete?: (value: { success: boolean; status: string; reservationReference?: string; country?: string; lastName?: string }) => void;
}

const PaymentField: React.FC<PaymentFieldProps> = ({ onComplete, ...props }) => {
    const [state, setState] = useState(() => ({
        loading: true,
        complete: false,
        success: false,
        status: '',
    }));
    useEffect(() => {
        const handler = (event: MessageEvent) => {
            const data = parsePaymentDataMessage(event);
            if (data) {
                if (data?.event === 'loaded') {
                    setState({
                        ...state,
                        loading: false,
                    });
                } else if (data?.event === 'complete') {
                    setState({
                        ...state,
                        loading: false,
                        complete: true,
                        success: Boolean(data.success),
                        status: data.status || '',
                    });
                    if (onComplete) {
                        onComplete({
                            success: Boolean(data.success),
                            status: data.status || '',
                            reservationReference: (data.reservationReference as string) || '',
                            country: (data.country as string) || '',
                            lastName: (data.lastName as string) || '',
                        });
                    }
                }
            }
        };
        window.addEventListener('message', handler);
        return () => window.removeEventListener('message', handler);
    }, [onComplete, state]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (state.loading) {
                setState({
                    ...state,
                    loading: false,
                });
            }
        }, 10000);
        return () => clearTimeout(timer);
    });
    const onLoadHandler = useCallback(() => {
        setState({
            ...state,
            loading: false,
        });
    }, [state]);
    return (
        <div className={clsx('payment-field', { loading: state.loading })}>
            {state.complete ? (
                <LoadingAnimation variant="primary" className="animate__animated animate__fadeIn" />
            ) : (
                <>
                    <LoadingAnimation variant="primary" className="animate__animated animate__fadeIn loader" />
                    <iframe title="Payment" onLoad={onLoadHandler} className="payment-frame" {...props} />
                </>
            )}
        </div>
    );
};

export default PaymentField;
