import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { JucyButton } from '../../components/Button/Button';
import { useRouteNavigate } from '../../hooks/useNavigateRoute';
import AvailabilityStore from '../../store/AvailabilityStore';
import ReservationStore from '../../store/ReservationStore';
import QuotePage from './QuotePage';

const Content = ({ activeQuote }) => {
    const isOnRequest = activeQuote.FleetCategory.Availability === 'OnRequest';
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const navigate = useRouteNavigate();
    return (
        <>
            <h1 className="h2 pt-0 pb-2 pt-lg-3 pb-lg-5 mb-0" data-cy="page-title">
                Cancel {isOnRequest ? 'request' : 'booking'}
            </h1>
            <h5>Reference number: {activeQuote.ReservationDisplayReference}</h5>
            {message ? (
                <div className="animate__animated animate__fadeIn">
                    <p>{message}</p>
                    <JucyButton
                        variant="contained"
                        onClick={() => {
                            AvailabilityStore.reset();
                            ReservationStore.reset();
                            navigate('quotes');
                        }}
                    >
                        Back to to Manage Bookings
                    </JucyButton>
                </div>
            ) : (
                <div className="animate__animated animate__fadeIn">
                    <p>Are you sure?</p>
                    <JucyButton color="warning" disabled={isLoading} onClick={() => navigate(-1)} variant="contained">
                        No, back to booking
                    </JucyButton>
                    &nbsp;
                    <JucyButton
                        variant="contained"
                        color="success"
                        showLoader={isLoading}
                        disabled={isLoading}
                        onClick={(e) => {
                            e.preventDefault();
                            setIsLoading(true);
                            ReservationStore.cancelReservation()
                                .then((response) => {
                                    if (response && response.ResponseType === 'Success') {
                                        setMessage(response.Message || response.Data);
                                        ReservationStore.reset();
                                    } else {
                                        toast.error(response.Message);
                                    }
                                    setIsLoading(false);
                                })
                                .catch((e) => {
                                    toast.error((e.response && e.response.body && e.response.body.Message) || e.message);
                                    console.error(e);
                                });
                        }}
                    >
                        Yes, cancel {isOnRequest ? 'request' : 'booking'}{' '}
                    </JucyButton>
                </div>
            )}
        </>
    );
};

const CancelQuotePage = (props) => <QuotePage child={Content} {...props} />;

export default CancelQuotePage;
