import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import AccountKeyStore from '#/store/AccountKeyStore';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useAccountInfo } from '../../../../hooks';
import useGetMenuItems from '../../../../hooks/useGetMenuItems';
import { RegistryRoute } from '../../../../store/RouteRegistry';
import { CMSPagesAccordion } from './CMSPagesAccordion';
import { HirePeriodsRatesMenuAccordion } from './HirePeriodsRatesMenuAccordion';
import { useMainNav } from './useMainNav';

export const MobileNavigation: React.FC<{ routes: RegistryRoute[] }> = observer(({ routes }) => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'mobile-main-nav' });
    const { accountInfo } = useAccountInfo(AccountKeyStore.accountKey);
    const rateType = accountInfo?.settings?.au?.rateType;
    const { items: menuItems } = useGetMenuItems({ path: `/partner/${rateType}`, enabled: Boolean(rateType) });
    const { enabled, currentPage } = useMainNav();

    return enabled ? (
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton {...bindTrigger(popupState)} size="large" color="inherit">
                <MenuIcon />
            </IconButton>
            <Menu
                {...bindMenu(popupState)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                }}
            >
                {routes.map((route) => (
                    <MenuItem component="div" selected={currentPage === route.path} key={route.id} onClick={popupState.close}>
                        <Link to={route.path}>{route.label}</Link>
                    </MenuItem>
                ))}
                <HirePeriodsRatesMenuAccordion />
                <CMSPagesAccordion items={menuItems || []} />
            </Menu>
        </Box>
    ) : null;
});
