import 'reflect-metadata';
import 'react-dates/initialize';
import { JucyToastContainer } from '@jucy-ui/components';
import { observer } from 'mobx-react-lite';
import React, { Suspense } from 'react';
import { JucyErrorAlert } from '#/components/Alert/JucyErrorAlert';
import PageLoader from '#/components/LoadingAnimation/PageLoader';
import { JucyAuthProvider } from '#/contexts/JucyAuthContext';
import { Box, CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import ErrorBoundary from '../../../components/Alert/ErrorBoundary';
import config from '../../../config';
import { QueryClient } from '@tanstack/react-query';
import { QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();
export const ObeProvider: React.FC<{ children: React.ReactNode }> = observer(({ children }) => (
    <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
            <JucyAuthProvider>
                <JucyToastContainer />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CssBaseline />
                    {!config.env ? (
                        <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
                            <JucyErrorAlert title="Configuration error" message="Invalid configuration" className="animate__animated animate__fadeIn" sx={{ mr: 2, width: '100%' }} />
                        </Box>
                    ) : (
                        <Suspense fallback={<PageLoader />}>{children}</Suspense>
                    )}
                </LocalizationProvider>
            </JucyAuthProvider>
        </QueryClientProvider>
    </ErrorBoundary>
));
