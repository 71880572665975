import { observer } from 'mobx-react-lite';
import React from 'react';
import { format } from 'date-fns';
import AvailabilityStore from '../../../../../store/AvailabilityStore';

export const AgentAvailabilityCaption: React.FC = observer(() => (
    <>
        Showing{' '}
        <strong>
            <span data-cy="available-count">{AvailabilityStore.availableCount}</span> {AvailabilityStore.fleetType?.name}
            {AvailabilityStore.availableCount !== 1 ? 's' : ''}
        </strong>{' '}
        for {AvailabilityStore.pickUpLocation?.Name}, {format(AvailabilityStore.pickUpDate as Date, 'dd MMM yyyy')} {format(AvailabilityStore.pickUpTime as Date, 'HH:mm')} to{' '}
        {AvailabilityStore.dropOffLocation?.Name}, {format(AvailabilityStore.dropOffDate as Date, 'dd MMM yyyy')} {format(AvailabilityStore.dropOffTime as Date, 'HH:mm')}
    </>
));
AgentAvailabilityCaption.displayName = 'AgentAvailabilityCaption';
