import RouteRegistry from '../store/RouteRegistry';

const getPaymentResultPath = ({
    success,
    status,
    query,
    reservationReference,
    fleetType,
}: {
    success: boolean;
    status?: string;
    query?: Record<string, string>;
    reservationReference?: string;
    fleetType?: string;
}): string => {
    if (success) {
        return RouteRegistry.getPath(
            'checkin',
            { mode: 'direct' },
            {
                ...(reservationReference && { reservationReference: reservationReference }),
                ...query,
            }
        );
    } else if (status === 'cancelled') {
        return RouteRegistry.getPath(
            'direct-edit-quote',
            {
                ...(reservationReference && { reservationReference: reservationReference }),
                ...(fleetType && { fleetType: fleetType }),
                activeTab: 'driver',
            },
            query
        );
    }
    return RouteRegistry.getPath(
        'direct-payment-failure',
        { mode: 'direct' },
        {
            ...(reservationReference && { reservationReference: reservationReference }),
            ...query,
        }
    );
};

export default getPaymentResultPath;
