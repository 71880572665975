import './lib/init';
import { datadogRum } from '@datadog/browser-rum';
import { useGrowthBook } from '@growthbook/growthbook-react';
import {
    asError,
    branchQueryKeys,
    fleetTypeQueryKeys,
    getFleetTypesWithPreloadedImages,
    getHirePeriods,
    getProductCatalog,
    getSites,
    hirePeriodQueryKeys,
    productCatalogQueryKeys,
} from '@jucy-ui/common';
import { STALE_TIME } from '@jucy-ui/common/lib/ReactQueryUtils';
import { Box } from '@mui/material';
import { useQueries } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import PageLoader from '#/components/LoadingAnimation/PageLoader';
import config from '#/config';
import { useJitsu } from '#/hooks';
import { useAuth } from '#/hooks/useAuth';
import { useIsCallback } from '#/hooks/useIsCallback';
import ErrorReporter from '#/lib/ErrorReporter';
import { UserMode } from '#/types/UserMode';
import { Location } from 'history';
import { autorun, runInAction } from 'mobx';
import { JucyErrorAlert } from '../../components/Alert/JucyErrorAlert';
import appStateStore from '../../store/AppStateStore';
import appState from '../../store/AppStateStore';
import { ObeContainer } from './components/ObeContainer';

const useOnLocationChange = (handleLocationChange: (location: Location) => void) => {
    const location = useLocation();
    useEffect(() => handleLocationChange(location), [location, handleLocationChange]);
};

const useGetModeFromLocation = () => {
    useLocation();
    const mode = window.location.pathname.split('/')[2];
    return mode || undefined;
};

const useInitApp = () => {
    const mode = useGetModeFromLocation();
    const { user, isLoading: isAuthLoading } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const editEnabled = user.type === 'staff' || (user?.type === 'agent' && user?.features?.['edit-reservation']);
    const growthBook = useGrowthBook();
    const isPaymentCallback = useIsCallback();
    runInAction(() => {
        appState.routeMode = (mode as UserMode) || appState.routeMode;
    });
    useEffect(() => {
        const dispose = autorun(() => {
            setIsLoading(appStateStore.state === 'loading' || appStateStore.state === 'pending');
        });
        return () => {
            if (dispose) {
                dispose();
            }
        };
    }, []);

    useEffect(() => {
        if (!isAuthLoading) {
            appStateStore
                .init({
                    isAuthCallback: window.location.pathname.endsWith(config.auth0RedirectPath),
                    isPaymentCallback: isPaymentCallback,
                    user,
                })
                .catch(ErrorReporter.captureError);
        }
    }, [isAuthLoading, isPaymentCallback, user]);

    useEffect(() => {
        if (editEnabled && growthBook) {
            const enabled = new Map<string, boolean>();
            enabled.set('edit-reservation', true);
            enabled.set('trip-api', true);
            growthBook.setForcedFeatures(enabled);
        }
    }, [editEnabled, growthBook]);

    return { isLoading: isLoading || isAuthLoading };
};

export const ObeLayoutLoader: React.FC<{ children: React.ReactNode }> = observer(({ children }) => {
    const { isLoading } = useInitApp();
    const location = useLocation();
    const { trackPageView } = useJitsu();

    React.useEffect(() => {
        datadogRum.startView(location.pathname);
        trackPageView();
    }, [location, trackPageView]);

    useOnLocationChange(() => {
        if (appStateStore.orderSummaryFooter.showTripSummary) {
            appStateStore.orderSummaryFooter.toggleSummary();
        }
    });

    const [
        { isLoading: isHirePeriodsLoading, error: hirePeriodsLoadError },
        { isLoading: isFleetTypesLoading, error: fleetTypesLoadError },
        { isLoading: isBranchesLoading, error: branchesLoadError },
        { isLoading: isCatalogLoading, error: catalogLoadError },
    ] = useQueries({
        queries: [
            { queryKey: hirePeriodQueryKeys.list(), queryFn: getHirePeriods, staleTime: STALE_TIME.INFINITY },
            { queryKey: fleetTypeQueryKeys.list(), queryFn: getFleetTypesWithPreloadedImages, staleTime: STALE_TIME.INFINITY },
            { queryKey: branchQueryKeys.list(), queryFn: getSites, staleTime: STALE_TIME.ONE_DAY },
            { queryKey: productCatalogQueryKeys.list(), queryFn: getProductCatalog, staleTime: STALE_TIME.ONE_DAY },
        ],
    });

    if (catalogLoadError && !isCatalogLoading) {
        return (
            <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
                <JucyErrorAlert
                    title="Failed to load product information"
                    message={asError(catalogLoadError).message}
                    className="animate__animated animate__fadeIn"
                    sx={{ mr: 2, width: '100%' }}
                />
            </Box>
        );
    }
    if (hirePeriodsLoadError && !isHirePeriodsLoading) {
        return (
            <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
                <JucyErrorAlert
                    title="Failed to load hire information"
                    message={asError(hirePeriodsLoadError).message}
                    className="animate__animated animate__fadeIn"
                    sx={{ mr: 2, width: '100%' }}
                />
            </Box>
        );
    }
    if (fleetTypesLoadError && !isFleetTypesLoading) {
        return (
            <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
                <JucyErrorAlert
                    title="Failed to load fleet information"
                    message={asError(fleetTypesLoadError).message}
                    className="animate__animated animate__fadeIn"
                    sx={{ mr: 2, width: '100%' }}
                />
            </Box>
        );
    }
    if (branchesLoadError && !isBranchesLoading) {
        return (
            <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
                <JucyErrorAlert
                    title="Failed to load location information"
                    message={asError(branchesLoadError).message}
                    className="animate__animated animate__fadeIn"
                    sx={{ mr: 2, width: '100%' }}
                />
            </Box>
        );
    }

    if (isLoading || isHirePeriodsLoading || isFleetTypesLoading || isBranchesLoading || isCatalogLoading) {
        return <PageLoader id="layout" />;
    }
    return <ObeContainer>{children}</ObeContainer>;
});
ObeLayoutLoader.displayName = 'ObeLayoutLoader';

export const ObeLayout: React.FC<{ children: React.ReactNode }> = observer(({ children }) => <ObeLayoutLoader>{children}</ObeLayoutLoader>);
ObeLayout.displayName = 'ObeLayout';
