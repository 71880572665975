import { observer } from 'mobx-react-lite';
import React from 'react';
import { useActiveFleetCategory } from '../../../../../hooks';
import AvailabilityStore from '../../../../../store/AvailabilityStore';

export const DirectAvailabilityCaption: React.FC = observer(() => {
    const activeCategory = useActiveFleetCategory();
    if (activeCategory?.isFreeSell) {
        return <>{activeCategory?.Name} is available</>;
    }
    if (activeCategory?.isOnRequest) {
        return <>{activeCategory?.Name} is available on request</>;
    }
    if (activeCategory?.Availability) {
        return <>{activeCategory?.Name} is not available for your dates, see other options below</>;
    }
    return (
        <>
            <span data-cy="available-count">{activeCategory ? AvailabilityStore.freeSellCount - 1 : AvailabilityStore.freeSellCount}</span> {activeCategory ? ' other ' : ''}{' '}
            {AvailabilityStore.fleetType?.name || 'vehicle'}
            {AvailabilityStore.freeSellCount !== 1 ? 's' : ''} available
            {AvailabilityStore.onRequestCount > 0 ? <>, {AvailabilityStore.onRequestCount} on request</> : '.'}
        </>
    );
});
DirectAvailabilityCaption.displayName = 'DirectAvailabilityCaption';
