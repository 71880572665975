import { makeAutoObservable } from 'mobx';
import appState from '../../../store/AppStateStore';
import availabilityStore from '../../../store/AvailabilityStore';
import paymentStore from '../../../store/PaymentStore';
import createTripStore from '../../../store/TripStore';
import { TabDetail } from './TabDetail';

export class TabManager {
    constructor() {
        makeAutoObservable(this);
    }

    get tabs(): TabDetail[] {
        return [
            {
                name: 'search',
                slug: 'search',
                label: 'Pick up & Return',
                visible: appState.mode === 'agent',
                props: {
                    disabled: false,
                },
            },
            {
                name: 'search-results',
                slug: 'search-results',
                label: 'Search results',
                visible: ['direct', 'staff'].includes(appState.mode),
                props: {
                    disabled: false,
                },
            },
            {
                name: 'results',
                slug: 'results',
                label: 'Vehicle',
                visible: availabilityStore.hasTripInfo && appState.mode === 'agent',
                props: {
                    disabled: false,
                },
            },
            {
                name: 'excess-reduction',
                slug: 'excess-reduction',
                label: 'Excess reduction',
                visible: Boolean(createTripStore.summary?.fleetCategory || availabilityStore.hasTripInfo),
                props: {
                    disabled: !createTripStore.summary?.insuranceProducts?.items.length,
                },
            },
            {
                name: 'extras',
                slug: 'extras',
                label: 'Extras',
                visible: Boolean(createTripStore.summary?.fleetCategory || availabilityStore.hasTripInfo),
                props: {
                    disabled: !createTripStore.summary?.secondaryProducts || !createTripStore.summary.selectedExcess?.productId,
                },
            },
            {
                name: 'driver',
                slug: appState.mode === 'direct' ? 'driver' : 'hirer-details',
                label: appState.mode === 'direct' ? 'Driver' : 'Hirer details',
                visible: Boolean(availabilityStore.hasTripInfo || createTripStore.summary?.selectedExcess),
                props: {
                    disabled: !createTripStore?.summary?.selectedExcess?.productId,
                },
            },
            {
                name: 'payment',
                slug: 'payment',
                label: 'Payment',
                visible: createTripStore.userMode === 'direct' && Boolean(paymentStore.getPaymentOptions(createTripStore.summary)?.length),
                props: {
                    disabled: !createTripStore?.convertUrl,
                },
            },
        ];
    }

    get visibleTabs(): TabDetail[] {
        return this.tabs.filter((t) => t.visible);
    }

    getTabBySlug(slug: string): TabDetail | undefined {
        return this.tabs.find((t) => t.slug === slug);
    }

    getTabByIndex(index: number): TabDetail | undefined {
        return this.tabs[index];
    }

    getTabIndex(tab: number | string | TabDetail): number {
        const t = this.getTab(tab);
        return t ? this.tabs.indexOf(t) : 0;
    }

    getTab(tab: number | string | TabDetail): TabDetail | undefined {
        if (typeof tab === 'number') {
            return this.getTabByIndex(tab);
        }
        if (typeof tab === 'string') {
            return this.getTabBySlug(tab);
        }
        return tab;
    }

    getTabProps(tab: TabDetail) {
        return {
            value: tab.slug,
            label: tab.label,
            id: `trip-tab-${tab.slug}`,
            'aria-controls': `trip-tabpanel-${tab.slug}`,
            ...tab.props,
        };
    }

    getTabPanelProps(tab: TabDetail) {
        return {
            value: tab.slug,
        };
    }
}
