import React from 'react';
import { useParams } from 'react-router';
import AgentCreateQuotePage from './agent/AgentCreateQuotePage.jsx';
import DirectCreateQuotePage from './direct/DirectCreateQuotePage';

const CreateQuotePage: React.FC = () => {
    const { mode } = useParams();

    if (!mode || mode === 'direct') {
        return <DirectCreateQuotePage />;
    }
    return <AgentCreateQuotePage />;
};
CreateQuotePage.displayName = 'CreateQuotePage';

export default CreateQuotePage;
