import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import AccountKeyStore from '#/store/AccountKeyStore';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GetAppIcon from '@mui/icons-material/GetApp';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { PopupState } from 'material-ui-popup-state/hooks';
import { useRatesUrl } from '../../../../hooks';

export const HirePeriodsRatesMenuItems: React.FC<{ popupState?: PopupState }> = observer(({ popupState }) => {
    const accountKey = AccountKeyStore.accountKey;
    const auRatesUrl = useRatesUrl({ accountKey, countryCode: 'au' })?.toString();
    const nzRatesUrl = useRatesUrl({ accountKey, countryCode: 'nz' })?.toString();

    return (
        <>
            <MenuItem onClick={popupState?.close} component={Link} to="/hire-periods">
                <ListItemIcon>
                    <CalendarMonthIcon fontSize="small" />
                </ListItemIcon>
                Hire periods
            </MenuItem>
            {auRatesUrl ? (
                <MenuItem onClick={popupState?.close} component="a" href={auRatesUrl.toString()}>
                    <ListItemIcon>
                        <GetAppIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>AU rates</ListItemText>
                </MenuItem>
            ) : null}
            {nzRatesUrl ? (
                <MenuItem onClick={popupState?.close} component="a" href={nzRatesUrl.toString()}>
                    <ListItemIcon>
                        <GetAppIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>NZ rates</ListItemText>
                </MenuItem>
            ) : null}
        </>
    );
});
