import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useIsEditEnabled } from '#/hooks/useIsEditEnabled';
import { Button, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { addDays, differenceInHours, parseISO } from 'date-fns';
import { action } from 'mobx';
import ReviewActions from '../../components/Links/ReviewActions';
import ReservationReviewCard from '../../components/Reservation/ReservationReviewCard';
import config from '../../config';
import { useAuth, useGetAmendmentEmail, useRouteNavigate } from '#/hooks';
import SimpleTemplateParser from '../../lib/SimpleTemplateParser';
import AccountDetailsStore from '../../store/AccountDetailsStore';
import appState from '../../store/AppStateStore';
import ReservationStore from '../../store/ReservationStore';
import RouteRegistry from '../../store/RouteRegistry';
import QuotePage from './QuotePage';
import { useMutation } from '@tanstack/react-query';
import JucyAPI from '#/lib/JucyAPI';

const Content = ({ activeQuote }) => {
    const dateCreated = parseISO(activeQuote.DateCreated);
    const expiresDate = addDays(dateCreated, config.quoteExpireDays);
    const remaining = differenceInHours(expiresDate, new Date());

    const cancelConfirmLink = RouteRegistry.getPath('quote-cancel', {
        reservationReference: activeQuote.ReservationReference,
    });
    const amendmentsEmail = useGetAmendmentEmail();
    useEffect(() => {
        appState.ui.showFooter = false;
        return () => {
            appState.ui.showFooter = true;
        };
    }, []);
    let message = '';
    if (activeQuote.isQuote) {
        if (remaining > 0) {
            message = `Your JUCY quote is saved and will expire in ${remaining} hours, please note only the price will be held for this period`;
        } else {
            message = 'This quote has expired';
        }
    }
    const toolTip = `Cancel Booking is disabled because the booking falls within the minimum cancellation period. If you still want to cancel, please contact Sales Hub at ${amendmentsEmail} Please note cancellation fees may apply.`;
    const cannotBeCancelled = AccountDetailsStore.resolveCannotBeCancelled(activeQuote);
    return (
        <div className="review-quote-page" data-cy="review-quote-page">
            <div className="d-flex">
                <div>
                    <h1 className="page-title h2" data-cy="page-title">
                        <span> Review {activeQuote.isRequest ? 'Request' : activeQuote.isQuote ? 'Quote' : 'Booking'}</span>
                    </h1>
                    <div className="fw-bold smaller">Reference number: {activeQuote.ReservationDisplayReference}</div>
                </div>
                {activeQuote.isConfirmed && (
                    <div className="ms-auto text-end">
                        <Button
                            color="primary"
                            variant="text"
                            size="small"
                            component={Link}
                            to={cancelConfirmLink}
                            disabled={cannotBeCancelled}
                            sx={{
                                fontSize: 14,
                                fontStyle: 'italic',
                                fontWeight: 'bold',
                                py: 0,
                                lineHeight: 1,
                            }}
                        >
                            Cancel {activeQuote.isRequest ? 'Request' : 'Booking'}
                        </Button>
                        {cannotBeCancelled && (
                            <div id="cancel-info" className="small">
                                <Tooltip title={toolTip} arrow>
                                    <div>
                                        {' '}
                                        Why can&apos;t I cancel the booking? <div className="fa fa-info-circle"></div>
                                    </div>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div className="animate__animated animate__fadeIn">
                <p>{message}</p>
                <ReservationReviewCard reservation={activeQuote} />
                <ReviewQuoteFooter activeQuote={activeQuote} />
            </div>
        </div>
    );
};

const ReviewQuoteFooter = ({ activeQuote }) => {
    const { user, isLoading: isAuthLoading } = useAuth();
    const amendmentsEmail = useGetAmendmentEmail();
    const navigate = useRouteNavigate();
    const [isConfirmingQuote, setConfirmingQuote] = useState(false);
    const { editEnabled, isLoading: isUseEditLoading } = useIsEditEnabled();
    const { mutateAsync: emailReservationReminder, isPending: emailReservationReminderIsPending } = useMutation({
        mutationKey: ['send-checkin-email', activeQuote.ReservationReference],
        mutationFn: async () => JucyAPI.emailReservationReminder({ quoteId: activeQuote.QuoteId }),
        onError: (e) => {
            toast.error((e.response && e.response.body && e.response.body.Message) || e.message);
        },
        onSuccess: () => {
            toast.success(`Check in reminder sent to ${activeQuote.HirerDetails.Email}`);
        },
    });

    const editLink = useMemo(
        () =>
            RouteRegistry.getPath('create-trip', {
                action: 'edit',
                fleetTypeOrResRef: activeQuote.ReservationReference,
            }),
        [activeQuote.ReservationReference]
    );

    const onConfirmQuoteClick = useCallback(() => {
        ReservationStore.setDeposit(undefined);
        ReservationStore.confirmQuote()
            .then(() => {
                navigate('quote-confirmed', {
                    params: { reservationReference: ReservationStore.activeQuote.ReservationReference },
                });
                ReservationStore.reset();
            })
            .catch(
                action('fetchError', (e) => {
                    toast.error((e.response && e.response.body && e.response.body.Message) || e.message);
                    console.error(e);
                })
            )
            .finally(() => setConfirmingQuote(false));
    }, [navigate]);

    const subject = SimpleTemplateParser(config.editBookingSubjectTemplate || 'Request changes for booking ref #$ReservationReference', {
        ReservationReference: activeQuote && activeQuote.ReservationDisplayReference,
    });

    if (isAuthLoading || isUseEditLoading) {
        return null;
    }

    if (activeQuote.isComplete) {
        return (
            <Typography lineHeight={1} variant="caption">
                This booking is complete and can no longer be modified
            </Typography>
        );
    }

    if (!activeQuote.isConfirmed) {
        return (
            <ReviewActions
                btnProps={{
                    'data-cy': 'confirm-quote-btn',
                    disabled: isConfirmingQuote,
                    showLoader: isConfirmingQuote,
                    onClick: () => {
                        setConfirmingQuote(true);
                        onConfirmQuoteClick();
                    },
                }}
                btnLabel={activeQuote.isConfirmed ? 'Request vehicle' : 'Confirm'}
            >
                {editEnabled ? (
                    <>Amendments and changes can be made post confirmation up to {user.type === 'agent' ? user.settings.cancellationMinimum || 24 : 24} hours before pick up</>
                ) : (
                    <>
                        If you are ready to convert this quote into a booking, click Confirm. Please note any amendments after confirmation of the booking can only be made by contacting the
                        reservations team
                    </>
                )}
            </ReviewActions>
        );
    }

    return (
        <>
            <Stack direction={{ sx: 'column', sm: 'row' }} justifyContent={{ sx: 'center', sm: 'end' }} alignItems={'center'}>
                {editEnabled ? (
                    <>
                        <ReviewActions
                            btnProps={{
                                component: Link,
                                'data-cy': 'edit-quote-btn',
                                to: editLink,
                            }}
                            btnLabel="Edit booking"
                        />
                    </>
                ) : (
                    <>
                        <Typography sx={{ fontSize: 12, justifyContent: 'center' }}>
                            If you’d like to make any changes to this confirmed booking you need to contact our support team. They will come back to you with the details regarding the
                            changes and they will update the booking for you once you’ve agreed on the modifications.
                        </Typography>
                        <ReviewActions
                            btnProps={{
                                'data-cy': 'confirm-quote-btn',
                                component: 'a',
                                rel: 'noopener noreferrer',
                                onClick: () => {
                                    setConfirmingQuote(true);
                                    onConfirmQuoteClick();
                                },
                                href: `mailto:${amendmentsEmail || config.editBookingEmailAddress}?subject=${subject}`,
                            }}
                            btnLabel="Request changes"
                        ></ReviewActions>
                    </>
                )}
            </Stack>
            <Divider />
            <Stack direction={{ sx: 'column', sm: 'row' }} justifyContent={{ sx: 'center', sm: 'end' }} alignItems={'center'}>
                <Typography sx={{ fontSize: 12, justifyContent: 'end' }}>Send a check-in email to the hirer</Typography>
                <ReviewActions
                    btnProps={{
                        'data-cy': 'checkin-email-btn',
                        onClick: emailReservationReminder,
                        disabled: emailReservationReminderIsPending,
                        showLoader: emailReservationReminderIsPending,
                    }}
                    btnLabel="Check-in Email"
                />
            </Stack>
        </>
    );
};
const ReviewQuotePage = observer((props) => <QuotePage child={Content} {...props} />);
ReviewQuotePage.displayName = 'ReviewQuotePage';

export default ReviewQuotePage;
