type CreateTextRangeHTMLElement = HTMLElement & {
    createTextRange: () => TextRange;
};
type TextRange = {
    moveToElement: (el: HTMLElement) => void;
    select: () => void;
};

const hasCreateTextRange = (input: unknown): input is CreateTextRangeHTMLElement => (input as { body: CreateTextRangeHTMLElement })?.body?.createTextRange !== undefined;

export const copyElementToClipboard = async (el: HTMLElement | null) => {
    if (!el) {
        return false;
    }
    try {
        let range: Range | TextRange | null;
        let selection: Selection | null;
        if (hasCreateTextRange(document.body)) {
            range = document.body.createTextRange();
            range.moveToElement(el);
            range.select();
        } else if (window.getSelection) {
            selection = window.getSelection();
            range = document.createRange();
            range.selectNodeContents(el);
            selection?.removeAllRanges();
            selection?.addRange(range);
        }
        document.execCommand('copy');
        window.getSelection()?.removeAllRanges();
        return true;
    } catch (e) {
        return false;
    }
};
