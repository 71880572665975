import { useGrowthBook } from '@growthbook/growthbook-react';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import PageLoader from '#/components/LoadingAnimation/PageLoader';
import { HotJar } from '#/components/tags';
import { Intercom } from '#/components/tags/Intercom';
import ShowDNRModal from '../../../components/DoNotRentModal/DoNotRentModal';
import EmailQuoteModal from '../../../components/EmailQuote/EmailQuoteModal';
import AgentFooterNav from '../../../components/Footer/AgentFooterNav';
import { MainAppBar } from '../../../components/MainAppBar/MainAppBar';
import { AppContainer } from '../../../containers/AppContainer';
import accountDetailsStore from '../../../store/AccountDetailsStore';
import appStateStore from '../../../store/AppStateStore';
import sitesStore from '../../../store/SitesStore';
import { FatalError } from './FatalError';

export const ObeContainer: React.FC<{ children: React.ReactNode }> = observer(({ children }) => {
    const sitesStoreState = sitesStore.state;
    const accountDetailsStoreState = accountDetailsStore.state;
    const appStateStoreState = appStateStore.state;
    const isPaymentCallback = appStateStore.isPaymentCallback;
    const errorTitle = useMemo(() => {
        if (sitesStoreState === 'error') {
            return 'Error loading sites';
        } else if (accountDetailsStoreState === 'error') {
            return 'Error loading account details';
        }
        return 'Unknown error';
    }, [sitesStoreState, accountDetailsStoreState]);
    const showError = useMemo(() => sitesStoreState === 'error' || accountDetailsStoreState === 'error', [sitesStoreState, accountDetailsStoreState]);
    const showLoading = useMemo(() => appStateStoreState === 'loading' && !isPaymentCallback && !showError, [appStateStoreState, isPaymentCallback, showError]);
    const showApp = useMemo(() => appStateStoreState === 'done' && !showError && !showLoading, [appStateStoreState, showError, showLoading]);
    const gb = useGrowthBook();

    if (gb && !gb?.ready) {
        return <PageLoader />;
    }

    return (
        <>
            {appStateStore.ui.showHeader && <MainAppBar />}
            <AppContainer>
                {showLoading ? <PageLoader hasContainer={true} /> : null}
                {showError ? <FatalError title={errorTitle} message={accountDetailsStore.message || sitesStore.message} /> : null}
                {showApp ? children : null}
            </AppContainer>
            {appStateStore.ui.showHeader && <AgentFooterNav />}
            {appStateStore.state === 'done' && <PostAppLoaded />}
            <EmailQuoteModal />
        </>
    );
});

const PostAppLoaded: React.FC = observer(() => (
    <>
        {appStateStore.ui.showChat && appStateStore.user.type !== 'agent' ? <Intercom /> : null}
        <HotJar />
        <ShowDNRModal />
    </>
));

ObeContainer.displayName = 'AppComponent';
