
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[brand]/obe",
      function () {
        return require("private-next-pages/[brand]/obe.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[brand]/obe"])
      });
    }
  