import { observer } from 'mobx-react-lite';
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useIsEditEnabled } from '#/hooks/useIsEditEnabled';
import routeRegistry from '#/store/RouteRegistry';
import { Box, Tab, TabProps, Tabs, styled, tabsClasses, useMediaQuery, useTheme } from '@mui/material';
import { isAfter, isSameDay, subHours } from 'date-fns';
import { autorun, runInAction } from 'mobx';
import FatalError from '../../components/Alert/FatalError';
import { JucyErrorAlert } from '../../components/Alert/JucyErrorAlert';
import DelayedSpinner from '../../components/LoadingAnimation/DelayedSpinner';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import SummaryColumnLayout from '../../containers/SummaryColumnLayout';
import { useAccountInfo, useActiveRoute, useAuth, useNestedSearchParams, useRouteNavigate, useSearchParams, useTripApi, useUpdateRouteParams } from '../../hooks';
import { resolveError } from '../../lib/resolveError';
import accountKeyStore from '../../store/AccountKeyStore';
import FleetTypesStore from '../../store/FleetTypesStore';
import rentalTripSearchStore from '../../store/RentalTripSearchStore';
import tripStore, { RentalTripSearchParams, TripStoreState } from '../../store/TripStore';
import { BookingCart } from '../../types/BookingCart';
import { UserMode } from '../../types/UserMode';
import { DirectLandingPage, TripTabPanel } from './components';
import { EditConfirmedBookingAlert, EditMinPeriodBookingAlert, EditOnRequestAlert, EditPastBookingAlert } from './components/Alerts';
import {
    useHandleOnExcessSelected,
    useHandleOnExtrasSelected,
    useHandleOnFleetCategorySelected,
    useHandleOnHirerDetailsSubmitted,
    useHandleOnSearchSubmitted,
    useTripRouteParams,
} from './hooks';
import { useInitStoreBySearchParams } from './hooks/useInitStoreBySearchParams';
import { useInitStoresByReservationRef } from './hooks/useInitStoresByReservationRef';
import { TabDetail, tabManager } from './lib';

const JucyTab = styled((props: TabProps) => <Tab {...props} />)(({ theme }) => ({
    '&.MuiTab-root.Mui-selected': {
        color: theme.palette.text.primary,
    },
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
    fontWeight: 'bold',
}));

const useActiveTab = (): TabDetail => {
    const activeRoute = useActiveRoute();
    const updateRoute = useUpdateRouteParams();
    const defaultTab = tabManager.visibleTabs?.[0];
    const routeParams = useTripRouteParams();
    const [activeTab, setActiveTab] = React.useState(() => tabManager.visibleTabs.find((t) => t.slug === routeParams.activeTab) || defaultTab);
    const ready = tripStore.ready;
    useEffect(() => {
        if (routeParams.action === 'create' && !routeParams.fleetTypeOrResRef) {
            updateRoute(
                {
                    activeTab: defaultTab?.slug,
                    fleetTypeOrResRef: FleetTypesStore.getDefault().slug,
                },
                { keepSearch: true, replace: true }
            );
        }
    }, [routeParams.action, defaultTab?.slug, routeParams.fleetTypeOrResRef, updateRoute]);

    useEffect(() => {
        if (ready && routeParams.activeTab) {
            const tab = tabManager.visibleTabs.find((t) => t.slug === routeParams.activeTab);
            if (tab && activeTab && tab.slug !== activeTab.slug) {
                setActiveTab(tab);
            } else if (!tab && defaultTab?.slug && routeParams.activeTab !== defaultTab?.slug) {
                updateRoute({ activeTab: defaultTab.slug }, { keepSearch: true });
            }
        }
    }, [activeRoute, activeTab, routeParams.activeTab, defaultTab?.slug, ready, updateRoute]);

    return useMemo(() => activeTab, [activeTab]);
};

const useInitTripStore = (): TripStoreState => {
    const [searchParams] = useNestedSearchParams<RentalTripSearchParams>();
    const routeParams = useTripRouteParams();
    const [tripStoreState, setTripStoreState] = useState<TripStoreState>(tripStore.state);
    const [storeReady, setStoreReady] = useState<boolean>(tripStore.ready);
    const [isHydrated, setIsHydrated] = useState<boolean>(tripStore.isHydrated);
    const [state, setState] = useState<TripStoreState>('pending');
    const initStoresByReservationRef = useInitStoresByReservationRef();
    const initStoreBySearchParams = useInitStoreBySearchParams();
    const navigate = useRouteNavigate();

    useEffect(() => {
        const disposer = autorun(() => {
            setTripStoreState(tripStore.state);
            setStoreReady(tripStore.ready);
            setIsHydrated(tripStore.isHydrated);
        });
        return () => {
            disposer();
        };
    }, []);

    useEffect(() => {
        tripStore.userMode = routeParams.mode as UserMode;
    }, [routeParams.mode]);

    useEffect(() => {
        if (tripStoreState === 'error') {
            setState('error');
            return;
        }
        if (!isHydrated || ['loading', 'done'].includes(state)) {
            return;
        }
        (async () => {
            if (routeParams.action === 'create') {
                setState('loading');
                await initStoreBySearchParams(searchParams).catch();
                setState('done');
                return;
            }
            if (routeParams.action === 'edit' && routeParams.fleetTypeOrResRef) {
                setState('loading');
                await initStoresByReservationRef(routeParams.fleetTypeOrResRef);
                setState('done');
                return;
            }
            navigate('create-trip', {
                params: {
                    ...routeParams,
                    fleetTypeOrResRef: routeParams.fleetTypeOrResRef || FleetTypesStore.getDefault().slug,
                    action: 'create',
                },
                keepSearch: true,
                replace: true,
            });
        })().catch((e) => {
            resolveError(e).then(({ error, message }) => {
                runInAction(() => {
                    tripStore.state = 'error';
                    tripStore.error = error;
                    tripStore.message = message;
                    console.error(error);
                });
            });
        });
    }, [
        initStoreBySearchParams,
        initStoresByReservationRef,
        isHydrated,
        navigate,
        routeParams,
        routeParams.action,
        routeParams.fleetTypeOrResRef,
        searchParams,
        state,
        storeReady,
        tripStoreState,
    ]);

    return state;
};

export interface TripPageLayoutProps {
    summary: BookingCart;
}

const TripPageLayout: React.FC<TripPageLayoutProps> = observer(({ summary }) => {
    const activeTab = useActiveTab();
    const { accountInfo } = useAccountInfo(accountKeyStore.accountKey);
    const updateRoute = useUpdateRouteParams();
    const { editEnabled } = useIsEditEnabled();
    const onSearchSubmit = useHandleOnSearchSubmitted({ userMode: summary.userMode });
    const onFleetCategorySelected = useHandleOnFleetCategorySelected();
    const onExcessSelected = useHandleOnExcessSelected({ summary: summary });
    const onExtrasSelected = useHandleOnExtrasSelected({ summary: summary });
    const onHirerDetailsSubmit = useHandleOnHirerDetailsSubmitted({ summary: summary });
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const handleTabChanged = useCallback(
        (_event: React.SyntheticEvent, newValue: string) => {
            if (newValue) {
                updateRoute({ activeTab: newValue }, { keepSearch: true });
            }
        },
        [updateRoute]
    );

    if (summary.action === 'edit') {
        if (summary?.isOnRequest) {
            return <EditOnRequestAlert summary={summary} />;
        }
        if (isSameDay(new Date(), summary?.pickUpDate as Date) || isAfter(new Date(), summary?.pickUpDate as Date)) {
            return <EditPastBookingAlert summary={summary} />;
        }
        if (!editEnabled && summary.bookingStatus === 'reservation-confirmed') {
            return <EditConfirmedBookingAlert summary={summary} />;
        }
        const minimumCancellationDate = subHours(new Date(summary?.pickUpDate as Date), accountInfo?.settings?.cancellationMinimum || 0);
        if (isSameDay(new Date(), minimumCancellationDate) || isAfter(new Date(), minimumCancellationDate)) {
            return <EditMinPeriodBookingAlert summary={summary} />;
        }
    }

    if (rentalTripSearchStore.state === 'error') {
        return <FatalError title={rentalTripSearchStore.message} />;
    }

    if (!summary?.fleetCategory && summary?.userMode == 'direct') {
        return <DirectLandingPage summary={summary} />;
    }

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={activeTab?.slug}
                    onChange={handleTabChanged}
                    aria-label="booking step tabs"
                    variant="scrollable"
                    scrollButtons={isXs ? true : 'auto'}
                    allowScrollButtonsMobile={true}
                    sx={{
                        '& .MuiTabs-indicator': { height: 4 },
                        [`& .${tabsClasses.flexContainer}`]: {
                            width: 0,
                        },
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                    }}
                >
                    {tabManager.visibleTabs.map((t) => (
                        <JucyTab key={t.slug} {...tabManager.getTabProps(t)} />
                    ))}
                </Tabs>
            </Box>
            <Suspense fallback={<DelayedSpinner center={true} />}>
                {tabManager.visibleTabs.map((t) => (
                    <TripTabPanel
                        key={t.slug}
                        active={activeTab?.slug === t.slug}
                        tab={t}
                        summary={summary}
                        onSearchSubmit={onSearchSubmit}
                        onFleetCategorySelected={onFleetCategorySelected}
                        onExcessSelected={onExcessSelected}
                        onExtrasSelected={onExtrasSelected}
                        onHirerDetailsSubmit={onHirerDetailsSubmit}
                    />
                ))}
            </Suspense>
        </>
    );
});
const TripPage: React.FC = observer(() => {
    const { isLoading } = useAuth();
    const state = useInitTripStore();
    const tripEnabled = useTripApi();
    const routeParams = useTripRouteParams();
    const [search] = useSearchParams();

    if (!tripEnabled) {
        const to = {
            pathname:
                routeParams.action === 'create' || !routeParams.fleetTypeOrResRef
                    ? routeRegistry.getPath('create-quote', {
                          fleetType: FleetTypesStore.getFleetTypeBySlug(routeParams.fleetTypeOrResRef)?.slug || FleetTypesStore.getDefault().slug,
                          mode: routeParams.mode,
                      })
                    : routeRegistry.getPath(routeParams.mode === 'direct' ? 'direct-edit-quote' : 'edit-quote', {
                          reservationReference: routeParams.fleetTypeOrResRef,
                          mode: routeParams.mode,
                      }),
            search: new URLSearchParams(search as Record<string, string>).toString(),
        };
        return <Navigate to={to} replace={true} />;
    }

    if (state === 'error') {
        return (
            <Box className="animate__animated animate__fadeIn" width="100%" p={4}>
                <JucyErrorAlert title={tripStore.errorTitle || 'Error loading reservation'} message={tripStore.message} />
            </Box>
        );
    }
    if (state === 'loading' || state === 'pending' || !tripEnabled || !tripStore.summary || isLoading) {
        return (
            <Box display="flex" width="100%" justifyContent="center" p={4}>
                <LoadingAnimation variant="primary" className="animate__animated animate__fadeIn" />
            </Box>
        );
    }

    return (
        <SummaryColumnLayout routeId="direct-edit-quote" summary={tripStore.summary}>
            <TripPageLayout summary={tripStore.summary} />
        </SummaryColumnLayout>
    );
});
TripPage.displayName = 'TripPage';
export default TripPage;
