import { PaymentConfig } from '@jucy/rentals-api-client/rentals-api-v3';
import { JucyCountryCode } from '@jucy/rentals-common';
import { PaymentIntent, SetupIntent } from '@stripe/stripe-js';
import config from '#/config';
import jucyRentalsApiClient from '#/services/jucyRentalsApi';
import { getPaymentCallbackUrl } from '#/lib/payments';

export type ReservationPaymentTokenData = { token: string; amount: number; countryCode: JucyCountryCode; confirmUrl: string };
export class JucyPayments {
    //Get payment url for payment request
    getPaymentUrlFromPaymentId(paymentId: string) {
        const result = new URL('/api/v3/payment/complete', config.apiBaseUrl);
        result.searchParams.append('data', paymentId);
        return result.toString();
    }

    getPaymentConfiguration(countryCode: string): Promise<PaymentConfig> {
        return jucyRentalsApiClient.get({
            path: '/api/v3/payment/config',
            query: { countryCode },
        });
    }
    getReservationPaymentTokenUrl(reservationReference: string, { amount, storeCard, returnUrl }: { amount?: number; storeCard?: boolean; returnUrl?: string }) {
        const result = new URL(`/api/self-service/reservation/ref/${reservationReference}/payment-token`, config.apiBaseUrl);
        result.searchParams.set('gateway', 'stripe');
        result.searchParams.set('storeCard', storeCard ? 'true' : 'false');
        result.searchParams.set('returnUrl', returnUrl || getPaymentCallbackUrl({ ref: reservationReference }));
        if (amount) {
            result.searchParams.append('amount', `${amount}`);
        }
        return result;
    }

    async getReservationPaymentToken({
        reservationReference,
        amount,
        returnUrl,
        storeCard,
    }: {
        reservationReference: string;
        amount?: number;
        returnUrl?: string;
        storeCard?: boolean;
    }): Promise<ReservationPaymentTokenData> {
        const url = this.getReservationPaymentTokenUrl(reservationReference, {
            amount,
            returnUrl,
            storeCard,
        });
        return await jucyRentalsApiClient.get({
            path: url.pathname,
            query: Object.fromEntries(url.searchParams),
        });
    }

    async confirmPaymentIntent({ url: passedUrl, paymentIntent }: { url: string; paymentIntent: PaymentIntent }): Promise<{ result: boolean }> {
        const url = new URL(passedUrl);
        url.searchParams.set('redirect', 'false');
        url.searchParams.set('callbackResult', 'success');
        url.searchParams.set('payment_intent', paymentIntent.id);
        url.searchParams.set('payment_intent_client_secret', paymentIntent.client_secret || '');
        return await jucyRentalsApiClient.get({
            path: url.pathname,
            query: Object.fromEntries(url.searchParams),
        });
    }

    async confirmSetupIntent({ url: passedUrl, setupIntent }: { url: string; setupIntent: SetupIntent }): Promise<{ result: boolean }> {
        const url = new URL(passedUrl);
        url.searchParams.set('redirect', 'false');
        url.searchParams.set('callbackResult', 'success');
        url.searchParams.set('storeCard', 'true');
        url.searchParams.set('setup_intent', setupIntent.id);
        url.searchParams.set('setup_intent_client_secret', setupIntent.client_secret || '');
        return await jucyRentalsApiClient.get({
            path: url.pathname,
            query: Object.fromEntries(url.searchParams),
        });
    }
}

export const jucyPay = new JucyPayments();
