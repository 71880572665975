import React from 'react';
import { Box, Fade, LinearProgress } from '@mui/material';

export interface LoadingContainerProps {
    isLoading?: boolean;
    children: React.ReactNode;
}
export const LoadingContainer: React.FC<LoadingContainerProps> = ({ isLoading, children }) => (
    <Box sx={{ position: 'relative', minHeight: '20px' }}>
        <Fade in={isLoading}>
            <Box sx={(theme) => ({ position: 'absolute', width: '100%', top: theme.spacing(2) })}>
                <LinearProgress />
            </Box>
        </Fade>
        <Fade in={!isLoading}>
            <Box>{children}</Box>
        </Fade>
    </Box>
);
