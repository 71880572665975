import React from 'react';
import { AlertTitle, Typography } from '@mui/material';
import { JucyButton } from '../Button/Button';
import { JucyAlert, JucyAlertProps } from './JucyAlert';

export interface OBEErrorAlertProps extends Omit<JucyAlertProps, 'severity' | 'color' | 'title'> {
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    message?: React.ReactNode;
}

export const OBEErrorAlert: React.FC<OBEErrorAlertProps> = ({ title = 'WHOOPS!', subTitle, message, children, ...props }) => (
    <JucyAlert
        color="primary"
        variant="outlined"
        severity="warning"
        sx={{
            '& .MuiAlert-icon': {
                color: 'primary.main',
                fontSize: 40,
            },
        }}
        {...props}
    >
        <AlertTitle
            sx={(theme) => ({
                lineHeight: 1,
                color: theme.palette.primary.main,
                fontSize: theme.typography.h3.fontSize,
            })}
        >
            {title}
        </AlertTitle>

        {subTitle ? <h2 className="font-apex fw-heavy h5 mb-0">{subTitle}</h2> : null}
        <Typography sx={{ color: 'text.primary' }} variant="body2" gutterBottom={true}>
            If you wish to create a booking, <a href="mailto:res@jucyworld.com">send us a message</a> and we&apos;ll be in touch soon.
            <br />
            Or if you have any questions, <a href="tel:0800399736">call us on 0800 388 736</a>
        </Typography>
        <Typography sx={{ color: 'text.primary' }} variant="body2">
            Alternatively, please
        </Typography>
        <JucyButton color="primary" variant="contained" onClick={() => window.location.reload()}>
            Try again
        </JucyButton>
        {children}
    </JucyAlert>
);
