import config from '../config';
import appState from '../store/AppStateStore';
import { BookingCart } from '../types/BookingCart';

const createCheckInLink = (summary: BookingCart): URL => {
    const checkInUrl = new URL('/checkin', appState.brand === 'jucy' ? config.dashboardUrl.jucy : config.dashboardUrl.star);
    checkInUrl.searchParams.set('lastName', `${summary.hirerDetails?.lastName}`);
    checkInUrl.searchParams.set('bookingNumber', `${summary.reservationId}`);
    checkInUrl.searchParams.set('pickUpBranch', `${summary.pickUpLocation?.SiteCode}`);
    checkInUrl.searchParams.set('reservationReference', summary.reservationReference || '');
    checkInUrl.searchParams.set('utm_source', 'obe');
    checkInUrl.searchParams.set('utm_campaign', 'check-in');
    checkInUrl.searchParams.set('utm_medium', 'check-in-btn');
    checkInUrl.searchParams.set('utm_id', 'pre-pick-up');
    return checkInUrl;
};
export default createCheckInLink;
