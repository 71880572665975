import React from 'react';
import { Link } from 'react-router-dom';
import { AlertTitle, Button } from '@mui/material';
import { JucyAlert } from '../../../../components/Alert/JucyAlert';
import RouteRegistry from '../../../../store/RouteRegistry';
import { BookingCart } from '../../../../types/BookingCart';

export const EditOnRequestAlert: React.FC<{
    summary: BookingCart;
}> = ({ summary }) => {
    const cancelLink = RouteRegistry.getPath('quote-cancel', {
        reservationReference: summary.reservationReference,
    });
    return (
        <JucyAlert color="primary" variant="outlined" severity="info">
            <AlertTitle>Reservation is on request</AlertTitle>
            Your booking <strong>{summary.reference}</strong> is currently on request.
            <br />
            <br />
            Changes or amendments are unavailable until availability has been confirmed.
            <br />
            <br />
            Please contact us if you wish to make any changes to this booking.
            <br />
            Or to cancel this request, click the following link &nbsp;
            <Button color="primary" variant="outlined" size="small" component={Link} to={cancelLink}>
                Cancel Booking
            </Button>
        </JucyAlert>
    );
};
