import React from 'react';
import { Link } from 'react-router-dom';
import { AlertTitle, Button } from '@mui/material';
import { JucyAlert } from '../../../../components/Alert/JucyAlert';
import { useEditReservationHref } from '../../../../hooks';
import { BookingCart } from '../../../../types/BookingCart';

export const EditPastBookingAlert: React.FC<{
    summary: BookingCart;
}> = ({ summary }) => {
    const editLink = useEditReservationHref(summary);

    return (
        <JucyAlert color="primary" variant="outlined" severity="info">
            <AlertTitle>Booking amendment unavailable</AlertTitle>
            We're sorry, but you're no longer able to make online changes to your
            <br />
            booking <strong>{summary.reference}</strong>
            <br />
            <br />
            The pick-up date has passed.
            <br />
            <br />
            If you have any concerns or questions about your booking, please don't
            <br />
            hesitate to contact us.
            <br />
            Our support team is here to help and can advise you on any options or
            <br />
            answer any concerns.
            <br />
            <Button color="primary" variant="outlined" size="small" component={Link} to={editLink}>
                Contact us
            </Button>
        </JucyAlert>
    );
};
