import config from '#/config';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { isValidDate } from '#/lib/dates';
import AvailabilityStore from '#/store/AvailabilityStore';
import reservationStore from '#/store/ReservationStore';
import RouteRegistry from '#/store/RouteRegistry';
import { ReservationSummary } from '@jucy/rentals-api-client/rentals-api';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { addDays, differenceInHours, format } from 'date-fns';
import { ReservationsTableSummary } from './ReservationsTableSummary';

export const ReservationsTableRow: React.FC<{ row: ReservationSummary }> = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const expiresDate = addDays(row.created, config.quoteExpireDays);
    const expiresIn = differenceInHours(expiresDate, new Date()) + 12;
    const status = row.status === 'confirmed' && row.availability === 'onrequest' ? 'Requested' : (row.status as string);
    const statusLabel = status ? status.charAt(0).toUpperCase() + status.slice(1).toLowerCase() : 'unknown';
    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{row.hirer ? [row.hirer.firstName, row.hirer.lastName].filter((e) => e).join(' ') : 'Unknown'}</TableCell>
                <TableCell>{row.bookingNo}</TableCell>
                <TableCell>{row.ref}</TableCell>
                <TableCell>{row.voucherRef}</TableCell>
                <TableCell>{isValidDate(row.created) ? format(row.created, 'd MMM yyyy') : ''}</TableCell>
                <TableCell>
                    {statusLabel}
                    {status === 'quote' && <div className="fst-italic">{expiresIn > 0 ? `${expiresIn}h remaining` : 'Expired'}</div>}
                </TableCell>
                <TableCell>
                    {row.status !== 'cancelled' && (
                        <Link
                            className="btn btn-small btn-purple"
                            onClick={() => {
                                AvailabilityStore.reset();
                                reservationStore.reset();
                            }}
                            to={RouteRegistry.getPath('quote', {
                                reservationReference: row.ref,
                            })}
                        >
                            Manage
                        </Link>
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <ReservationsTableSummary reservationReference={row.ref} voucherRef={row.voucherRef} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
