import { ProductLineFromJSON, TripDetails, TripInfoRequest } from '@jucy/rentals-api-client/rentals-api-v3';
import { Site } from '../../lib/api/model/Site';
import { getTotalLineValue } from '../../lib/sumLineTotal';
import { BookingCart } from '../../types/BookingCart';
import SitesStore from '../SitesStore';

export type SummaryFromTripDetailsProps = Partial<BookingCart> & { tripDetails: TripDetails; tripInfo?: Partial<TripInfoRequest> };
export const summaryFromTripDetails = ({ tripDetails, tripInfo, ...props }: SummaryFromTripDetailsProps): BookingCart => {
    const fleetCategoryMandatoryFees =
        tripDetails.fleetCategory?.mandatoryFees?.map((fee) => {
            const line = ProductLineFromJSON(fee);
            line.total.value = getTotalLineValue(line);
            return line;
        }) || [];
    const fleetCategoryMandatoryFeesIds = fleetCategoryMandatoryFees.map((fee) => fee.productId);
    return new BookingCart({
        ...props,
        pickUpLocation: SitesStore.getSiteByCode(tripDetails.pickUpLocation) as Site,
        dropOffLocation: SitesStore.getSiteByCode(tripDetails.dropOffLocation) as Site,
        pickUpDate: tripDetails.pickUpDate,
        dropOffDate: tripDetails.dropOffDate,
        rentalDays: tripDetails.rentalDays,
        driverAge: tripInfo?.driverAge,
        campaignCode: tripInfo?.campaignCode,
        fleetCategory: tripDetails.fleetCategory,
        secondaryProducts: tripDetails.secondaryProducts,
        insuranceProducts: tripDetails.insuranceProducts,
        mandatoryItems: [...fleetCategoryMandatoryFees, ...(tripDetails.lines?.filter((line) => !fleetCategoryMandatoryFeesIds.includes(line.productId)) || [])].sort(
            (p) => p.sortOrder || 0
        ),
        bundles: tripDetails.bundles || [],
    });
};
