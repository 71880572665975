import React from 'react';
import { JucyButton } from '../../components/Button/Button';
import { useRouteNavigate } from '../../hooks/useNavigateRoute';
import AvailabilityStore from '../../store/AvailabilityStore';
import ReservationStore from '../../store/ReservationStore';
import QuotePage from './QuotePage';

const Content = ({ activeQuote }) => {
    const navigate = useRouteNavigate();
    return (
        <>
            <h1 className="h2 pt-0 pb-2 pt-lg-3 pb-lg-5 mb-0" data-cy="page-title">
                Quote Sent
            </h1>
            <h5>Reference number: {activeQuote.ReservationDisplayReference}</h5>
            <p>Your quote has been saved and sent</p>
            <JucyButton
                variant="contained"
                onClick={() => {
                    AvailabilityStore.reset();
                    ReservationStore.reset();
                    navigate('quotes');
                }}
            >
                Back to to Manage Bookings
            </JucyButton>
        </>
    );
};
const ConfirmedEmailedPage = (props) => <QuotePage child={Content} {...props} />;
export default ConfirmedEmailedPage;
