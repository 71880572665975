import React from 'react';
import { Container, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { OBEErrorAlert } from '../../../components/Alert/OBEErrorAlert';

export const FatalError: React.FC<{ title?: string; message?: string; sx?: SxProps<Theme> }> = ({ title, message }) => (
    <Container maxWidth="md" sx={{ paddingTop: 2 }}>
        <OBEErrorAlert subTitle={<>An unexpected error has occurred: {title}</>}>
            <Typography component="div" sx={{ mt: 2 }} variant="caption">
                {message}
            </Typography>
        </OBEErrorAlert>
    </Container>
);
