import React, { useEffect } from 'react';
import config from '#/config';
import rentalTripSearchStore from '#/store/RentalTripSearchStore';
import { reaction } from 'mobx';

export const Intercom: React.FC = () => {
    const initialisedRef = React.useRef(false);
    useEffect(() => {
        if (!initialisedRef.current && !window.intercomSettings && config.intercomApiBase && config.intercomAppId) {
            initialisedRef.current = true;
            window.intercomSettings = {
                api_base: config.intercomApiBase,
                app_id: config.intercomAppId,
            };

            const w = window;
            const ic = w.Intercom;
            if (typeof ic === 'function') {
                ic('reattach_activator');
                ic('update', w.intercomSettings);
            } else {
                const i = (...args: unknown[]) => {
                    i.c(args);
                };
                i.q = [] as unknown[];
                i.c = (args: unknown) => {
                    i.q.push(args);
                };
                w.Intercom = i;
                w.addEventListener(
                    'load',
                    () => {
                        const script = document.createElement('script');
                        script.setAttribute('src', `https://widget.intercom.io/widget/${config.intercomAppId}`);
                        document.body.appendChild(script);
                    },
                    false
                );
            }

            const updateActiveRegion = (countryCode?: string) => {
                if (countryCode && window.Intercom && typeof window.Intercom === 'function') {
                    window.Intercom('update', {
                        activeRegion: countryCode,
                    });
                }
            };

            reaction(
                () => rentalTripSearchStore.pickUpLocation,
                (pickUpSite) => {
                    updateActiveRegion(pickUpSite?.CountryCode);
                }
            );
        }
    }, []);

    return null;
};
