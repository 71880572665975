import React, { useState } from 'react';
import AgentHirerDetailsFormComponent, {
    AgentHirerDetailsFormProps as AgentHirerDetailsFormComponentProps,
    AgentHirerDetailsFormValues,
} from '../../../../../components/Forms/HirerDetailsForm/AgentHirerDetailsForm';
import hireDetailsStore from '../../../../../store/HireDetailsStore';
import { BookingCart } from '../../../../../types/BookingCart';

export type AgentHirerDetailsFormProps = {
    summary: BookingCart;
    onSubmit: AgentHirerDetailsFormComponentProps['onSubmit'];
};
export const AgentHirerDetailsForm: React.FC<AgentHirerDetailsFormProps> = ({ summary, onSubmit }) => {
    const [initialValues] = useState<AgentHirerDetailsFormValues>(() => ({
        firstName: hireDetailsStore.isDefaultHirerName() ? '' : hireDetailsStore.firstName?.trim() || '',
        lastName: hireDetailsStore.isDefaultHirerName() ? '' : hireDetailsStore.lastName?.trim() || '',
        email: hireDetailsStore.isDefaultHirerEmail() ? '' : hireDetailsStore.email?.trim() || '',
        mobileNumber: hireDetailsStore.mobileNumber?.trim() || '',
        agentVoucherReference: hireDetailsStore.voucherReference?.trim() || '',
        agentName: hireDetailsStore.agentName?.trim() || '',
    }));
    return <AgentHirerDetailsFormComponent summary={summary} initialValues={initialValues} onSubmit={onSubmit} />;
};
AgentHirerDetailsForm.displayName = 'AgentHirerDetailsForm';
