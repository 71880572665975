import React from 'react';
import { Link } from 'react-router-dom';
import { AlertTitle, Button, Typography } from '@mui/material';
import { JucyAlert } from '../../../../components/Alert/JucyAlert';
import { useAccountInfo, useEditReservationHref } from '../../../../hooks';
import accountKeyStore from '../../../../store/AccountKeyStore';
import { BookingCart } from '../../../../types/BookingCart';

export const EditMinPeriodBookingAlert: React.FC<{
    summary: BookingCart;
}> = ({ summary }) => {
    const { accountInfo } = useAccountInfo(accountKeyStore.accountKey);
    const editLink = useEditReservationHref(summary);
    return (
        <JucyAlert color="primary" variant="outlined" severity="info">
            <AlertTitle>Booking change deadline passed</AlertTitle>
            <Typography variant="body2" gutterBottom={true}>
                We're sorry, but you're no longer able to make online changes to your
                <br />
                booking <strong>{summary.reference}</strong>
            </Typography>
            <Typography variant="body2" gutterBottom={true}>
                For smooth processing, changes can't be made within {accountInfo?.settings?.cancellationMinimum} hours of pickup.
            </Typography>
            <Typography variant="body2">
                If you need to make any changes to your booking, please contact our support team
                <br />
                directly, and we'll do our best to assist you. Please be aware that fees may apply.
            </Typography>
            <Button color="primary" variant="outlined" size="small" component={Link} to={editLink}>
                Contact us
            </Button>
        </JucyAlert>
    );
};
