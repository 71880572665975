import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { JucyErrorAlert } from '../components/Alert/JucyErrorAlert';
import { PageContainer } from '../containers/PageContainer';

const PageNotFound = () => {
    const location = useLocation();
    return (
        <PageContainer>
            <div>
                <JucyErrorAlert title="404 Whoops page not found" sx={{ display: 'inline-flex' }}>
                    Sorry the page <strong>{location.pathname}</strong> cannot be found
                    <br />
                    <br />
                    Return to the <Link to="/">Search Page</Link>
                </JucyErrorAlert>
            </div>
        </PageContainer>
    );
};

export default PageNotFound;
