import { useCallback } from 'react';
import sitesStore from '#/store/SitesStore';
import { formatISO9075 } from 'date-fns';
import { runInAction } from 'mobx';
import { validateSearchValues } from '../../../components/Forms/TripSearchForm/SearchForm';
import { useUpdateRouteParams } from '../../../hooks';
import Events from '../../../lib/Events';
import FleetCategoryAvailability from '../../../lib/api/model/FleetCategoryAvailability';
import { setTime } from '../../../lib/dates';
import { stripEmptyProps } from '../../../lib/stripEmptyProps';
import { FleetType } from '../../../services';
import availabilityStore from '../../../store/AvailabilityStore';
import FleetTypesStore from '../../../store/FleetTypesStore';
import ProductStore from '../../../store/ProductStore';
import rentalTripSearchStore from '../../../store/RentalTripSearchStore';
import reservationStore from '../../../store/ReservationStore';
import createTripStore from '../../../store/TripStore';
import tripStore from '../../../store/TripStore';
import { UserMode } from '../../../types/UserMode';
import { TripTabPanelProps } from '../components';

export const useHandleOnSearchSubmitted = ({ userMode }: { userMode?: UserMode }): TripTabPanelProps['onSearchSubmit'] => {
    const updateRoute = useUpdateRouteParams();
    return useCallback(
        async (values, form) => {
            form.clearErrors();
            const activePickUpLocation = tripStore.tripInfo?.pickUpLocation && sitesStore.getSiteByCode(tripStore.tripInfo.pickUpLocation);
            if (values.pickUpLocation && activePickUpLocation && values.pickUpLocation.CountryCode !== activePickUpLocation.CountryCode) {
                runInAction(() => {
                    tripStore.action = 'create';
                });
            }
            if (tripStore.action === 'create') {
                runInAction(() => {
                    tripStore.booking = undefined;
                    rentalTripSearchStore.state = 'done';
                    reservationStore.activeQuote = undefined;
                });
            }
            if (validateSearchValues(values)) {
                rentalTripSearchStore.setValues(values);
                if (tripStore.booking?.reservationReference && tripStore.summary && tripStore.action === 'edit') {
                    const searchResponse = await rentalTripSearchStore.performSearchV3(tripStore.booking.reservationReference);
                    if (searchResponse) {
                        const fleetCategories = searchResponse.fleetCategories.map((f) =>
                            FleetCategoryAvailability.formV3Api({
                                apiFleetCategory: f,
                                fleetType: FleetTypesStore.getFleetTypeBySlug(f.fleetTypeCode) as FleetType,
                                catalogData: ProductStore.getProductByCode(f.categoryCode, rentalTripSearchStore.pickUpLocation?.CountryCode),
                            })
                        );
                        const activeFleetCategory = fleetCategories.find((f) => f.CategoryCode === tripStore.booking?.fleetCategory.categoryCode) as FleetCategoryAvailability;
                        availabilityStore.setFleetCategories(fleetCategories);
                        availabilityStore.setSelectedFleetCategory(activeFleetCategory);
                    }
                } else {
                    Events.trackBookingInitiated(values);
                    availabilityStore.selectedFleetCategory = undefined;
                    await rentalTripSearchStore.performSearch();
                    createTripStore.tripInfo = availabilityStore.asTripInfoRequest();
                }
                const routeParams = {
                    activeTab: userMode === 'agent' ? 'results' : undefined,
                    fleetTypeOrResRef: tripStore.action === 'edit' ? tripStore.booking?.reservationReference : values.fleetType.slug,
                };
                updateRoute(routeParams, {
                    keepSearch: true,
                    search: stripEmptyProps({
                        pickUpLocation: values.pickUpLocation.SiteCode,
                        dropOffLocation: values.dropOffLocation.SiteCode,
                        pickUpDateTime: formatISO9075(setTime(values.pickUpDate, values.pickUpTime)),
                        dropOffDateTime: formatISO9075(setTime(values.dropOffDate, values.dropOffTime)),
                        driverAge: values.driverAge,
                        campaignCode: values.promoCode,
                    }),
                });
            }
        },
        [updateRoute, userMode]
    );
};
