import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { JucyButton, JucyButtonProps } from '../Button/Button';

const ReviewActions: React.FC<{ children?: React.ReactNode; btnProps: JucyButtonProps; btnLabel: string }> = ({ children, btnProps, btnLabel }) => (
    <Stack direction="row" spacing={2} p={2}>
        <Box>
            <Typography lineHeight={1} variant="caption">
                {children}
            </Typography>
        </Box>
        <Box
            sx={{
                justifyContent: 'end',
                display: 'flex',
                alignItems: 'center',
                flex: 1,
            }}
        >
            <JucyButton variant="contained" {...btnProps}>
                {btnLabel}
            </JucyButton>
        </Box>
    </Stack>
);

export default ReviewActions;
