import React, { useEffect } from 'react';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';

const BreakFrame: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    /* The top frame is at "{origin}/__/" when running in Cypress */
    const isInFrame = !/__/.test(window.parent.location.pathname) && window.parent.location !== window.location;

    useEffect(() => {
        const parsedUrl = new URL(window.location.href);
        const isCallback = Boolean(parsedUrl.searchParams.get('callback'));
        if (isInFrame || isCallback) {
            if (isCallback) {
                parsedUrl.searchParams.delete('callback');
            }
            window.parent.location = parsedUrl.href;
        }
    }, [isInFrame]);
    if (isInFrame) {
        return (
            <div className="initial-loader-container">
                <LoadingAnimation variant="primary" className="animate__animated animate__fadeIn" />
            </div>
        );
    }
    return <>{children}</>;
};

export default BreakFrame;
