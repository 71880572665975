import { useCallback } from 'react';
import { runInAction } from 'mobx';
import { useRentalsApi } from '../../../hooks';
import FleetCategoryAvailability from '../../../lib/api/model/FleetCategoryAvailability';
import { FleetType } from '../../../services';
import availabilityStore from '../../../store/AvailabilityStore';
import fleetTypesStore from '../../../store/FleetTypesStore';
import hireDetailsStore from '../../../store/HireDetailsStore';
import productStore from '../../../store/ProductStore';
import rentalTripSearchStore from '../../../store/RentalTripSearchStore';
import reservationStore from '../../../store/ReservationStore';
import { summaryFromBooking } from '../../../store/Summary/summaryFromBooking';
import tripStore from '../../../store/TripStore';
import { BookingCart } from '../../../types/BookingCart';

export const useInitStoresByReservationRef = () => {
    const rentalsApiClient = useRentalsApi();
    return useCallback(
        async (reservationRef: string) => {
            reservationStore.reset();
            tripStore.state = 'loading';
            const { booking, options } = await rentalsApiClient.fetchBookingAndOptions(reservationRef);
            if (booking && options) {
                runInAction(() => {
                    tripStore.action = 'edit';
                    rentalTripSearchStore.action = 'edit';
                    tripStore.booking = booking;
                    tripStore.options = options;

                    hireDetailsStore.agentName = booking.agentName;
                    hireDetailsStore.voucherReference = booking.voucherReference;
                    if (booking.hirerDetails) {
                        hireDetailsStore.firstName = booking.hirerDetails.firstName;
                        hireDetailsStore.lastName = booking.hirerDetails.lastName;
                        hireDetailsStore.mobileNumber = booking.hirerDetails.mobileNumber;
                        hireDetailsStore.email = booking.hirerDetails.email;
                        hireDetailsStore.mailingList = Boolean(booking.hirerDetails.mailingList);
                        hireDetailsStore.driversLicenceCountry = booking.hirerDetails.driversLicence?.country || '';
                    }
                    tripStore.summary = summaryFromBooking({
                        booking: booking,
                        options: options,
                        userMode: tripStore.userMode,
                        action: tripStore.action,
                        bookingStatus: booking.status as BookingCart['bookingStatus'],
                    });
                });
                const bookingFleetCategoryAvailability = FleetCategoryAvailability.formV3Api({
                    apiFleetCategory: booking.fleetCategory,
                    fleetType: fleetTypesStore.getFleetTypeBySlug(booking.fleetCategory.fleetTypeCode) as FleetType,
                    catalogData: productStore.getProductByCode(booking.fleetCategory.categoryCode, rentalTripSearchStore.pickUpLocation?.CountryCode),
                });
                tripStore.state = 'done';
                availabilityStore.setFleetCategories([bookingFleetCategoryAvailability]);
                rentalTripSearchStore.setValuesFromBooking(booking);
                rentalTripSearchStore.performSearchV3(booking.reservationReference).then(() => {
                    availabilityStore.setSelectedFleetCategory(availabilityStore.fleetCategories.find((fc) => fc.CategoryCode === booking.fleetCategory.code) as FleetCategoryAvailability);
                    rentalTripSearchStore.state = 'done';
                });
            }
        },
        [rentalsApiClient]
    );
};
