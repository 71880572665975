import { Elements } from '@stripe/react-stripe-js';
import React, { useMemo } from 'react';
import { JucyCountryCode } from '@jucy/rentals-common';
import { useTheme } from '@mui/material';
import { Appearance, loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

const stripe =
    process.env.NEXT_PUBLIC_STRIPE_AU_API_KEY && process.env.NEXT_PUBLIC_STRIPE_NZ_API_KEY
        ? {
              au: loadStripe(process.env.NEXT_PUBLIC_STRIPE_AU_API_KEY as string),
              nz: loadStripe(process.env.NEXT_PUBLIC_STRIPE_NZ_API_KEY as string),
          }
        : undefined;

const useDefaultStripeElementsOptions = (options: StripeElementsOptions) => {
    const theme = useTheme();

    return useMemo(
        () => ({
            appearance: {
                theme: 'stripe' as Appearance['theme'],
                variables: {
                    colorPrimary: theme.palette.primary.main,
                    colorBackground: theme.palette.common.white,
                    colorText: theme.palette.text.primary,
                    colorDanger: theme.palette.warning.main,
                    fontFamily: 'Poppins, system-ui, sans-serif',
                    borderRadius: '4px',
                    fontSizeBase: '16px',
                    fontWeightLight: theme.typography.fontWeightLight,
                    fontWeightNormal: theme.typography.fontWeightRegular,
                    fontWeightMedium: theme.typography.fontWeightMedium,
                    fontWeightBold: theme.typography.fontWeightBold,
                    spacingUnit: '5.5',
                    fontLineHeight: theme.typography.body1.lineHeight,
                } as Appearance['variables'],
                rules: {
                    '.Input': {
                        padding: theme.spacing(2.0625, 1.75),
                        border: 'none',
                        transition: 'none',
                        boxShadow: 'none',
                        outline: `1px solid ${theme.palette.grey[400]}`,
                    },

                    '.Input:focus': {
                        border: 'none',
                        transition: 'none',
                        boxShadow: 'none',
                        outline: `2px solid ${theme.palette.primary.main}`,
                    },
                    // '.Input:hover': {
                    //     border: 'none',
                    //     transition: 'none',
                    //     boxShadow: 'none',
                    //     outline: `1px solid ${theme.palette.grey[900]}`,
                    // },
                    // '.Input:hover.Input:focus': {
                    //     border: 'none',
                    //     transition: 'none',
                    //     boxShadow: 'none',
                    //     outline: `2px solid ${theme.palette.primary.main}`,
                    // },
                },
            },
            fonts: [
                {
                    cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap',
                },
            ],
            ...options,
        }),
        [theme, options]
    );
};
export const StripeProvider: React.FC<{ options: StripeElementsOptions; children: React.ReactNode; countryCode: JucyCountryCode }> = ({ countryCode, options: passedOptions, children }) => {
    const options = useDefaultStripeElementsOptions(passedOptions);
    if (!stripe?.[countryCode]) {
        throw new Error(`StripeProvider: No stripe instance for country code ${countryCode}`);
    }
    return (
        <Elements options={options} stripe={stripe[countryCode]}>
            {children}
        </Elements>
    );
};
