import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { MinimumHirePeriod } from '@jucy/rentals-api-client/rentals-api-v3';
import { JucyCountryCode } from '@jucy/rentals-common';
import { Box, Button, Grid, Typography } from '@mui/material';
import { runInAction } from 'mobx';
import { useRentalsApi } from '../../hooks';
import { JucyErrorAlert } from '../Alert/JucyErrorAlert';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import FleetCategoryMinHireTable from './FleetCategoryMinHireTable';
import { HirePeriodsStore } from './HirePeriodsStore';

export interface HirePeriodsTableLoaderProps {
    region: JucyCountryCode;
}

const FleetCategoryMinHireTableLoader: React.FC<HirePeriodsTableLoaderProps> = observer(({ region }) => {
    const [hirePeriodsStore] = useState(new HirePeriodsStore({ region }));
    const rentalsApiClient = useRentalsApi();
    const { error, data, isLoading } = useQuery<MinimumHirePeriod[], Error>({
        queryKey: [`get-hire-periods-${region}`],
        queryFn: () => rentalsApiClient.v3.data.getMinimumHirePeriods(undefined, undefined, undefined, undefined, region),
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
    useEffect(() => {
        if (data && !hirePeriodsStore.minimumHirePeriods.length) {
            runInAction(() => (hirePeriodsStore.minimumHirePeriods = data));
        }
    }, [data, hirePeriodsStore, region]);
    if (error) {
        return (
            <div className="pt-4 animate__animated animate__fadeIn">
                <JucyErrorAlert title="Whoops failed to load minimum hire periods" message={error?.message || 'An unexpected error has occurred'}></JucyErrorAlert>
            </div>
        );
    }
    if (isLoading) {
        return (
            <div className="w-100 d-flex justify-content-center pt-4 animate__animated animate__fadeIn">
                <LoadingAnimation variant="primary" className="animate__animated animate__fadeIn" />
            </div>
        );
    }
    const downloadXlsxURL = `/api/v3/data/minimum-hire-periods?region=${region}&format=xlsx&_=${Date.now()}`;
    const downloadCsvURL = `/api/v3/data/minimum-hire-periods?region=${region}&format=csv&_=${Date.now()}`;
    return (
        <Box sx={{ pt: 2 }} className="animate__animated animate__fadeIn">
            <Button component={'a'} href={new URL(downloadXlsxURL, rentalsApiClient.config.basePath).toString()} variant="outlined" color="primary">
                Download XLSX
            </Button>{' '}
            <Button variant="outlined" href={new URL(downloadCsvURL, rentalsApiClient.config.basePath).toString()} color="primary">
                Download CSV
            </Button>
            <Grid sx={{ mt: 2 }} container spacing={2}>
                {hirePeriodsStore.fleetTypeMinimumHirePeriods.map((row) => (
                    <Grid xs={6} item key={row.fleetType.slug}>
                        <Typography variant="h5" textAlign="center" mt={0} mb={0}>
                            {row.fleetType.name}
                        </Typography>
                        {row.fleetCategories.map((fleetCategory) => (
                            <FleetCategoryMinHireTable key={fleetCategory.code} minimumHirePeriods={fleetCategory} />
                        ))}
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
});
export default FleetCategoryMinHireTableLoader;
