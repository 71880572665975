import initStores from '../../store/initStores';
import { useEffect } from 'react';
import { useRouteNavigate, useTripApi } from '#/hooks';
import { useRouteMode } from '#/hooks/useRouteMode';
import FleetTypesStore from '../../store/FleetTypesStore';

/**
 * @return {null}
 */
const LandingPage = () => {
    const navigate = useRouteNavigate();
    const mode = useRouteMode();
    const tripEnabled = useTripApi();
    useEffect(() => {
        initStores();
        navigate(tripEnabled ? 'create-trip' : 'create-quote', {
            params: {
                mode: mode || 'agent',
                fleetType: FleetTypesStore.getDefault().slug,
                action: 'create',
            },
            replace: true,
            keepSearch: true,
        });
    });
    return null;
};

export default LandingPage;
