import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { SearchForm } from '../../../../../components/Forms/TripSearchForm/SearchForm';
import { SearchFormContainerProps } from '../../../../../components/Forms/TripSearchForm/SearchFormContainerProps';
import { PartialSearchFormValues } from '../../../../../components/Forms/TripSearchForm/SearchFormValues';
import { useSearchParams } from '../../../../../hooks';
import fleetTypesStore from '../../../../../store/FleetTypesStore';
import { BookingCart } from '../../../../../types/BookingCart';
import { useHandleFleetTypeChanged } from '../../../hooks/useHandleFleetTypeChanged';

export interface TripSearchFromProps {
    onSubmit: SearchFormContainerProps['onSubmit'];
    summary?: BookingCart;
    defaultFleetTypeSlug?: string;
}

export const TripSearchFrom: React.FC<TripSearchFromProps> = observer(({ onSubmit, defaultFleetTypeSlug, summary }) => {
    const [searchParams] = useSearchParams<Record<string, string>>();
    const handleFleetTypeChanged = useHandleFleetTypeChanged();
    const initialValues = useMemo(() => {
        const defaults: PartialSearchFormValues = {
            pickUpLocation: undefined,
            pickUpDate: undefined,
            pickUpTime: undefined,
            dropOffLocation: undefined,
            dropOffDate: undefined,
            dropOffTime: undefined,
            fleetCategoryCode: undefined,
            promoCode: '',
            togglePromoCode: false,
            driverAge: '21',
            fleetType: fleetTypesStore.getActiveFleetType() || fleetTypesStore.getFleetTypeBySlug(defaultFleetTypeSlug) || undefined,
        };
        return (
            summary?.getSearchFormInitialValues({
                defaults,
                searchParams,
            }) || defaults
        );
    }, [defaultFleetTypeSlug, searchParams, summary]);
    return (
        <>
            <SearchForm initialValues={initialValues} className="booking-widget large" onSubmit={onSubmit} summary={summary} onFleetTypeChanged={handleFleetTypeChanged} />
        </>
    );
});
TripSearchFrom.displayName = 'TripSearchFrom';
