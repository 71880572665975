import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { AlertProps, AlertTitle, Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { JucyAlert, JucyAlertColors } from '../components/Alert/JucyAlert';
import { JucyButton } from '../components/Button/Button';
import { PageContainer } from '../containers/PageContainer';

const alertIcons = [undefined, false];
const alertColors: JucyAlertColors[] = ['primary', 'secondary', 'success', 'info', 'warning', 'error'];
const alertVariants: AlertProps['variant'][] = ['standard', 'filled', 'outlined'];
const alertSeverity: AlertProps['severity'][] = ['success', 'info', 'warning', 'error'];

const ExamplePage: React.FC = () => {
    useEffect(() => {
        toast.info('Info message', { autoClose: 1200000 });
    }, []);
    return (
        <PageContainer>
            <Stack sx={{ mt: 2, flex: '1 1' }} spacing={2}>
                <Card>
                    <CardHeader title="Alerts" />
                    <CardContent>
                        <JucyButton color="primary" variant="contained" onClick={() => toast.success('Success message', { autoClose: false })}>
                            Toast success
                        </JucyButton>
                        <JucyButton color="primary" variant="contained" onClick={() => toast.info('Info message')}>
                            Toast info
                        </JucyButton>
                        <JucyButton color="primary" variant="contained" onClick={() => toast.warning('Warning message')}>
                            Toast warning
                        </JucyButton>
                        <JucyButton color="primary" variant="contained" onClick={() => toast.error('Error message')}>
                            Toast error
                        </JucyButton>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader title="Alerts" />
                    <CardContent>
                        <Stack sx={{ mt: 2 }} spacing={1}>
                            {alertIcons.map((icon) =>
                                alertColors.map((color) =>
                                    alertVariants.map((variant) =>
                                        alertSeverity.map((severity) => (
                                            <JucyAlert
                                                key={`${variant}-${color}-${icon}-${severity}`}
                                                icon={icon}
                                                variant={variant}
                                                color={color}
                                                severity={severity}
                                                className="animate__animated animate__fadeIn"
                                            >
                                                <AlertTitle>
                                                    {icon === undefined ? 'Icon alert' : 'Alert'} {variant} {color}{' '}
                                                </AlertTitle>
                                                color: {color}
                                                <br />
                                                variant: {variant}
                                                <br />
                                                severity: {severity}
                                                <br />
                                            </JucyAlert>
                                        ))
                                    )
                                )
                            )}
                        </Stack>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader title="Typography" />
                    <CardContent>
                        <Typography variant="h1">h1</Typography>
                        <Typography variant="h2" gutterBottom>
                            h2 gutterBottom
                        </Typography>
                        <Typography variant="h3" align="right">
                            h3 align right
                        </Typography>
                        <Typography variant="h4" align="center">
                            h4 align center
                        </Typography>
                        <Typography variant="h5" color="primary">
                            h5 color primary
                        </Typography>
                        <Typography variant="h6">h6</Typography>
                        <Typography variant="subtitle1" display="inline">
                            subtitle1 display inline
                        </Typography>
                        <Typography variant="subtitle2" display="inline">
                            subtitle2 display inline
                        </Typography>
                        <Typography variant="body1">body1 (16px)</Typography>
                        <Typography variant="body2">body2 (14px)</Typography>
                        <Typography variant="button">button text</Typography>
                        <Typography variant="caption" display="block">
                            line break caption text
                        </Typography>
                        <Typography variant="overline" display="block">
                            overline text
                        </Typography>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader title="Buttons" />
                    <CardContent>
                        jucy charcoal{' '}
                        <Stack spacing={2} direction="row">
                            <JucyButton color="charcoal" variant="text">
                                Text
                            </JucyButton>
                            <Button color="charcoal" variant="contained">
                                Contained
                            </Button>
                            <Button color="charcoal" variant="outlined">
                                Outlined
                            </Button>
                        </Stack>
                        jucy light{' '}
                        <Stack spacing={2} direction="row">
                            <JucyButton color="light" variant="text">
                                Text
                            </JucyButton>
                            <Button color="light" variant="contained">
                                Contained
                            </Button>
                            <Button color="light" variant="outlined">
                                Outlined
                            </Button>
                        </Stack>
                        charcoal{' '}
                        <Stack spacing={2} direction="row">
                            <Button color="charcoal" variant="text">
                                Text
                            </Button>
                            <Button color="charcoal" variant="contained">
                                Contained
                            </Button>
                            <Button color="charcoal" variant="outlined">
                                Outlined
                            </Button>
                        </Stack>
                        light{' '}
                        <Stack spacing={2} direction="row">
                            <Button color="light" variant="text">
                                Text
                            </Button>
                            <Button color="light" variant="contained">
                                Contained
                            </Button>
                            <Button color="light" variant="outlined">
                                Outlined
                            </Button>
                        </Stack>
                        primary{' '}
                        <Stack spacing={2} direction="row">
                            <Button color="primary" variant="text">
                                Text
                            </Button>
                            <Button color="primary" variant="contained">
                                Contained
                            </Button>
                            <Button color="primary" variant="outlined">
                                Outlined
                            </Button>
                        </Stack>
                        success{' '}
                        <Stack spacing={2} direction="row">
                            <Button color="success" variant="text">
                                Text
                            </Button>
                            <Button color="success" variant="contained">
                                Contained
                            </Button>
                            <Button color="success" variant="outlined">
                                Outlined
                            </Button>
                        </Stack>
                        error{' '}
                        <Stack spacing={2} direction="row">
                            <Button color="error" variant="text">
                                Text
                            </Button>
                            <Button color="error" variant="contained">
                                Contained
                            </Button>
                            <Button color="error" variant="outlined">
                                Outlined
                            </Button>
                        </Stack>
                        info{' '}
                        <Stack spacing={2} direction="row">
                            <Button color="info" variant="text">
                                Text
                            </Button>
                            <Button color="info" variant="contained">
                                Contained
                            </Button>
                            <Button color="info" variant="outlined">
                                Outlined
                            </Button>
                        </Stack>
                        inherit{' '}
                        <Stack spacing={2} direction="row">
                            <Button color="inherit" variant="text">
                                Text
                            </Button>
                            <Button color="inherit" variant="contained">
                                Contained
                            </Button>
                            <Button color="inherit" variant="outlined">
                                Outlined
                            </Button>
                        </Stack>
                        secondary{' '}
                        <Stack spacing={2} direction="row">
                            <Button color="secondary" variant="text">
                                Text
                            </Button>
                            <Button color="secondary" variant="contained">
                                Contained
                            </Button>
                            <Button color="secondary" variant="outlined">
                                Outlined
                            </Button>
                        </Stack>
                        warning{' '}
                        <Stack spacing={2} direction="row">
                            <Button color="warning" variant="text">
                                Text
                            </Button>
                            <Button color="warning" variant="contained">
                                Contained
                            </Button>
                            <Button color="warning" variant="outlined">
                                Outlined
                            </Button>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
        </PageContainer>
    );
};
export default ExamplePage;
