import { useRouteMatch } from '#/hooks';
import { useAuth } from '#/hooks/useAuth';
import routeRegistry, { RegistryRoute } from '#/store/RouteRegistry';
import { OBEUser } from '#/types/OBEUser';
import { userModes } from '#/types/UserMode';
import { useMediaQuery, useTheme } from '@mui/material';
import { autorun, toJS } from 'mobx';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

const parsePath = (path: string, user: OBEUser) => path.replace(':mode', user?.type || '');

export const useMainNav = () => {
    const { user } = useAuth();
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('md'));
    const [menuItems, setMenuItems] = React.useState<RegistryRoute[]>([]);
    const location = useLocation();

    useEffect(() => {
        autorun(() => {
            setMenuItems(
                routeRegistry.routes
                    .filter((r) => {
                        if (!r.showInMenu) {
                            return false;
                        }
                        if (!r.public && !user?.isAuthenticated) {
                            return false;
                        }
                        return !(r.userTypes && !r.userTypes.includes(user?.type));
                    })
                    .map((r) => {
                        const data = toJS(r);
                        return {
                            ...data,
                            path: parsePath(data.path, user),
                        };
                    })
            );
        });
    }, [user, user?.isAuthenticated, user.type]);

    const tabValues = useMemo(() => {
        const result = menuItems.map((r) => parsePath(r.path, user));
        if (user?.type === 'agent') {
            result.push('/hire-periods');
        }
        return result;
    }, [menuItems, user]);
    let currentPage = useRouteMatch(tabValues) || undefined;
    const authPathRegEx = new RegExp(`^/(${userModes.join('|')})`);
    for (const tabValue of tabValues) {
        if (location.pathname.replace(authPathRegEx, '').startsWith(tabValue.replace(authPathRegEx, ''))) {
            currentPage = tabValue;
            break;
        }
    }
    return useMemo(
        () => ({
            enabled: user?.isAuthenticated && !isSm,
            currentPage,
            menuItems,
        }),
        [currentPage, user, isSm, menuItems]
    );
};
