import { Elements } from '@stripe/react-stripe-js';
import React, { useMemo } from 'react';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { JucyCountryCode } from '@jucy/rentals-common';
import config from '#/config';

const stripe =
    config.stripe?.au.apiKey && config.stripe?.nz.apiKey
        ? {
              au: loadStripe(config.stripe?.au.apiKey),
              nz: loadStripe(config.stripe?.nz.apiKey),
          }
        : undefined;
const optionDefaults: StripeElementsOptions = {
    appearance: {
        theme: 'stripe',
    },
};

export const StripeProvider: React.FC<{ options: StripeElementsOptions; children: React.ReactNode; countryCode: JucyCountryCode }> = ({ countryCode, options: passedOptions, children }) => {
    const options = useMemo(
        () =>
            ({
                ...optionDefaults,
                ...passedOptions,
            }) as StripeElementsOptions,
        [passedOptions]
    );
    if (!stripe?.[countryCode]) {
        throw new Error(`StripeProvider: No stripe instance for country code ${countryCode}`);
    }
    return (
        <Elements options={options} stripe={stripe[countryCode]}>
            {children}
        </Elements>
    );
};
