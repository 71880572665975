import { MinimumHirePeriod } from '@jucy/rentals-api-client/rentals-api-v3';
import { FleetCategoryMap, FleetTypeMap, JucyCountryCode, branches, fleetCategories, fleetTypes } from '@jucy/rentals-common';
import { format } from 'date-fns';
import { computed, makeAutoObservable, reaction } from 'mobx';

export type MinimumHirePeriodRange = {
    pickUpStartDate: Date;
    pickUpEndDate: Date;
};

export type PivotedMinimumHirePeriod = {
    locationCodes: string;
} & Record<string, string>;

export type PivotedMinimumHirePeriodGroup = {
    fleetType: FleetTypeMap;
    fleetCategory: FleetCategoryMap;
    minimumHirePeriods: PivotedMinimumHirePeriod[];
    dateRanges: MinimumHirePeriodRange[];
};
export type FleetCategoryMinimumHirePeriods = FleetCategoryMap & {
    fleetType: FleetTypeMap;
    minimumHirePeriods: PivotedMinimumHirePeriod[];
    dateRanges: MinimumHirePeriodRange[];
};

export type FleetTypeMinimumHirePeriods = {
    fleetType: FleetTypeMap;
    fleetCategories: FleetCategoryMinimumHirePeriods[];
};

export class HirePeriodsStore {
    minimumHirePeriods: MinimumHirePeriod[] = [];
    minimumHirePeriodByDateGroup: PivotedMinimumHirePeriodGroup[] = [];

    constructor({ region }: { region: JucyCountryCode }) {
        makeAutoObservable(this);
        reaction(
            () => this.activeMinimumHirePeriods,
            (activeMinimumHirePeriods) => {
                if (activeMinimumHirePeriods?.length) {
                    for (const fleetType of fleetTypes) {
                        for (const fleetCategory of fleetCategories.filter((f) => f.fleetTypeId === fleetType.id)) {
                            const minHirePeriods: PivotedMinimumHirePeriod[] = [];
                            const dateRanges: MinimumHirePeriodRange[] = [];
                            for (const pickUpBranch of branches.filter((b) => b.countryCode === region)) {
                                const minimumHirePeriods = activeMinimumHirePeriods.filter(
                                    (hirePeriod) => hirePeriod.pickUpLocationCode === pickUpBranch.code && hirePeriod.fleetCategoryCode === fleetCategory.code
                                );
                                if (minimumHirePeriods.length) {
                                    for (const dropOffBranch of branches.filter((b) => b.countryCode === region)) {
                                        const row: PivotedMinimumHirePeriod = {
                                            locationCodes: `${pickUpBranch.code}${dropOffBranch.code}`,
                                        };
                                        for (const minimumHirePeriod of minimumHirePeriods.filter((hp) => hp.dropOffLocationCode === dropOffBranch.code)) {
                                            if (
                                                !dateRanges.find(
                                                    (d) =>
                                                        d.pickUpStartDate.getTime() === minimumHirePeriod.pickUpStartDate.getTime() &&
                                                        d.pickUpEndDate.getTime() === minimumHirePeriod.pickUpEndDate.getTime()
                                                )
                                            ) {
                                                dateRanges.push({
                                                    pickUpStartDate: minimumHirePeriod.pickUpStartDate,
                                                    pickUpEndDate: minimumHirePeriod.pickUpEndDate,
                                                });
                                            }
                                            row[HirePeriodsStore.formatRangeKey(minimumHirePeriod)] = `${minimumHirePeriod.minDays}`;
                                        }
                                        if (Object.keys(row).length > 1) {
                                            minHirePeriods.push(row);
                                        }
                                    }
                                }
                            }
                            if (minHirePeriods.length) {
                                this.minimumHirePeriodByDateGroup.push({
                                    fleetType: fleetType,
                                    fleetCategory: fleetCategory,
                                    dateRanges: Array.from(dateRanges),
                                    minimumHirePeriods: minHirePeriods,
                                } as PivotedMinimumHirePeriodGroup);
                            }
                        }
                    }
                }
            }
        );
    }

    @computed get activeMinimumHirePeriods(): Required<MinimumHirePeriod>[] {
        return (this.minimumHirePeriods.filter((h) => h.pickUpStartDate && h.pickUpEndDate && h.minDays) as Required<MinimumHirePeriod>[]) || [];
    }

    @computed get fleetTypeMinimumHirePeriods(): FleetTypeMinimumHirePeriods[] {
        const result: FleetTypeMinimumHirePeriods[] = [];
        for (const fleetType of fleetTypes) {
            for (const fleetCategory of fleetCategories.filter((f) => f.fleetTypeId === fleetType.id)) {
                const minimumHirePeriod = this.minimumHirePeriodByDateGroup.find((d) => d.fleetCategory.code == fleetCategory.code);
                if (minimumHirePeriod) {
                    const existing = result.find((r) => r.fleetType.slug === fleetType.slug);
                    if (existing) {
                        existing.fleetCategories.push({ ...fleetCategory, ...minimumHirePeriod } as unknown as FleetCategoryMinimumHirePeriods);
                    } else {
                        result.push({
                            fleetType: fleetType,
                            fleetCategories: [{ ...fleetCategory, ...minimumHirePeriod } as unknown as FleetCategoryMinimumHirePeriods],
                        });
                    }
                }
            }
        }
        return result;
    }

    static formatRange(range: MinimumHirePeriodRange): string {
        const start = format(range.pickUpStartDate, 'dd MMM yyyy');
        const end = format(range.pickUpEndDate, 'dd MMM yyyy');
        return `${start} - ${end}`;
    }

    static formatRangeKey(range: MinimumHirePeriodRange): string {
        return `${range.pickUpStartDate.toLocaleDateString()}${range.pickUpEndDate.toLocaleDateString()}`;
    }
}
