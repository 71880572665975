import { LinkAuthenticationElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { FormEvent, useMemo, useState } from 'react';
import { ZoomSpinner } from '#/components/ZoomSpinner';
import { Alert, Box, Button, Fade, LinearProgress, Stack } from '@mui/material';
import { ConfirmPaymentData, PaymentIntent, SetupIntent, StripeError, StripePaymentElementOptions, TermsOption } from '@stripe/stripe-js';
import { useHandleConfirmPayment } from './useHandleConfirmPayment';
import { jucyPayStripeUtils } from '#/services/jucyPayStripeUtils';
import { useBrand } from '#/store/BrandStore';
import ErrorReporter from '#/lib/ErrorReporter';

export type StripePaymentFormProps = {
    email?: string;
    onEmailChange?: (email: string) => void;
    onError?: (error: StripeError) => void;
    onComplete?: (paymentIntent: PaymentIntent | SetupIntent) => void;
    paymentElementOptions?: StripePaymentElementOptions;
    title?: React.ReactNode;
    confirmParams: ConfirmPaymentData;
    submitLabel?: string;
    mode?: 'storeCard' | 'purchase';
};

const termProps = ['applePay', 'auBecsDebit', 'bancontact', 'card', 'cashapp', 'googlePay', 'ideal', 'paypal', 'sepaDebit', 'sofort', 'usBankAccount'] as const;

const useDefaultStripePaymentElementOptions = ({ paymentElementOptions }: { paymentElementOptions?: StripePaymentElementOptions }) => {
    const { brand } = useBrand();
    const companyName = brand === 'jucy' ? 'JUCY Rentals' : 'Star Rentals';
    return useMemo(
        () => ({
            ...paymentElementOptions,
            business: {
                ...paymentElementOptions?.business,
                name: paymentElementOptions?.business?.name || companyName,
            },
            terms: {
                ...paymentElementOptions?.terms,
                ...termProps.reduce(
                    (acc, term) => ({
                        ...acc,
                        [term]: paymentElementOptions?.terms?.[term] || 'never',
                    }),
                    {} as TermsOption
                ),
            },
        }),
        [companyName, paymentElementOptions]
    );
};

export const StripePaymentForm: React.FC<StripePaymentFormProps> = ({ confirmParams, onError, email, onEmailChange, paymentElementOptions, submitLabel = 'Pay now', onComplete, mode }) => {
    const stripe = useStripe();
    const elements = useElements();
    const handleConfirmPayment = useHandleConfirmPayment({ mode, confirmParams });
    const [message, setMessage] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [loadError, setLoadError] = useState<string | null>(null);
    const [isReady, setIsReady] = useState(false);
    const showLoader = (!isReady || !stripe || !elements) && !loadError;
    const options = useDefaultStripePaymentElementOptions({ paymentElementOptions: paymentElementOptions });
    const handleError = (error: StripeError | Error) => {
        onError?.(error as StripeError);
        ErrorReporter.captureError(error);
        const message = ['card_error', 'validation_error', 'invalid_request_error'].includes((error as StripeError).type) ? error.message : null;
        setMessage(message || 'An unexpected error occurred.');
    };

    const handleSuccesses = (paymentIntent: PaymentIntent | SetupIntent) => {
        onComplete?.(paymentIntent);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsSubmitting(true);
        const result = await handleConfirmPayment.mutateAsync({ stripe, elements });
        if (result.error) {
            handleError(result.error);
            setIsSubmitting(false);
            return;
        }
        const intent = jucyPayStripeUtils.getIntentFromResult(result);
        if (intent) {
            handleSuccesses(intent);
            setIsSubmitting(false);
        }
    };

    return (
        <Box id="payment-form" sx={{ position: 'relative', minHeight: '20px' }}>
            <Fade in={showLoader}>
                <Box sx={(theme) => ({ position: 'absolute', width: '100%', top: theme.spacing(2) })}>
                    <LinearProgress />
                </Box>
            </Fade>
            <Fade in={!showLoader}>
                <Box>
                    <Box
                        component={LinkAuthenticationElement}
                        mb={1}
                        id="link-authentication-element"
                        options={email ? { defaultValues: { email } } : undefined}
                        onChange={onEmailChange ? (e) => onEmailChange((e as unknown as React.ChangeEvent<HTMLInputElement>).target?.value || '') : undefined}
                    />
                    <PaymentElement
                        id="payment-element"
                        options={options}
                        onLoadError={(event) => {
                            setLoadError(event.error.message as string);
                        }}
                        onReady={() => {
                            setIsReady(true);
                        }}
                        onChange={(e) => {
                            setIsValid(e.complete);
                        }}
                    />
                </Box>
            </Fade>
            {isReady && !loadError ? (
                <Stack sx={{ flex: '1 1', pt: 1 }}>
                    <Button onClick={handleSubmit} variant="contained" sx={{ mt: 2, ml: 'auto' }} disabled={!stripe || !elements || !isValid || isSubmitting}>
                        {submitLabel}
                        <ZoomSpinner show={isSubmitting} />
                    </Button>
                    {message ? (
                        <Alert sx={{ mt: 2 }} severity="error" id="payment-message">
                            {message}
                        </Alert>
                    ) : null}
                </Stack>
            ) : null}
        </Box>
    );
};
