import { ConfirmPaymentData, PaymentIntentResult, SetupIntentResult, Stripe, StripeElements } from '@stripe/stripe-js';
import { useMutation } from '@tanstack/react-query';
import { jucyPay } from '#/services/jucyPay';
import { jucyPayStripeUtils } from '#/services/jucyPayStripeUtils';
import { asError } from '#/lib/asError';

export const isSetupIntentResult = (result: PaymentIntentResult | SetupIntentResult): result is SetupIntentResult => (result as SetupIntentResult).setupIntent?.object !== 'setup_intent';

export const isPaymentIntentResult = (result: PaymentIntentResult | SetupIntentResult): result is PaymentIntentResult =>
    (result as PaymentIntentResult).paymentIntent?.object !== 'payment_intent';

type UseHandleConfirmOptions = { mode?: 'storeCard' | 'purchase'; confirmParams?: ConfirmPaymentData };
type UseHandleConfirmParams = { stripe: Stripe; elements: StripeElements };
type UseHandleConfirmPaymentResponse = PaymentIntentResult | SetupIntentResult | { error: Error };
export const useHandleConfirmPayment = (options: UseHandleConfirmOptions = { mode: 'purchase' }) =>
    useMutation({
        mutationFn: async ({ stripe, elements }: UseHandleConfirmParams): Promise<UseHandleConfirmPaymentResponse> => {
            try {
                const params = {
                    elements,
                    confirmParams: options?.confirmParams,
                    redirect: 'if_required' as const,
                };
                const result = options?.mode === 'storeCard' ? await stripe.confirmSetup(params) : await stripe.confirmPayment(params);

                if (result.error) {
                    return result;
                }

                const intent = jucyPayStripeUtils.getIntentFromResult(result);
                if (intent && options?.confirmParams?.return_url) {
                    let success = false;
                    if (jucyPayStripeUtils.isSetupIntent(intent)) {
                        const response = await jucyPay.confirmSetupIntent({
                            url: options.confirmParams.return_url,
                            setupIntent: intent,
                        });
                        success = response.result;
                    } else {
                        const response = await jucyPay.confirmPaymentIntent({
                            url: options.confirmParams.return_url,
                            paymentIntent: intent,
                        });
                        success = response.result;
                    }
                    return success ? result : { error: new Error('Failed to confirm payment') };
                }
                return result;
            } catch (error) {
                return { error: asError(error) };
            }
        },
    });
