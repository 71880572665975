import * as React from 'react';
import { Box, TableCell, TableCellProps, TableSortLabel, TableSortLabelProps } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { HeadCell } from './headCells';

export interface HeadCellProps extends TableCellProps {
    cell: HeadCell;
    onClick: TableSortLabelProps['onClick'];
    sortBy: string;
    sortDirection: 'asc' | 'desc';
}

export const TableHeadCell: React.FC<HeadCellProps> = ({ cell, onClick, sortBy, sortDirection, variant, sx, ...props }) => (
    <TableCell key={cell.id} variant="head" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }} {...props}>
        {cell.sortable ? (
            <TableSortLabel active={sortBy === cell.id} direction={sortBy === cell.id ? sortDirection : 'asc'} onClick={onClick}>
                {cell.label}
                {sortBy === cell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                        {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                ) : null}
            </TableSortLabel>
        ) : (
            cell.label
        )}
    </TableCell>
);
