import { useCallback } from 'react';
import { useParams } from 'react-router';
import { URLSearchParamsInit } from 'react-router-dom';
import { toast } from 'react-toastify';
import config from '#/config';
import { useAuth, useRentalsApi, useSearchParams, useUpdateRouteParams } from '#/hooks';
import { jucyAttribution } from '#/lib/SalesMonitoring/jucyAttribution';
import { isStarFleetType } from '#/lib/isStarFleetType';
import { mergeSearchParams } from '#/lib/mergeSearchParams';
import { validateTripInfoRequest } from '#/lib/validateTripInfoRequest';
import appState from '#/store/AppStateStore';
import appStateStore from '#/store/AppStateStore';
import RouteRegistry from '#/store/RouteRegistry';
import { summaryFromAmendmentDetails } from '#/store/Summary/summaryFromAmendmentDetails';
import { summaryFromTripDetails } from '#/store/Summary/summaryFromTripDetails';
import { TripInfoRequest } from '@jucy/rentals-api-client/rentals-api-v3';
import { format } from 'date-fns';
import { runInAction } from 'mobx';
import FleetCategoryAvailability from '../../../lib/api/model/FleetCategoryAvailability';
import availabilityStore from '../../../store/AvailabilityStore';
import rentalTripSearchStore from '../../../store/RentalTripSearchStore';
import reservationStore from '../../../store/ReservationStore';
import tripStore from '../../../store/TripStore';
import { TripTabPanelProps } from '../components';

export const useHandleOnFleetCategorySelected = (): TripTabPanelProps['onFleetCategorySelected'] => {
    const { user } = useAuth();
    const [searchParams] = useSearchParams();
    const updateRoute = useUpdateRouteParams();
    const activeParams = useParams();
    const navigateToExcessReduction = useCallback(
        (fleetCategoryCode: string) => {
            updateRoute(
                {
                    activeTab: 'excess-reduction',
                },
                {
                    keepSearch: true,
                    search: {
                        fleetCategoryCode,
                    },
                }
            );
        },
        [updateRoute]
    );
    const rentalsApiClient = useRentalsApi();

    return useCallback(
        async (fleetCategory: FleetCategoryAvailability) => {
            const currentDriverAge = Number(rentalTripSearchStore.driverAge);
            const hasTripChanged = !(
                tripStore.booking?.fleetCategory.categoryCode === fleetCategory.CategoryCode &&
                tripStore.booking?.pickUpLocation === rentalTripSearchStore.pickUpLocation?.SiteCode &&
                tripStore.booking?.dropOffLocation === rentalTripSearchStore.dropOffLocation?.SiteCode &&
                format(tripStore.booking?.pickUpDate as Date, 'yyyy-MM-dd HH:mm') === format(rentalTripSearchStore.pickUpDateTime as Date, 'yyyy-MM-dd HH:mm') &&
                format(tripStore.booking?.dropOffDate as Date, 'yyyy-MM-dd HH:mm') === format(rentalTripSearchStore.dropOffDateTime as Date, 'yyyy-MM-dd HH:mm') &&
                tripStore.booking.driverAge === currentDriverAge
            );

            if (tripStore.summary?.isEditReservation) {
                const tripInfo = availabilityStore.asTripInfoRequest() as TripInfoRequest;
                const validateResponse = await rentalsApiClient?.validateUpdateReservation({
                    reservationRef: tripStore.summary.reservationReference as string,
                    ...tripInfo,
                    fleetCategory: fleetCategory.CategoryCode,
                    insurance: tripStore.summary.amendments?.selectedExcess || tripStore.summary.selectedExcess,
                    secondaryProducts: tripStore.summary.amendments?.selectedExtras || tripStore.summary.selectedExtras,
                    hirerDetails: {
                        firstName: tripStore.summary.hirerDetails.firstName,
                        lastName: tripStore.summary.hirerDetails.lastName,
                        mobileNumber: tripStore.summary.hirerDetails.mobileNumber,
                        email: tripStore.summary.hirerDetails.email,
                        driversLicenceCountry: tripStore.summary.hirerDetails.driversLicenceCountry,
                        acceptedTerms: tripStore.summary.hirerDetails.acceptedTerms || false,
                        mailingList: tripStore.summary.hirerDetails.mailingList || false,
                    },
                });
                if (validateResponse.status === 'invalid') {
                    toast.error(`Unable to validate your selection. ${validateResponse.message}`, { autoClose: false });
                    return;
                }
                runInAction(() => {
                    if (validateResponse.updates && tripStore.summary) {
                        tripStore.summary.amendments = summaryFromAmendmentDetails({
                            amendedTripDetails: validateResponse.updates,
                            reservationReference: tripStore.summary.reservationReference,
                            reservationId: tripStore.summary.reservationId,
                            userMode: tripStore.userMode,
                            action: tripStore.action,
                        });
                        navigateToExcessReduction(validateResponse.updates.fleetCategory.categoryCode);
                    }
                });
                return;
            } else if (tripStore.summary?.insuranceProducts && !hasTripChanged) {
                navigateToExcessReduction(fleetCategory.CategoryCode);
                return;
            } else {
                window.dataLayer?.push({
                    event: 'click',
                    eventAction: 'fleet-category-selected',
                    eventCategory: 'statistics',
                    eventLabel: fleetCategory.Name,
                    eventValue: undefined,
                });
                reservationStore.reset();
                availabilityStore.setSelectedFleetCategory(fleetCategory);
                const tripInfo = availabilityStore.asTripInfoRequest();
                if (!validateTripInfoRequest(tripInfo)) {
                    return;
                }

                if (isStarFleetType(fleetCategory) && appState.brand === 'jucy' && appStateStore.mode === 'direct' && user?.type === 'direct') {
                    const routeParams = {
                        ...activeParams,
                        activeTab: 'excess-reduction',
                    };

                    const path = `obe/${RouteRegistry.getPath('create-trip', routeParams).replace(/^\//, '')}`;
                    const redirectUrl = new URL(path, config.baseUrl.star);
                    const attributionParams = jucyAttribution.getForwardUrlSearchParams();

                    redirectUrl.search = mergeSearchParams(redirectUrl.searchParams, searchParams as URLSearchParamsInit, attributionParams, {
                        utm_source: 'jucy-obe',
                    }).toString();
                    window.location.href = redirectUrl.toString();
                    return;
                }

                const tripDetails = await rentalsApiClient.getTripDetails(tripInfo);
                runInAction(() => {
                    if (tripDetails) {
                        const selectedExcess = tripStore.summary?.selectedExcess;
                        const selectedExtras = tripStore.summary?.selectedExtras;
                        tripStore.summary = summaryFromTripDetails({
                            userMode: tripStore.userMode,
                            action: tripStore.action,
                            tripDetails,
                            tripInfo,
                        });

                        if (selectedExcess?.productCode) {
                            tripStore.summary.setSelectedInsurance(selectedExcess.productCode);
                        }
                        for (const selectedExtra of selectedExtras || []) {
                            if (selectedExtra?.productCode) {
                                tripStore.summary.addSecondaryProduct(selectedExtra.productCode, selectedExtra.qty);
                            }
                        }
                    }
                });
                if (tripDetails) {
                    navigateToExcessReduction(tripDetails.fleetCategory.categoryCode);
                }
            }
        },
        [rentalsApiClient, navigateToExcessReduction, user?.type, activeParams, searchParams]
    );
};
