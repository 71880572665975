import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { JucyErrorAlert } from '#/components/Alert/JucyErrorAlert';
import CurrencyFormatter from '#/components/CurrencyFormatter/CurrencyFormatter';
import Quote from '#/lib/api/model/Quote';
import reservationStore from '#/store/ReservationStore';
import { Box, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import LoadingAnimation from '../../LoadingAnimation/LoadingAnimation';
import { Img } from '../../Img/Img';

export const ReservationsTableSummary: React.FC<{ reservationReference?: string; voucherRef?: string }> = ({ reservationReference, voucherRef }) => {
    const { isLoading, isError, data } = useQuery<Quote>({
        queryKey: [`res-summary-${reservationReference}-${voucherRef}`],
        queryFn: () => reservationStore.fetchReservation(reservationReference || '') as Promise<Quote>,
    });
    if (isLoading) {
        return (
            <Box pb={1}>
                <LoadingAnimation variant="primary" className="animate__animated animate__fadeIn" />
            </Box>
        );
    }
    if (!data?.FleetCategory || isError) {
        return <JucyErrorAlert title="Details Unavailable" message={`Sorry details for ${voucherRef} (${reservationReference}) are no longer available`} />;
    }
    return (
        <Grid container spacing={2} className="animate__animated animate__fadeIn">
            <Grid xs={3} item>
                <div className="car-image">
                    <Img
                        className="category-image"
                        src={data.FleetCategory.product.image}
                        width={264}
                        height={176}
                        style={{ maxWidth: '100%', height: 'auto' }}
                        alt={data.FleetCategory.Name}
                    />
                </div>
            </Grid>
            <Grid item xs>
                <Typography variant="subtitle2" gutterBottom={false} component="p" fontWeight="bold">
                    Product type
                </Typography>
                {data.FleetType?.name}
                <br />
                <Typography variant="subtitle2" gutterBottom={false} component="p" fontWeight="bold" sx={{ mt: 1 }}>
                    Model
                </Typography>
                {data.FleetCategory.Name}
            </Grid>
            <Grid item xs>
                <Typography variant="subtitle2" gutterBottom={false} component="p" fontWeight="bold">
                    Pick up
                </Typography>
                {data.PickUpSite?.Name}
                <br />
                {format(data.PickUpDateTime, 'd MMM yyyy, h:mm a')}
            </Grid>
            <Grid item xs>
                <Typography variant="subtitle2" gutterBottom={false} component="p" fontWeight="bold">
                    Drop off
                </Typography>
                {data.DropOffSite?.Name}
                <br />
                {format(data.DropOffDateDateTime, 'd MMM yyyy, h:mm a')}
            </Grid>
            <Grid item xs>
                <Typography variant="subtitle2" gutterBottom={false} component="p" fontWeight="bold">
                    Total
                </Typography>
                <CurrencyFormatter price={data.TotalPrice} />
            </Grid>
        </Grid>
    );
};
