import { TripInfoRequest } from '@jucy/rentals-api-client/rentals-api-v3';

export const validateTripInfoRequest = (values: Partial<TripInfoRequest> | unknown): values is TripInfoRequest =>
    Boolean(
        (values as TripInfoRequest)?.pickUpLocation &&
            (values as TripInfoRequest)?.dropOffLocation &&
            (values as TripInfoRequest)?.pickUpDate &&
            (values as TripInfoRequest)?.dropOffDate &&
            (values as TripInfoRequest)?.driverAge &&
            (values as TripInfoRequest)?.fleetCategory
    );
