import { Box, CircularProgress, CircularProgressProps, Theme, Zoom } from '@mui/material';
import React from 'react';

export const ZoomSpinner: React.FC<CircularProgressProps & { show: boolean }> = ({ size = 18, show, ...props }) => (
    <Zoom in={show}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box
                sx={{
                    ml: show ? 1 : 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: show ? `${size}px` : 0,
                    transition: (theme: Theme) =>
                        theme.transitions.create(['width', 'margin'], {
                            duration: 400,
                            easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
                            delay: 0,
                        }),
                }}
            >
                <CircularProgress sx={{ color: 'inherit' }} size={size} {...props} />
            </Box>
        </Box>
    </Zoom>
);
