import React from 'react';
import { JucyButton } from '../../components/Button/Button';
import { useRouteNavigate } from '../../hooks/useNavigateRoute';
import AvailabilityStore from '../../store/AvailabilityStore';
import ReservationStore from '../../store/ReservationStore';
import QuotePage from './QuotePage';

const Content = ({ activeQuote }) => {
    const isOnRequest = activeQuote.FleetCategory.Availability === 'OnRequest';
    const navigate = useRouteNavigate();
    const message = isOnRequest
        ? "Our crew is checking to see if a vehicle for you is available for your dates so we can get you all locked in.<br/>We'll send you and email soon."
        : 'Your booking is confirmed, thanks for booking with JUCY';
    return (
        <div className="confirmed-quote-page" data-cy="confirmed-quote-page">
            <h1 className="h2 pt-0 pb-2 pt-lg-3 pb-lg-5 mb-0" data-cy="page-title">
                {isOnRequest ? 'Vehicle requested' : 'Booking Confirmed'}
            </h1>
            <h5>Reference number: {activeQuote.ReservationDisplayReference}</h5>
            <p dangerouslySetInnerHTML={{ __html: message }} />
            <JucyButton
                variant="contained"
                onClick={() => {
                    AvailabilityStore.reset();
                    ReservationStore.reset();
                    navigate('quotes');
                }}
            >
                Back to Manage Bookings
            </JucyButton>
        </div>
    );
};

const ConfirmedQuotePage = (props) => <QuotePage child={Content} {...props} />;

export default ConfirmedQuotePage;
