import React from 'react';
import { Container, ContainerProps } from '@mui/material';
import { mergeSx } from '#/lib/mergeSx';

export const AppContainer: React.FC<ContainerProps> = ({ maxWidth, sx, children }) => (
    <Container
        maxWidth={maxWidth || 'xl'}
        sx={mergeSx(
            (theme) => ({
                minHeight: '100%',
                display: 'flex',
                paddingTop: theme.settings?.appBar.spacer(),
                pb: { xs: `${theme.settings?.footer?.height}px`, md: '40px' },
            }),
            sx
        )}
    >
        {children}
    </Container>
);
