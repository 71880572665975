import * as React from 'react';
import { forwardRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ContentMenuItem } from '@jucy/content-api-client';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, ButtonProps, Menu, MenuItem, MenuItemProps } from '@mui/material';
import { PopupState, bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { NestedMenuItem } from 'mui-nested-menu';

export const CMSMenuLink: React.FC<MenuItemProps & { item: ContentMenuItem; popupState: PopupState }> = ({ item, popupState, ...props }) => {
    const url = useMemo(() => {
        if (item.link) {
            return `/agent/content/${item.link}`;
        }
        return '';
    }, [item.link]);
    if (item.children.length) {
        return (
            <NestedMenuItem rightIcon={<ChevronRightIcon />} label={item.label} parentMenuOpen={popupState?.isOpen}>
                {item.children.map((child) => (
                    <CMSMenuLink key={child.id} item={child} popupState={popupState} />
                ))}
            </NestedMenuItem>
        );
    }
    return (
        <MenuItem onClick={popupState?.close} {...(props as MenuItemProps<typeof Link>)} component={Link} to={url}>
            {item.label}
        </MenuItem>
    );
};

export interface CMSPagesMenuProps extends ButtonProps {
    items?: ContentMenuItem[];
}

export const CMSPagesTab = forwardRef<HTMLButtonElement, CMSPagesMenuProps>(({ items, children, ...props }, ref) => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'cms-pages-menu' });
    return (
        <>
            <Button ref={ref} {...props} {...bindTrigger(popupState)}>
                {children}
            </Button>
            {items?.length ? (
                <Menu {...bindMenu(popupState)}>
                    {items.map((item) => (
                        <CMSMenuLink key={item.id} item={item} popupState={popupState} />
                    ))}
                </Menu>
            ) : null}
        </>
    );
});
CMSPagesTab.displayName = 'CMSPagesTab';
