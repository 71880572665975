import { observer } from 'mobx-react-lite';
import React, { lazy } from 'react';
import { BookingCart } from '../../../types/BookingCart';
import { useHandleOnFleetCategorySelected, useHandleOnSearchSubmitted } from '../hooks';

const SearchAndResults = lazy(() => import('./Tabs/TripSearchTab'));

export const DirectLandingPage: React.FC<{ summary: BookingCart }> = observer(({ summary }) => {
    const onSearchSubmit = useHandleOnSearchSubmitted({ userMode: 'direct' });
    const onFleetCategorySelected = useHandleOnFleetCategorySelected();

    return <SearchAndResults showResults={true} variant="standalone" summary={summary} onSubmit={onSearchSubmit} onFleetCategorySelected={onFleetCategorySelected} />;
});
