import ErrorReporter from '#/lib/ErrorReporter';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { JucyErrorAlert } from '../../components/Alert/JucyErrorAlert';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import { useRouteNavigate, useSearchParams, useTripApi } from '../../hooks';
import { default as AppStateStore, default as appState } from '../../store/AppStateStore';
import hireDetailsStore from '../../store/HireDetailsStore';
import RentalTripSearchStore from '../../store/RentalTripSearchStore';
import ReservationStore from '../../store/ReservationStore';
import tripStore from '../../store/TripStore';

export const EditQuotePage: React.FC<{ children: React.ReactNode }> = observer(({ children }) => {
    const [{ resume, country }] = useSearchParams<{ resume: string; country: string }>();
    const { reservationReference, activeTab, mode } = useParams<{ reservationReference: string; activeTab: string; mode: string }>();
    const { state, activeQuote } = ReservationStore;
    const navigate = useRouteNavigate();
    const tripEnabled = useTripApi();

    useEffect(() => {
        appState.ui.showFooter = true;
        hireDetailsStore.init();
        tripStore.init().catch(ErrorReporter.captureError);
    }, []);

    useEffect(() => {
        if (reservationReference) {
            ReservationStore.setActiveReservationReference(reservationReference, {
                resume: resume === 'true',
                country,
            }).catch(ErrorReporter.captureError);
        }
    }, [reservationReference, resume, country]);

    useEffect(() => {
        if (state !== 'loading' && state !== 'error' && activeQuote && tripEnabled) {
            navigate('create-trip', {
                params: {
                    mode,
                    fleetTypeOrResRef: activeQuote.ReservationReference,
                    action: 'edit',
                    activeTab: mode === 'agent' ? 'hirer-details' : 'driver',
                },
                keepSearch: true,
                replace: true,
            });
        }
    }, [activeQuote, mode, navigate, state, tripEnabled]);

    if (ReservationStore.error && (ReservationStore.error as unknown as { status: number }).status === 404) {
        if (ReservationStore.expiredError) {
            return (
                <div>
                    <JucyErrorAlert title="Quote expired" className="animate__animated animate__fadeIn me-auto w-100 mb-0 mt-4">
                        <p className="mb-0">We&apos;re sorry, the quote for reference {reservationReference} has expired</p>
                        <br />
                        <Link to={`/${AppStateStore.mode}`}>Start a new quote </Link>
                    </JucyErrorAlert>
                </div>
            );
        } else {
            return (
                <div>
                    <JucyErrorAlert title="404 Quote not found" className="animate__animated animate__fadeIn me-auto w-100 mb-0 mt-4">
                        <p className="mb-0">We&apos;re sorry, no valid quote for reference {reservationReference} was found</p>
                        <br />
                        <Link to={`/${AppStateStore.mode}`}>Start a new quote </Link>
                    </JucyErrorAlert>
                </div>
            );
        }
    }
    if (ReservationStore.error && !ReservationStore.message.includes('Email')) {
        return (
            <div>
                <JucyErrorAlert title="Error loading reservation" message={ReservationStore.message} />
            </div>
        );
    }
    if (
        ReservationStore.activeQuote?.ReservationReference !== reservationReference &&
        RentalTripSearchStore.state !== 'loading' &&
        !['search-results', 'fleet-category'].includes(activeTab || '')
    ) {
        return (
            <div className="d-flex flex-column align-items-center w-100 pt-6 animate__animated animate__fadeIn">
                <LoadingAnimation variant="primary" />
                <div className="fw-bold small text-muted">Loading your reservation</div>
            </div>
        );
    }
    return <>{children}</>;
});
EditQuotePage.displayName = 'EditQuotePage';
