import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import hireDetailsStore from '../../../../store/HireDetailsStore';
import paymentStore from '../../../../store/PaymentStore';
import tripStore from '../../../../store/TripStore';
import { BookingCart } from '../../../../types/BookingCart';
import { DirectHirerDetailsFormValues } from '../DirectHirerDetailsSchema';

export const setHirerDetailsFromFormValues = (values: DirectHirerDetailsFormValues) => {
    hireDetailsStore.updateFromForm(values);
    const paymentOption = paymentStore.getPaymentOptions(tripStore.summary as BookingCart).find((p) => p.id === values.paymentType);
    if (tripStore.summary && paymentOption && paymentOption.id !== tripStore.summary?.paymentTypeId) {
        try {
            tripStore.summary.paymentTypeId = paymentOption.id;
        } catch (e) {
            console.error(e);
        }
    }
};

const SyncHirerWithTripStore: React.FC = () => {
    const context = useFormikContext<DirectHirerDetailsFormValues>();
    useEffect(() => hireDetailsStore.updateContext(context), [context]);
    useEffect(() => {
        setHirerDetailsFromFormValues(context.values);
    }, [context]);
    return null;
};
SyncHirerWithTripStore.displayName = 'SyncHirerWithTripStore';
export default SyncHirerWithTripStore;
