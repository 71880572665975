import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import AgentRentalsReservationTabSet from '../../components/Tab/AgentRentalsReservationTabSet';
import SummaryColumnLayout from '../../containers/SummaryColumnLayout';
import reservationStore from '../../store/ReservationStore';
import { EditQuotePage } from '../common/EditQuote';

const routeId = 'edit-quote';

const AgentEditQuotePage = observer((props) => {
    const [showRight, setShowRight] = useState(true);
    const { activeTab } = useParams();
    return (
        <SummaryColumnLayout summary={reservationStore.summary} routeId={routeId} showRight={showRight}>
            <EditQuotePage>
                <AgentRentalsReservationTabSet setShowRight={setShowRight} routeId={routeId} activeTab={activeTab} {...props} />
            </EditQuotePage>
        </SummaryColumnLayout>
    );
});
AgentEditQuotePage.displayName = 'AgentEditQuotePage';
export default AgentEditQuotePage;
