import React from 'react';
import { ReservationsTable } from '#/components/Tables/ReservationsTable';
import RouteRegistry from '../../store/RouteRegistry';

const ReservationsPage = () => {
    RouteRegistry.setActiveRoute(RouteRegistry.getRouteById('quotes'));
    return <ReservationsTable />;
};

export default ReservationsPage;
