import React from 'react';
import config from '../config';

const ConfigValue = ({ val }) => (
    <>
        {typeof val === 'undefined' && <i>undefined</i>}
        {typeof val !== 'undefined' && '' + val}
    </>
);

class ConfigPage extends React.Component {
    render() {
        return (
            <table className="table table-striped">
                <thead className="thead-dark">
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.values(config).map((item) => (
                        <tr key={item.name}>
                            <td>{item.name}</td>
                            <td>
                                <ConfigValue val={item.value} />
                            </td>
                            {item.layers.map((layer) => (
                                <td key={item.name + '-' + layer.name}>
                                    <ConfigValue val={layer.getValue(item.name)} />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
}

export default ConfigPage;
