import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AppStateStore from '../../store/AppStateStore';
import FleetTypesStore from '../../store/FleetTypesStore';
import ReservationStore from '../../store/ReservationStore';
import RouteRegistry from '../../store/RouteRegistry';
import { BookingCart } from '../../types/BookingCart';
import PaymentForm from '../Forms/PaymentForm/PaymentForm';
import { RentalsReservationTabNames } from './RentalsReservationTabNames';

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

const DirectPaymentTab = () => {
    useEffect(() => {
        scrollToTop();
        AppStateStore.orderSummaryFooter.setShowFooter(false);
        return () => {
            AppStateStore.orderSummaryFooter.setShowFooter(true);
        };
    }, []);
    if (!ReservationStore?.activeQuote?.ReservationReference) {
        return null;
    }
    return (
        <PaymentForm
            summary={ReservationStore.summary as BookingCart}
            onLoad={() => {
                setTimeout(scrollToTop, 200);
            }}
            sx={(t) => ({
                top: t.settings?.appBar.spacer(),
                position: 'absolute',
                left: 0,
                right: 0,
                background: t.palette.background.paper,
                bottom: 0,
            })}
            className="full-page payment-tab"
            backLink={
                <Link
                    className="smaller fw-bold"
                    to={RouteRegistry.getPath('direct-edit-quote', {
                        fleetType: FleetTypesStore.activeFleetTypeSlug,
                        mode: AppStateStore.mode,
                        reservationReference: ReservationStore.activeQuote.ReservationReference,
                        activeTab: RentalsReservationTabNames.driver,
                    })}
                >
                    <i className="fa fa-chevron-left" aria-hidden="true" /> Back to driver info
                </Link>
            }
        />
    );
};
DirectPaymentTab.displayName = 'DirectPaymentTab';
export default DirectPaymentTab;
